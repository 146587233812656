import { useEffect, useRef } from "react";
import NotesDrawerListItem from "components/notes/DrawerListItem";
import NotesDrawerLoadMore from "components/notes/LoadMore";
import LoadingSpinner from "components/common/Loading";

const NotesDrawerList = (props) => {
  const {
    notesDrawerData,
    mobile
  } = props;

  const {
    noteListData,
    sortValues,
    selectedSort,
    defaultSort,
    changeNotesSort,
    listLoading,
    listScrolled,
    setNotesData,
    editingNote,
    deletingNote,
    selectedAboutId,
    hideLoadMore,
    pageLimit
  } = notesDrawerData;

  const showAddNote = () => {
    setNotesData({
      selectedNoteId: null,
      selectedAboutId: null,
      showAddNoteMobile: true
    })
  }

  const notesListElement = useRef();

  function changeSort(event){
    changeNotesSort(event.target.value);
  }

  function handleScroll(event){
    setNotesData({
      listScrolled: event.srcElement.scrollTop == 0
                    ? false
                    : true
    });
  }

  function scrollToTop(){
    if(!notesListElement.current){
      return;
    }
    $(notesListElement.current).scrollTop(0);
  }

  // attach scroll events
  useEffect(() => {
    if(!notesListElement.current){
      return;
    }
    notesListElement.current.addEventListener('scroll', handleScroll);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if(!notesListElement.current){
        return;
      }
      notesListElement.current.removeEventListener('scroll', handleScroll);
    };
  });

  const renderListItemsOutput = () => {
    let noteItemOutput = [];

    if(Object.keys(noteListData).length > 0){
      const note_ids = Object.keys(noteListData)

      note_ids.forEach((currentNoteId) => {
        noteItemOutput.push(
          <NotesDrawerListItem
              notesDrawerData={notesDrawerData}
              currentNoteId={currentNoteId}
              key={noteListData[currentNoteId].id}
              mobile={mobile}
             />
        )
      })
    }else{
      noteItemOutput = (
        <div className="note-list-no-content">
          Add a note to get started. 
        </div>
      );
    }
    return noteItemOutput;
  }

  const renderSortListOutput = () => {
    let sortListOptions = [];
    sortValues.forEach(([sortText, sortValue]) => {
      sortListOptions.push(
        <option value={sortValue} key={sortValue}>{sortText}</option>
      )
    });
    return (
      <select name="note-drawer-list-sort" onChange={changeSort} value={selectedSort || defaultSort}>
        {sortListOptions}
      </select>
    );
  }

  return (
    <div className="maxed-height">
      {
        editingNote || deletingNote
         ? <div className="grayed-out"></div>
         : ''
      }
      <div id="notes-sorting" key="notes-sorting">
        {renderSortListOutput()}
      </div>
      <div id="add-note-button" key="add-note-button">
        <button className={`btn btn-info ${!selectedAboutId && !mobile ? 'disabled' : ''}`}
                onClick={showAddNote}>
                Add Note</button>
      </div>
      <div key={`notes-list-${mobile}`}
           ref={ notesListElement }
           className={`notes-list maxed-height ${listScrolled ? 'scrolled' : ''}`}>
        {
          listLoading
            ? <LoadingSpinner />
            : <>
                {renderListItemsOutput()}
                <NotesDrawerLoadMore noteListData={noteListData}
                                     selectedSort={selectedSort}
                                     setNotesData={setNotesData}
                                     hideLoadMore={hideLoadMore}
                                     pageLimit={pageLimit} />
              </>
        }
      </div>
      {
        listScrolled
          ? <div id="notes-back-to-top"><a onClick={scrollToTop}>Back to top</a></div>
          : ''
      }
    </div>
  )
}

export default NotesDrawerList;
