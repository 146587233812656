import filesize from "filesize";
import MimeTypeIcon from "components/common/MimeTypeIcon";
import { notePath, noteAttachmentPath } from "utilities/UrlHelpers";
import { assetUrl } from "utilities/RailsHelpers";

const NoteAttachmentListItem = (props) => {
  const { note, setNoteState, attachment } = props;

  const deleteAttachment = async () => {
    let resp = await Fetch(noteAttachmentPath(attachment.attachableId, attachment.id), {method: 'DELETE'});
    let json = await resp.json();
    resp = await Fetch(notePath(note.id), {method: 'GET'});
    json = await resp.json();

    setNoteState(json);
  };

  return (
    <div
      key={attachment.id}
      className={`note-attachment-list-item`}
      >
      <div className="note-attachment-list-item-data">
        <div className="row">
          <div className="col-xs-6">
            <p title={attachment.name}>
            <MimeTypeIcon contentType={attachment.contentType} />
            {attachment.name}
            </p>
          </div>
          <div className="col-xs-2">
          {filesize(attachment.size, {round: 0})}
          </div>
          <div className="col-xs-2">
            {attachment.createdAtString}
          </div>
          <div className="col-xs-2">
            <p className="text-right">
            <a href={noteAttachmentPath(attachment.attachableId, attachment.id)}><img src={ assetUrl('download.svg') }/></a>
            <a onClick={deleteAttachment}><img src={ assetUrl('delete.svg') } /></a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoteAttachmentListItem;
