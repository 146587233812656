import Drawer from "components/Drawer";
import { conversationStarPath, conversationUnstarPath } from "utilities/UrlHelpers";
import UseMessagesState from 'data/MessagesState';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';

const StarConversationButton = (props) => {
  const messagesDrawerState = UseMessagesState();

  const { targetAccountId, starred } = props;

  const {
    setMessagesDrawerData,
    conversationData,
    conversationListData,
    showConversationList,
    drawerMethods
  } = messagesDrawerState.get();

  const starMessage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const path = starred ? conversationUnstarPath(targetAccountId) : conversationStarPath(targetAccountId)
    Fetch(path, { method: 'PUT' })
      .then(response => response.json())
      .then(data => {
        if(messagesDrawerState.conversationListData.conversations[targetAccountId]){
          messagesDrawerState.conversationListData.conversations[targetAccountId].starred.set(!starred)
        }
        messagesDrawerState.conversationData.starred.set(!starred);
      });
  }

  return (
    <span className={`star-message ${starred ? 'starred' : ''}`}
       onClick={starMessage}>
      {
        starred
          ? <StarRoundedIcon />
          : <StarBorderRoundedIcon />
      }
    </span>
  )
}

export default StarConversationButton;
