import { useEffect } from "react";
import MessageList from "components/messages/MessageList";
import ConversationsList from "components/messages/ConversationsList";
import Drawer from "components/Drawer";
import LoadingSpinner from "components/common/Loading";
import AccountSidebarWidget from "containers/AccountSidebarWidget";
import MessagesFilters from "components/messages/MessagesFilters";
import NewMessageButton from "components/messages/NewMessageButton";
import SearchBox from "components/messages/search/SearchBox";
import SearchResults from "components/messages/search/SearchResults";
import { messagesPath, conversationsPath, currentAccountCampaignsPath } from "utilities/UrlHelpers";
import { conversationListPageLimit, assetUrl } from "utilities/RailsHelpers";
import UseMessagesState from 'data/MessagesState';
import { Untracked } from '@hookstate/untracked';
import EventTracking from 'components/common/EventTracking';
import CampaignsFilters from "components/messages/CampaignsFilters";
import CurrentUserData from "components/common/CurrentUserData";

const MessageDrawer = (props) => {
  const { account_type, ghost_mode } = CurrentUserData();

  const init = () => {
    Untracked(messagesDrawerState).set((oldState) => {
      // only change this if it wasn't already open
      if(!oldState.showConversationList){
        oldState.showConversationList = props.showConversationList || !props.messagingAccountId;
      }
      oldState.drawerMethods = props.drawerMethods;
      oldState.context = props.context;
      oldState.messagingAccountId = props.messagingAccountId;
      oldState.setMessagesDrawerData = setMessagesDrawerData;
      oldState.selectConversation = selectConversation;
      oldState.showLoading = showLoading;
      oldState.removeLoading = removeLoading;
      oldState.fetchConversationsListData = fetchConversationsListData;
      oldState.fetchMessagesDrawerData = fetchMessagesDrawerData;
      return oldState;
    });
    fetchConversationsListData();
    fetchMessagesDrawerData();
    if(account_type == "business"){
      fetchAvailableCampaigns();
    }
  };

  const showLoading = (loader) => {
    $('body').addClass('ajax');
    let messagesDrawerDataUpdate = {}
    messagesDrawerDataUpdate[`${loader}Loading`] = true
    setMessagesDrawerData(messagesDrawerDataUpdate);
  };

  const removeLoading = (loader) => {
    $('body').removeClass('ajax');
    let messagesDrawerDataUpdate = {}
    messagesDrawerDataUpdate[`${loader}Loading`] = false
    setMessagesDrawerData(messagesDrawerDataUpdate);
  };

  const fetchMessagesDrawerData = async () => {
    const messagingAccountId = messagesDrawerState.messagingAccountId.get();
    const context = messagesDrawerState.context.get();
    if(!messagingAccountId){
      removeLoading('messages');
      return
    }
    showLoading('messages');
    Fetch(messagesPath(messagingAccountId, context))
      .then(response => response.json())
      .then(data => {
        const {newMessages, ...newConversationData} = data;
        setMessagesDrawerData({
          conversationData:  newConversationData,
          messagingAccountId: messagingAccountId,
          newMessages: newMessages
        },
        ()=>{
          removeLoading('messages');
          $("#drawer-message-box").focus();
          EventTracking('Conversation Viewed');
          if(data.isNew){
            EventTracking('Conversation Created');
          }
        });
      });
  };

  const setMessagesDrawerData = (messagesDrawerData, callback) => {
    messagesDrawerState.merge(messagesDrawerData);
    if(callback){ callback() }
  };

  const fetchConversationsListData = async () => {
    const showConversationList  = messagesDrawerState.showConversationList.get();
    const filter = messagesDrawerState.filter.get();
    const currentCampaignId = messagesDrawerState.get().campaignId;

    setMessagesDrawerData({
      conversationListData: {
        conversationsLoading: true,
        conversations: [],
        hideLoadMore: true,
        currentConversationListScrollPosition: 0
      }
    })
    Fetch(conversationsPath(filter, currentCampaignId))
      .then(response => response.json())
      .then(data => {
        setMessagesDrawerData({
          conversationListData:  {
            conversations: data,
            conversationsLoading: false,
            hideLoadMore: Object.keys(data).length == 0
          }
        });
      });
  }

  const fetchAvailableCampaigns = async () => {
    if(!messagesDrawerState.get().campaignsAvailable){
      const response = await Fetch(currentAccountCampaignsPath());
      const campaignsData = await response.json();
      setMessagesDrawerData({campaignsAvailable: campaignsData});
    }
  }

  const selectConversation = (newMessagingAccountId, newSearchWithinConversation) => {
    if(messagesDrawerState.showConversationList.get()){
      const conversation = messagesDrawerState.conversationListData.conversations[newMessagingAccountId];
      if(conversation && conversation.get()){
        if(!ghost_mode){
          conversation.unreadCount.set(0);
          conversation.unread.set(false);
        }
      }
    }
    let newDrawerData = {messagingAccountId: newMessagingAccountId};
    if(!newSearchWithinConversation){
      newDrawerData.searchWithinConversation = null;
      newDrawerData.currentMatch = 1;
    }else{
      newDrawerData.searchWithinConversation = newSearchWithinConversation;
      newDrawerData.currentMatch = 1;
      newDrawerData.matchCount = null;
    }
    setMessagesDrawerData(newDrawerData, ()=>{
      fetchMessagesDrawerData();
    })
  }

  const unselectConversation = (e) => {
    setMessagesDrawerData({
      messagingAccountId: null
    });
  };

  const displaySidebarMobile = () => {
    setMessagesDrawerData({
      showSidebarMobile: true
    });
  }
  const hideSidebarMobile = () => {
    setMessagesDrawerData({
      showSidebarMobile: false
    });
  }

  const LeftColumn = () => {
    const search = messagesDrawerState.search.get();
    const searchData = messagesDrawerState.searchData.get();
    const startNewConversation = messagesDrawerState.startNewConversation.get();

    return(
      <div className={`col-xs-12 col-sm-3 no-padding maxed-height ${ messagingAccountId ? "hidden-xs" : "" }`}>
        <div className="visible-xs messages-mobile-header">
          <div className="conversation-list-header">
            <i className="fas fa-times" onClick={(e)=>{drawerMethods.closeDrawer();}}></i>
            <h2>Messages</h2>
            <div className="pull-right messages-actions">
              { !startNewConversation && <NewMessageButton /> }
              { !search && !startNewConversation && <MessagesFilters /> }
            </div>
          </div>
        </div>
        <div className="conversation-list-header hidden-xs">
          <h2>Messages</h2>
          <div className="pull-right messages-actions">
            { !startNewConversation && <NewMessageButton key="new-message-button" /> }
            { !search && !startNewConversation && <MessagesFilters key="messages-filters" /> }
          </div>
        </div>
        <div className="messages-search">
          <SearchBox />
        </div>
        { !search && <CampaignsFilters /> }
        {
          search
            ? <SearchResults />
            : <ConversationsList />
        }
      </div>
    )
  }

  const MiddleColumn = () => {
    return (
      messagingAccountId
        ? <>
            <div
              className={
                `col-sm-${showConversationList ? "6" : "9"} col-xs-12 maxed-height ${ showSidebarMobile ? "hidden-xs" : "" }`
              }>
              <div className="visible-xs row">
                <div className="col-xs-12 messages-mobile-header">
                  {showConversationList
                      ? <i className="fas fa-angle-left pull-left" onClick={(e)=>{unselectConversation()}}></i>
                      : <i className="fas fa-times pull-left" onClick={(e)=>{drawerMethods.closeDrawer();}}></i>}
                  <h2>{username}</h2>
                  <i className="fas fa-info-circle pull-right" onClick={(e)=>{displaySidebarMobile()}}></i>
                </div>
              </div>
              <MessageList />
            </div>
            <div className={`${ showSidebarMobile ? "" : "hidden-xs" } col-xs-12 col-sm-3 maxed-height account-sidebar-column`}>
              <div className="row visible-xs">
                <div className="col-xs-12 messages-mobile-header">
                  <i className="fas fa-angle-left pull-left" onClick={(e)=>{hideSidebarMobile()}}></i>
                  <h2>{username}</h2>
                </div>
              </div>
              <AccountSidebarWidget accountId={messagingAccountId}
                                    showMessageButton={false} />
            </div>
          </>
        : <div className="col-xs-12 col-sm-9">
            <p className="text-center">
              <img src={assetUrl('messages_blank.svg')} className="message-blank img-responsive block-center padding-top" />
            </p>
            <p className="text-center">Select a conversation or start a new message</p>
          </div>

    )
  }

  const messagesDrawerState = UseMessagesState();
  messagesDrawerState.attach(Untracked);

  const {
    messagesLoading,
    conversationData,
    messagingAccountId,
    showSidebarMobile,
    showConversationList,
    drawerMethods
  } = messagesDrawerState.get();

  const { recipient } = conversationData;
  const { username } = recipient || {};

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="container-fluid maxed-height">
      <div className="row maxed-height">
        { showConversationList && <LeftColumn /> }
        {messagesLoading
          ? <div className="container-fluid maxed-height text-center">
              <LoadingSpinner />
            </div>
          : <MiddleColumn /> }
      </div>
    </div>
  )
}

export default MessageDrawer;
