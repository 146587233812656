import { createState, useState } from "@hookstate/core";

const ProfileModalState = createState({
  visible: false,
  hovering: false,
  userData: {
    id: null,
    accountType: null,
    isSelect: null,
    isPro: null,
    profilePicture: null,
    reach: null,
    fullName: null,
    username: null,
    headline: null,
    location: null,
    profileUrl: null,
    isClaimed: null,
  },
  position: { top: 0, left: 0 },
});

export default function UseProfileModalState(access) {
  if (!access) {
    return useState(ProfileModalState);
  }
  return ProfileModalState;
}
