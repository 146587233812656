import UseMessagesState from 'data/MessagesState';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const SearchWithinConversationNavigation = (props) => {
  const messagesDrawerState = UseMessagesState();

  const {
    currentMatch,
    matchCount,
    searchWithinConversation,
    setMessagesDrawerData
  } = messagesDrawerState.get();

  const navigateMatchesWithinConversation = (position) => {
    setMessagesDrawerData({currentMatch: position});
  }

  const prevMatch = () => {
    let prevMatchPosition = currentMatch - 1;
    if(prevMatchPosition < 1){ prevMatchPosition = 1; }
    navigateMatchesWithinConversation(prevMatchPosition)
  }

  const nextMatch = () => {
    let nextMatchPosition = currentMatch + 1;
    if(nextMatchPosition > matchCount){ nextMatchPosition = matchCount; }
    navigateMatchesWithinConversation(nextMatchPosition)
  }

  const clearSearch = () => {
    setMessagesDrawerData({searchWithinConversation: null})
  };

  const showSidebarMobile = () => {
    setMessagesDrawerData({showSidebarMobile: true});
  }

  if(!searchWithinConversation || !matchCount){
    return null;
  }

  return (
    <div className="current-search-within-conversation-navigation">
      <span onClick={()=>{currentMatch > 1 && prevMatch()}} className="fa fa-chevron-up"></span>
      <span onClick={()=>{currentMatch < matchCount && nextMatch()}} className="fa fa-chevron-down"></span>
      <span className="matches-text">
        {currentMatch}/{matchCount} matched messages in conversation for&nbsp;
        <strong
          className="current-search-term"
          onClick={showSidebarMobile}>
          {searchWithinConversation}
        </strong>
      </span>
      <a onClick={clearSearch}>Clear</a>
    </div>
  )
}

export default SearchWithinConversationNavigation;
