import CircularImage from 'components/common/CircularImage/index'

const NotesDrawerListItem = (props) => {
  const {
    notesDrawerData,
    currentNoteId,
    mobile
  } = props

  const {
    setNotesData,
    selectedNoteId,
    drawerMethods,
    noteListData,
    resetDrawer
  } = notesDrawerData;

  const {
    setShowBack,
    setDrawerTitle,
    setCloseDrawer
  } = drawerMethods;

  const note = noteListData[currentNoteId];
  const {
    about_profile_picture, about_id, about_username, about_name, content, list_timestamp_text
  } = note

  const clickNote = () => {
    if(selectedNoteId == currentNoteId){
      return;
    }

    if(mobile){
      setDrawerTitle(`Notes on ${ about_name }`);
      setShowBack(true);
      setCloseDrawer(resetDrawer);
    }

    setNotesData({
      selectedNoteId: currentNoteId,
      selectedNote: null,
      selectedAboutId: about_id,
      editingNote: false,
      editNoteContent: content,
      searchAccount: null
    });
  }

  return (
    <div
      key={currentNoteId}
      className={`notes-list-item ${selectedNoteId == currentNoteId ? 'selected' : ''}`}
      onClick={clickNote}
      >
      <CircularImage
          src={about_profile_picture}
          classes=""
          alt={`Profile picture for @${about_username}`}
        />
      <div className="note-list-item-data">
        <div className="note-name">{about_name}</div>
        <div className="note-username">@{about_username}</div>
        <div className="truncate-text no-wrap note-content">{content}</div>
        <div className="note-updated">{list_timestamp_text}</div>
      </div>
    </div>
  )
}

export default NotesDrawerListItem;
