import { useRef, useEffect } from "react";
import UseMessagesState from 'data/MessagesState';
import { sendMessagePath, influencerProfilePath } from "UrlHelpers";
import UploadService from "UploadService";
import EventTracking from 'EventTracking';

const AttachmentFileInput = (props) => {
  const messagesState = UseMessagesState();
  const inputRef = useRef();
  const { messagingAccountId } = messagesState.get();

  const handleFileSelect = async () => {
    setUploadingState(true);
    let formData = new FormData();
    formData.append("attachment", inputRef.current.files[0]);
    formData.append("message", "");
    formData.append("account_id", messagingAccountId);

    UploadService.upload(
      sendMessagePath(messagingAccountId),
      formData,
      handleUploadProgress,
      handleDownloadProgress
    ).then((resp)=>{
      messagesState.conversationData.messages.merge([resp.data.message]);
      EventTracking('Message Created')
      setTimeout(()=>{ setUploadingState(false); }, 300);
    }).catch(error => {
      if(error.response.status == 429){
        // User needs to upgrade to pro
        window.location = `${influencerProfilePath(recipient.username)}?trigger_paywall=${error.response.data.trigger_paywall}`;
      }else if(error.response.status == 403){
        setUploadingState(false);
        alert(error.response.data.error);
      } else {
        setUploadingState(false);
        alert("There was an unknown error, please try again.  If the problem persists, please contact us and let us know.");
      }
    });
  }

  const handleUploadProgress = (progress) => {
    setUploadProgressState(Math.round((100 * progress.loaded) / progress.total));
  };

  const handleDownloadProgress = (progress) => {
    setDownloadProgressState(Math.round((100 * progress.loaded) / progress.total));
  };

  const setUploadProgressState = (progress) => {
    messagesState.merge({uploadProgress: progress});
  };

  const setDownloadProgressState = (progress) => {
    messagesState.merge({downloadProgress: progress});
  };

  const setUploadingState = (uploading) => {
    messagesState.merge({uploading: uploading});
    if(!uploading){
      messagesState.merge({uploadProgress: null, downloadProgress: null})
    }
  };

  const clickUploadInput = () => {
    inputRef.current.click();
  }

  useEffect(()=>{
    setTimeout(()=>(messagesState.merge({ clickUploadInput})), 100)
  }, [messagingAccountId, inputRef]);

  return (
    <input
      type="file"
      name="attachment_input"
      style={{ display: "none" }}
      ref={inputRef}
      onChange={handleFileSelect}
      />
  );
};

export default AttachmentFileInput;
