import { useEffect, useState } from "react";
import { flagsAPIPath } from "utilities/UrlHelpers";
import CurrentUserData from "components/common/CurrentUserData";
import useSharedSpacesStyles from "./SharedStyles";

import spacesHero from "./icon-spaces-hero.png";

const AnswersHero = () => {
  const [heroHidden, setHeroHidden] = useState(false);
  const classes = useSharedSpacesStyles();

  if(CurrentUserData().flags_hide_answer_hero || heroHidden){ return null; }

  const close = () => {
    updateHeroHidden();
    setHeroHidden(true);
  };

  const updateHeroHidden = async () => {
    const resp = await Fetch(flagsAPIPath("hide_answer_hero", true), { method: "POST" });
  };


  return (
    <div className={classes.answersHero}>
      <img src={spacesHero} />
      <div>
        <h2>Ask Questions & Get Answers</h2>
        <p>Questions are a place to ask and get answers from people
  who know!</p>
      </div>
      {
        CurrentUserData().id && 
        <a onClick={close}>Dismiss</a>
      }
    </div>
  )
}

export default AnswersHero;
