import { Component } from "react";

export default class ActivitySummaryCard extends Component {
  render() {
    const {
      post_activity_summary_data,
      show_activity_path
    } = this.props
    let big_card_data = null
    let small_card_data = null
    let small_card_output = [];
    if(Object.keys(post_activity_summary_data).length > 0){
      const activity_data = {...post_activity_summary_data};
      const activity_ids = Object.keys(activity_data)
      const big_activity_id = activity_ids.splice(0,1)[0]
      big_card_data = activity_data[big_activity_id]
      delete activity_data[big_activity_id]
      small_card_data = activity_data
      Object.keys(small_card_data).forEach((key) => {
        small_card_output.push(
          <ActivitySummaryDetailCardSmall
            data={small_card_data[key]}
            key={key} />
        )
      })
    }
    if(big_card_data == null){
      return null
    }
    return (
      <div>
        <h4>Activity</h4>
        {
          small_card_output.length === 0
          ? <div>
              <ActivitySummaryDetailCardLarge
                    data={big_card_data}
                    solo={true} />
              <a href={show_activity_path}
                 className={"activity-show-all"}>
                See All
              </a>
            </div>
          : <div>
              <div className={"row"}>
                <div className={"col-xs-12 col-md-6"}>
                  <ActivitySummaryDetailCardLarge
                    data={big_card_data}
                    solo={false} />
                </div>
                <div className={"col-xs-12 col-md-6"}>
                  {small_card_output}
                  <hr />
                </div>
              </div>
              <a
                onClick={(e) => handleActivityClick({cta_url: show_activity_path})}
                className={"activity-show-all"}>
                See All
              </a>
            </div>
        }
      </div>
    )
  }
}


class ActivitySummaryDetailCardLarge extends Component {
  render() {
    const card_data = this.props.data
    return(
      <div className="row">
        <div className="col-xs-12">
          <button className={"activity-summary-detail-large"}
               onClick={(e) => handleActivityClick(card_data)}>
            <div className={"col-xs-12 white-content-block activity-summary-action"}>
              <div className={"activity-summary-action"}>
                {card_data.actor} {card_data.action_text}
              </div>
              <ActivitySummaryLargeImageDescription
                data={card_data}
                solo={this.props.solo} />
              <hr />
              <span>
                <i className={"glyphicon glyphicon-heart"}></i>
                {card_data.like_count}
              </span>
              <span>
                <i className={"glyphicon glyphicon-comment"}></i>
                {card_data.comment_count}
              </span>
            </div>
          </button>
        </div>
      </div>
    )
  }
}

class ActivitySummaryLargeImageDescription extends Component {
  render() {
    const card_data = this.props.data;
    // If we are in a layout that wants the text next to the image, make sure there's an image, otherwise just render the text version
    if (this.props.solo && card_data.image_url != '' ){
      return (
        <div className={"row flex-display"}>
          <div className={"col-xs-6"}>
            <img src={card_data.image_url} className={"img-responsive"} />
          </div>
          <div className={"col-xs-6"}>
            {card_data.share_title
               ? <div className='summary-title no-wrap-ellipsis'>{card_data.share_title}</div>
               : ''}
            <div className={"summary-description"}>{card_data.post_description}</div>
          </div>
        </div>
      )
    }else{
      return(
        card_data.image_url != ''
          ? <div>
              <img src={card_data.image_url} className={"img-responsive"} />
              {card_data.share_title
                 ? <div className='summary-title no-wrap-ellipsis'>{card_data.share_title}</div>
                 : ''}
              <div className={"summary-description truncate-text no-wrap"}>{card_data.post_description}</div>
            </div>
          : <div className={"summary-description no-image truncate-text no-wrap"}>{card_data.post_description}</div>
      )
    }
  }
}

class ActivitySummaryDetailCardSmall extends Component {
  render() {
    const card_data = this.props.data
    return(
      <button className={"activity-summary-small"}
           onClick={(e) => handleActivityClick(card_data)}>
        {
          card_data.image_url != ''
            ? <div className={'small-activity-image flex-display'}>
                <img src={card_data.image_url} className={"img-responsive"} />
              </div>
            : null
        }
        <div>
          <div className={'summary-description truncate-text no-wrap'}>
            {card_data.share_title || card_data.post_description}
          </div>
          <div className={'truncate-text no-wrap'}>
            {card_data.actor} {card_data.action_text}
          </div>
        </div>
      </button>
    )
  }
}

function handleActivityClick(activity_data){
  test_track_event({
    event_name: 'Activity Summary - Click',
    activity_url: activity_data.cta_url
  })
  // Give event time to register then redirect to cta_url
  setTimeout(()=>{window.location = activity_data.cta_url}, 300);
}
