import { useEffect, useState } from "react";
import LoadingSpinner from "components/common/Loading";
import CircularImage from "components/common/CircularImage/index";
import { questionAnswersAPIPath } from "utilities/UrlHelpers";
import UseSpaceState from "data/SpaceState";
import AnswerDetail from "./AnswerDetail";
import QuestionListItem from "./QuestionListItem";

const AnswersList = (props) => {
  const { questionId, skipAnswerId, preview } = props;
  const spaceState = UseSpaceState();
  const { answers } = spaceState.get();
  const answersValues = spaceState.answers[questionId].get();
  const [loading, setLoading] = useState(false);

  const loadAnswers = async () => {
    const urlParams = [];
    setLoading(true);
    const res = await Fetch(questionAnswersAPIPath(questionId));
    setLoading(false);
    if(res.ok){
      const data = await res.json();
      updateAnswersData(data)
    }
  };

  const updateAnswersData = (newData) => {
    if(!spaceState.answers[questionId]){
      spaceState.answers[questionId].set(newData);
    } else {
      spaceState.answers[questionId].merge(newData);
    }
  }

  useEffect(()=>{
    if(!questionId || (answersValues && answersValues.length > 0)){
      return;
    }
    loadAnswers();
  }, [questionId]);
  if(loading){
    return (
      <div>
        <LoadingSpinner text="Loading answers..." />
      </div>
    );
  }else if(!answersValues || answersValues.length == 0){ return null; }

  const RenderAnswers = () => {
    const answersRows = [];
    answersValues.forEach((answer) => {
      if(skipAnswerId && skipAnswerId == answer.id){ return; }
      answersRows.push(
        <div key={answer.id}>
          <hr />
          <AnswerDetail answer={answer} preview={preview} />
        </div>
      );
    });
    return answersRows;
  }

  return (
    <div>
      <RenderAnswers />
    </div>
  )
}

export default AnswersList;
