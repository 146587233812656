import UseMessagesState from 'data/MessagesState';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import { usernameInfluencerSearchesPath } from "utilities/UrlHelpers";

export default function NewMessageButton() {
  const messagesDrawerState = UseMessagesState();

  const {
    startNewConversation,
    setMessagesDrawerData
  } = messagesDrawerState.get();

  const handleClick = () => {
    setMessagesDrawerData({startNewConversation: true})
  };

  return (
    <div className="new-message-button pull-right">
      <div onClick={handleClick}>
        <RateReviewOutlinedIcon style={{ fontSize: 18 }} />
      </div>
    </div>
  );
}
