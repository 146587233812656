import { useState } from "react";
import CircularImage from "components/common/CircularImage/index";
import CampaignApplicationCard from "components/campaign_applications/ApplicationCard";
import Collapse from '@material-ui/core/Collapse';


const SidebarApplicationsCard = (props) => {
  const {
    campaignAppData
  } = props;
  if(campaignAppData.length == 0){
    return '';
  }
  const [showDetails, setShowDetails] = useState(false);

  const renderAppData = () => {
    let renderedAppItems = [];
    campaignAppData.forEach(function (app) {
      renderedAppItems.push(
        <CampaignApplicationCard app={app} key={app.id} />
      );
    });
    return renderedAppItems;
  }

  return(
    <div className='account-sidebar-campaign-apps'>
      <div className="campaigns-widget-header collapsible-widget-header" onClick={ ()=>{ setShowDetails(!showDetails); } }>
        <h2>Campaigns</h2>
        <span className={`fa ${showDetails ? "fa-chevron-down" : "fa-chevron-left"}`}></span>
      </div>
      <Collapse in={showDetails}>
        {
          renderAppData()
        }
      </Collapse>
    </div>
  )
}

export default SidebarApplicationsCard;
