import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useBoostrapVariables from "utilities/BootstrapVariables";
import { fireballStatusPath, fireballsAPIPath } from "utilities/UrlHelpers";
import CurrentUserData from "components/common/CurrentUserData";
import JSONHeaders from "utilities/JSONHeaders";

const cssDefaults = useBoostrapVariables();

const useFireballStyles = makeStyles((theme) => ({
  button: {
    "& span": {
      display: "inline-block",
      borderRadius: 20,
      backgroundColor: cssDefaults.brandLink,
      color: "white",
      padding: `2px 3px`,
      marginLeft: theme.spacing(.5),
      minWidth: 21,
      fontSize: 12,
      fontWeight: 700
    },
    "& button, & button:hover, & button:active, & button:focus": {
      textDecoration: "none",
      outline: "none !important",
      border: "0 none"
    }
  }
}));

const FireballButton = (props) => {
  const { likeableType, likeableId, liked, totalLikes } = props;
  const classes = useFireballStyles();
  const [likedState, setLikedState] = useState(liked);
  const [totalLikesState, setTotalLikes] = useState(totalLikes);

  const toggleLike = async () => {
    if(!CurrentUserData().id){
      return window.triggerSignupWall('like-button');
    }
    const method = likedState ? "DELETE" : "POST";
    const resp = await Fetch(fireballsAPIPath(), {
      ...JSONHeaders,
      method: method,
      body: JSON.stringify({
        likeable_id: likeableId,
        likeable_type: likeableType
      })
    })
    if(resp.ok){
      setTotalLikes(likedState ? totalLikesState - 1 : totalLikesState + 1)
      setLikedState(!likedState);
    }else{
      alertify.error("There was a problem trying to complete that action, please try again or contact us if the issue persists.")
    }
  };

  return (
    <div className={classes.button}>
      <button onClick={toggleLike} className="btn btn-link no-padding">
        { likedState ? "Liked" : "Like" }
        <span>
          {totalLikesState}
        </span>
      </button>
    </div>
  );
};

export default FireballButton;
