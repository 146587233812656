import { conversationsPath } from "utilities/UrlHelpers";
import { conversationListPageLimit } from "utilities/RailsHelpers";
import UseMessagesState from 'data/MessagesState';

const ConversationListLoadMore = (props) => {
  const messagesDrawerState = UseMessagesState();

  const {
    conversationListData,
    setMessagesDrawerData,
    filter
  } = messagesDrawerState.get();

  const {
    hideLoadMore,
  } = conversationListData;
  if(hideLoadMore){
    return '';
  }

  const conversations = messagesDrawerState.conversationListData.conversations;
  const lastConversation = conversations[Object.keys(conversations)[Object.keys(conversations).length - 1]].get();
  
  const handleClick = async () => {
    let lastValue = lastConversation.lastMessageTimestamp;
    const currentCampaignId = messagesDrawerState.get().campaignId;

    const response = await Fetch(conversationsPath(filter, currentCampaignId, lastValue))

    if(response.ok){
      const conversationsData = await response.json()
      conversations.merge(conversationsData);
      messagesDrawerState.conversationListData.conversationsLoading.set(false);
      messagesDrawerState.conversationListData.hideLoadMore.set((Object.keys(conversationsData).length == 0))
    }
  }

  return (
    <button className="conversations-load-more btn btn-default"
            onClick={handleClick}>
      Load More
    </button>
  )
}

export default ConversationListLoadMore;
