import { useState, useEffect } from "react";
import { adminActivityStatusPath, adminToggleSuggestedPath } from "utilities/UrlHelpers";
import UseActivitiesState from 'data/ActivitiesState';

const SuggestToFeedButton = (props) => {
  const { activityId } = props;

  //guard clause in case we somehow get here without an activity being present
  if(!activityId){ return null; }

  const activityState = UseActivitiesState();
  const { activities } = activityState;
  const activity = activities[activityId];
  const { suggested } = activity.get() || {suggested: false};

  const getSuggestedStatus = async () => {
    const resp = await Fetch(adminActivityStatusPath(activityId));

    if(resp.ok){
      const data = await resp.json();
      activityState.activities.merge({[activityId]: data});
    }
  };

  const handleClick = async () => {
    const resp = await Fetch(adminToggleSuggestedPath(activityId),{ method: "PUT" });
    if(resp.ok){
      const data = await resp.json();
      activityState.activities.merge({[activityId]: data});
    }
  };

  useEffect(() => { getSuggestedStatus(); }, []);

  if(!activity.get()){
    return null;
  }

  return (
    <button className="btn btn-link btn-text" onClick={handleClick}>
      { !suggested ? "Add to " : "Remove from " } Community Feed
    </button>
  )
}

export default SuggestToFeedButton;
