import { Component } from "react";
import ReactDOM from "react-dom";

const LABEL_MAP = {
    growth_last_month: "Growth Last Month",
    growth_last_month_percentage: "Growth Last Month %",
    daily_avg_followers_last_month: "Avg. Growth/Day",
    month_to_date: "Growth This Month",
};

export default class GrowthSummaryCard extends Component {
    generateTiles(labelMap, stats) {
        let output = [];
        Object.keys(labelMap).forEach((key) => {
            if (key in stats && labelMap[key]) {
                output.push(
                    <GrowthSummaryTile
                        data={stats[key]}
                        key={key}
                        label={labelMap[key]}
                    />
                  )
            }
        });
        return output;
    }
    render() {
      const { stats } = this.props;

      if (stats.missing_instagram) {
        return(
          <div className={'growth-summary'}>
            <div className={"summary-header"}>Instagram Growth Summary</div>
            <div className={"row summary-body"}>
              <p className="text-center">
              <a href="/influencers/me/edit#edit_instagram" className="btn btn-info">Connect Instagram for Stats</a>
              </p>
            </div>
          </div>
        ) 
      } else {
        return (
              <div className={'growth-summary'}>
                  <div className={"summary-header"}>Instagram Growth Summary</div>
                  <div className={"row summary-body"}>
                    <GrowthSummaryTile label={"Followers"} data={stats.followed_by_count} />
                    {this.generateTiles(LABEL_MAP, stats)}
                  </div>
              </div>
          )
      }
    }
}

class GrowthSummaryTile extends Component {
  render() {
      return(
          <div className={"summary-tile col-xs-12"}>
              <div className={"summary-tile-title"}>{this.props.label}:</div>
              <div className={"summary-tile-data"}>{this.props.data ? this.props.data : 0}</div>
          </div>
        )
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const nodes = document.getElementsByClassName('GrowthSummaryCard')
 
  nodes.forEach((el, i) => {
    var data = JSON.parse(el.getAttribute('data-react-props'))

    ReactDOM.render(
      <GrowthSummaryCard stats={data} />,
      el.appendChild(document.createElement('div')),
    )
  })
})
