import { useState, useRef } from "react";
import JSONHeaders from "utilities/JSONHeaders";
import CustomMarkdown from "components/common/CustomMarkdown";
import Pluralize from "pluralize";
import AvatarUsername from "components/common/AvatarUsername";
import CurrentUserData from "components/common/CurrentUserData";
import useSharedSpacesStyles from "./SharedStyles";
import CircularImage from "components/common/CircularImage/index";
import { questionAnswerCreateAPIPath, questionAPIPath, spacesPath, adminEditQuestionPath } from "utilities/UrlHelpers";
import UseSpaceState from "data/SpaceState";
import UpvoteButton from "../UpvoteButton";
import ShareButton from "components/common/ShareButton";
import AnswerForm from "./AnswerForm";
import AnswersList from "./AnswersList";
import Dropdown from "./Dropdown";

const QuestionDisplay = (props) => {
  let { question, spaces } = props;
  const { account, id, activityId, url, title, description, voteCount, voted, answerCount, topVoteAvatars } = question;
  const [answering, setAnswering] = useState(false);
  const [descriptionState, setDescription] = useState(description);
  const [titleState, setTitle] = useState(title);
  const [editing, setEditing] = useState(false);
  const spaceState = UseSpaceState();
  const answerInput = useRef();
  const titleInput = useRef();
  const descriptionInput = useRef();
  const classes = useSharedSpacesStyles();

  if(!spaces){ return null }
  const space = question ? spaces[question.spaceSlug] : {};
  if(!space){ return null };


  const TopVoteAvatars = () => {
    const avatars = [];

    if(topVoteAvatars && topVoteAvatars.length > 0){
      topVoteAvatars.forEach((avatar) => {
        const avatarUrl = avatar.profilePicture;
        avatars.push(
          <a key={avatar.profileUrl} href={avatar.profileUrl} className="profile-modal-opener" data-profile-modal-username={avatar.username}>
            <CircularImage src={avatarUrl} key={avatar.id} />
          </a>
        )
      });
    }
    return avatars;
  };

  const handleAnswerButton = async () => {
    if(!CurrentUserData().id){
      return window.triggerSignupWall('answer-question');
    }
    if(answering && answerInput.current.value != ""){
      const resp = await Fetch(questionAnswerCreateAPIPath(id), {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          description: answerInput.current.value
        })
      });
      if(resp.ok){
        const data = await resp.json();
        spaceState.answers[id].merge([data]);
      } else {
        alertify.error("There was a problem saving your answer, please try again or contact us");
        // don't close the form
        return;
      }
    }
    setAnswering(!answering);
  };

  const toggleEditForm = () => {
    setEditing(!editing);
  };

  const handleEdit = async () => {
    const newTitleValue = titleInput.current.value;
    const newDescriptionValue = descriptionInput.current.value;
    const resp = await Fetch(questionAPIPath(question.id), {
      ...JSONHeaders,
      method: "PUT",
      body: JSON.stringify({
        description: newDescriptionValue,
        title: newTitleValue
      })
    })
    setTitle(newTitleValue);
    setDescription(newDescriptionValue);
    setEditing(false);
  };

  const handleDestroy = async () => {
    if(confirm("Are you sure you want to delete this question?")){
      const resp = await Fetch(questionAPIPath(question.id), { method: "DELETE" })
      if(resp.ok){
        alertify.success("Your question has been deleted");
        setTimeout(()=>{
          window.location = spacesPath();
        }, 1000)
      }else{
        alertify.error("The question could not be deleted, please try again or contact us if the issue persists");
      }
    }
  };

  return (
    <div className={`${classes.questionListItem} white-content-block`} key={id}>
      <div className="avatar-row">
        <AvatarUsername account={account} extraText="asked a question in" extraLinkText={space.title} extraLinkUrl={space.url} />
        <Dropdown
          viewUrl={url}
          editUrl={adminEditQuestionPath(id)}
          activityId={activityId}
          followAccountId={account.id} />
      </div>
      {
        !editing
          ?  <>
               <h2>{titleState}</h2>
               <div className="unmarked-profile-hover-links">
                 { descriptionState && <CustomMarkdown source={descriptionState} /> }
               </div>
             </>
           :  <div className="edit-question-form">
                <textarea className="form-control" rows="2" type="text" ref={titleInput} defaultValue={titleState} /> <br />
                <textarea className="form-control" rows="6" type="text" ref={descriptionInput} defaultValue={descriptionState} /> <br />
                <button className="btn btn-link no-padding" onClick={handleEdit}>Save</button>
              </div>
      }
      { !answering && <hr /> }
      { answering && <AnswerForm answering={answering}  onCancel={handleAnswerButton} account={account} answerInput={answerInput}  /> }
      <div className="actions-row">
        <UpvoteButton
          defaultCount={voteCount}
          defaultVoted={voted}
          voteableId={id}
          voteableType="Space::Question" />
        <div className="top-vote-avatars">
          <TopVoteAvatars />
        </div>
        {
          (CurrentUserData().is_admin || CurrentUserData().id == account.id) && (
            <div className="owner-actions">
              <button className="btn btn-link no-padding" onClick={toggleEditForm}>
                { CurrentUserData().is_admin && "(Admin) " }Edit
              </button>
              <button className="fa fa-trash btn btn-link no-padding" onClick={handleDestroy}></button>
            </div>
          )
        }
        <div className="right-actions">
          <ShareButton
            url={url}
            title="Check out this question on Influence.co"
            hashtags="#influenceco"/>

          { !CurrentUserData().messaging_blocked  && (
            <button className="btn btn-primary" onClick={handleAnswerButton}>
              <i className="fa fa-pencil"></i>&nbsp;
              { answering ? "Submit" : "Answer" }
            </button>
          )}
        </div>
      </div>
      <AnswersList questionId={id} />
    </div>
  )
}

export default QuestionDisplay;
