import { createState, useState } from "@hookstate/core";

const CurrentUserState = createState(window._inf_user_data);

export default function UseCurrentUserState(accessDirectly) {
  if (accessDirectly) {
    return CurrentUserState;
  }

  return useState(CurrentUserState);
}
