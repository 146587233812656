import { useEffect } from 'react';
import SpacesList from "components/spaces/SpacesList";
import QuestionsList from "components/spaces/QuestionsList";
import AskQuestionOpener from "components/spaces/AskQuestionOpener";
import QuestionsListFilters from "components/spaces/QuestionsListFilters";
import SpaceHeader from "components/spaces/SpaceHeader";
import SpaceDetail from "components/spaces/SpaceDetail";
import AnswersHero from "components/spaces/AnswersHero";
import MobileSpacesListOpener from "components/spaces/MobileSpacesListOpener";
import UseSpaceState from "data/SpaceState";
import SidebarCarousel from "components/spaces/SidebarCarousel";
import CurrentUserData from "components/common/CurrentUserData";

const SpacesPage = (props) => {
  const currentSpaceState = UseSpaceState();
  const { currentSpaceId, currentFilter, spaces, currentMobilePanel }= currentSpaceState.get();
  const propSpaceId = props.space_id;
  const propCurrentFilter = props.currentFilter;
  const { memberOnly } = props;
  const currentSpace = (currentSpaceId && currentSpaceId != "all" && currentSpaceId != "mine") ? spaces[currentSpaceId] : null;

  useEffect(()=>{
    if(propSpaceId && !currentSpaceId){
      currentSpaceState.merge({currentSpaceId: propSpaceId});
    }
    if(propCurrentFilter && !currentFilter){
      currentSpaceState.merge({currentFilter: propCurrentFilter});
    }
  },[]);

  const mobileClass = (panel) => {
    return panel == currentMobilePanel ? "" : "hidden-sm hidden-xs"
  };

  return (
    <div className="position-relative">
      <div className={`container ${currentMobilePanel == "left" && "sm-fullscreen xs-fullscreen"}`}>
        <div className="row margin-top hidden-xs"></div>
        <div className="row">
          <div className={`col-sm-12 col-md-3 ${mobileClass('left')} sticky`}>
            <SpacesList />
          </div>
          <div className={`col-sm-12 col-md-6 no-padding-xs no-padding-sm ${mobileClass('middle')}`}>
            <MobileSpacesListOpener />
            {
              currentSpace
              ? <SpaceHeader space={currentSpace} />
              : <>
                  { !CurrentUserData().ff_hide_posting && <AskQuestionOpener type="text" /> }
                  <AnswersHero />
                </>
            }
            <QuestionsListFilters />
            <QuestionsList memberOnly={memberOnly} />
          </div>
          <div className={`col-sm-12 col-md-3 ${mobileClass('right')}`}>
            {
              currentSpace &&
              <SpaceDetail space={currentSpace} />
            }
            <div className="white-content-block hidden-sm">
              <SidebarCarousel />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default SpacesPage;
