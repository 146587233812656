import { useState, useRef, useEffect } from "react";
import { commentsAPIPath, deleteCommentAPIPath, suggestUsernameAPIPath } from "utilities/UrlHelpers";
import JSONHeaders from "utilities/JSONHeaders";
import { makeStyles } from "@material-ui/core/styles";
import useBoostrapVariables from "utilities/BootstrapVariables";
const cssDefaults = useBoostrapVariables();
import { usernameRegex } from "utilities/RailsHelpers";
import CurrentUserData from "components/common/CurrentUserData";
import { MentionsInput, Mention } from 'react-mentions';
import suggestStyle from './suggestStyle'

const useFormStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    alignItems: "start",
    marginTop: theme.spacing(1),
    "& > div": {
      flex: 2,
      marginRight: theme.spacing(2),
    },
    "& button": {
      color: "white",
      fontSize: 14,
      padding:  `0 ${theme.spacing(2)}px`
    },
    "& .MuiOutlinedInput-input": {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      fontSize: 14
    },
    "& mark": {
      padding: "0 !important",
      backgroundColor: "#ccf",
      border: "#8888ff 1px solid",
      borderRadius: "3px",
      color: "inherit",
    }
  }
}));

const CommentForm = (props) => {
  const { disableAutoFocus, commentableId, commentableType, parentCommentId, onCreate, onCancel, defaultValue } = props;
  const classes = useFormStyles();
  const [textValue, setTextValue] = useState(defaultValue);
  const { ff_comment_mentions_modal, id: currentUserID } = CurrentUserData();

  const handleSubmit = async () => {
    if(!currentUserID){
      window.triggerSignupWall("comment-form");
      return;
    }

    if(textValue != ""){
      const result = await Fetch(commentsAPIPath(), {
        ...JSONHeaders,
        method: "POST",
        body: JSON.stringify({
          commentable_id: commentableId,
          commentable_type: commentableType,
          text: textValue,
          parent_comment_id: parentCommentId ? parentCommentId : ""
        })
      });

      if(result.ok){
        const data = await result.json();
        setTextValue(null);
        if(onCreate){
          onCreate(data);
        }
      }else{
        alertify.error("There was an error trying to save your comment.");
      }
    }else{
      onCancel();
    }
  }

  const handleClick = () => {
    if(!currentUserID){
      window.triggerSignupWall("comment-form");
      return;
    }
  };

  const triggerChange = async (e) => {
    const commentValue = e.target.value;
    setTextValue(commentValue);
  };

  const searchUsernames = async (username, callback) => {
    const resp = await Fetch(suggestUsernameAPIPath(username));
    const results = await resp.json();
    callback(results);
    return results;
  }

  if(CurrentUserData().messaging_blocked){
    return null;
  }

  return (
    <div className={`${classes.form} ${!textValue && 'no-text'} comment-form`} onClick={handleClick}>
      <MentionsInput
        placeholder="Add comment"
        onChange={triggerChange}
        name="comment_text"
        id="comment_text"
        value={textValue || ""}
        style={suggestStyle}
      >
        { /* markup = @<~*__id__*~> = stupid workaround for @__id__ not working https://github.com/signavio/react-mentions/issues/446 --> */ }
        { (currentUserID && ff_comment_mentions_modal)
          ? <Mention
                displayTransform={(id, display) => { return `@${id}` }}
                trigger="@"
                markup="@<~*__id__*~>"
                data={searchUsernames}
                appendSpaceOnAdd={true}
              />
          : <Mention
                trigger="+=+=+=+=z~~~!@#$%^*()_+_)(*^%$#@!~)~~~+=+=+=+=z"
              /> }
      </MentionsInput>
      { textValue && <button className="btn btn-primary" onClick={handleSubmit}>Submit</button> }
    </div>
  )
};

export default CommentForm;
