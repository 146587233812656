import { useEffect } from "react";
import ReactDOM from "react-dom";
import CampaignInvitationForm from "components/campaign_invitations/CampaignInvitationForm";
import EventTracking from "components/common/EventTracking";

const CampaignInvitationButton = (props) => {
  const {
    invitationAccountId,
    username,
    buttonText,
    wrapperClasses
  } = props;

  const openModal = () => {
    if ($('#campaign-invitation-modal').length > 0) {
      return;
    }

    EventTracking("Clicked Invite to Campaign Button", {...props});

    let elm = $('<div></div>');
    const onClose = function() {
      $('#campaign-invitation-modal').modal('hide');
      setTimeout(() => {$(elm).remove()}, 1000);
    };

    $('body').append(elm);

    ReactDOM.render(
      <div id="campaign-invitation-modal" className="full-screen-xs modal fade modal-thicker-padding">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-close-button" onClick={() => {onClose()}}>&times;</div>
            <div className="modal-body">
              <CampaignInvitationForm
                username={props.username}
                invitationAccountId={props.invitationAccountId} />
            </div>
          </div>
        </div>
      </div>,
      elm[0]
    )

    $('#campaign-invitation-modal').modal('show');

    return false;
  }
  
  return (
    <a href="#" className={`btn btn-primary ${wrapperClasses}`} onClick={()=>{openModal()}}>
      {buttonText || 'Invite to Campaign'}
    </a>
  )
}

export default CampaignInvitationButton; 
