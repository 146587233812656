/* eslint-disable */
import { useState } from "react";
import FollowButton from "FollowButton";
import NoteOpener from "NoteOpener";
import CircularImage from "CircularImage/index";
import CurrentUserData from 'CurrentUserData';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SendMessageButton from "components/messages/SendMessageButton";
import ArchiveMessageButton from 'components/messages/ArchiveMessageButton';
import StarConversationButton from 'components/messages/StarConversationButton';
import BlockButton from 'components/messages/BlockButton';
import { goSelectPath, goProPath } from "utilities/UrlHelpers";
import UseMessagesState from 'data/MessagesState';
import SearchWithinConversationBox from 'components/messages/search/SearchWithinConversationBox';
import SelectProLabels from 'components/accounts/SelectProLabels';
import ProfileTypeBadge from 'components/accounts/ProfileTypeBadge';
import { assetUrl } from "utilities/RailsHelpers";

const AccountSmallCard = (props) => {
  const {
    accountData,
    followed,
    showNoteButton,
    showMessageButton
  } = props;

  const [menuElement, setMenuElement] = useState(null);

  const handleMenuClick = event => {
    setMenuElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuElement(null);
  };

  const messageButton = () => {
    if(!showMessageButton || !accountData.isClaimed){ return ''; }

    return (
      <MenuItem onClick={handleCloseMenu}>
        <a href="#"><SendMessageButton messagingAccountId={accountData.id} wrapperClasses=" " /></a>
      </MenuItem>
    )
  };

  const StarButton = () => {
    if(showMessageButton){ return ''; }
    const conversationData = UseMessagesState().conversationData.get();
    return (
      <StarConversationButton targetAccountId={accountData.id} starred={conversationData.starred} />
    )
  }

  const archiveMessageButton = () => {
    if(showMessageButton){ return ''; }

    return (
      <MenuItem onClick={handleCloseMenu}>
        <a href="#">
          <ArchiveMessageButton messagingAccountId={accountData.id} classes=" " />
        </a>
      </MenuItem>
    )
  }

  const noteButton = () => {
    if(!showNoteButton){ return ''; }

    return (
      <MenuItem onClick={handleCloseMenu} className="note-trigger">
        <NoteOpener selectedAboutId={accountData.id} buttonText="Add Note" containerClasses="dropdown-link" iconClasses=" " classes="dropdown-link" />
      </MenuItem>
    )
  };

  const ReportButton = () => {
    if(showMessageButton){ return ''; }
    let mailtoLink = "mailto:support@influence.co";
    mailtoLink += `?subject=${CurrentUserData().username} reporting conversation http://${window.location.host}/influencers/${CurrentUserData().id}/conversations/new?recipient_id=${accountData.id}`
    return (
      <MenuItem onClick={handleCloseMenu}>
        <a target="_blank" href={mailtoLink}>Report</a>
      </MenuItem>
    )
  };

  //Overwrite drawer styles inline
  const styles = {
    verticalAlign: 'text-top',
    fontSize: '10px',
    lineHeight: '10px'
  }

  return(
    <div className="small-account-card-container">
      <div className="small-account-card-avatar-username">
        <CircularImage src={accountData.profilePicture} />
        <div className="small-card-username">
          <p>{accountData.fullName}</p>
          <p>
            <a href={accountData.profileUrl} target="_blank">
              <img className="inf-logo" src={assetUrl('influence-icon.svg')}/>&nbsp;
              @{accountData.username}
            </a>
          </p>
          {
            accountData.igUsername &&
              <p>
              <a href={ `https://www.instagram.com/${accountData.igUsername}` } target="_blank">
                <span className="fa fa-instagram"></span>&nbsp;
                @{accountData.igUsername}
              </a>
              </p>
          }
        </div>
        <StarButton />
        <div className="account-dropdown">
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVertIcon style={{ fontSize: 20 }}/>
          </IconButton>
          <Menu
            anchorEl={menuElement}
            keepMounted
            disablePortal
            open={Boolean(menuElement)}
            onClose={handleCloseMenu}
          >
            {messageButton()}
            {archiveMessageButton()}
            {noteButton()}
            <MenuItem onClick={handleCloseMenu}>
              <FollowButton
                source="FollowButton:SmallCard"
                followedId={accountData.id} 
                followed={followed} 
                baseClass="dropdown-link link-button" />
            </MenuItem>
            { !showMessageButton  && (
              <MenuItem onClick={handleCloseMenu}>
                <BlockButton classes="dropdown-link link-button" />
              </MenuItem>
            )}
            <ReportButton />
          </Menu>
        </div>
      </div>
      { !showMessageButton && <SearchWithinConversationBox /> }
      <p>
        <ProfileTypeBadge accountType={accountData.accountType} styles={styles} />
      </p>
      <p>
        <SelectProLabels isSelect={accountData.isSelect} isPro={accountData.isPro} styles={styles} />
      </p>
      { accountData.location && <p>{accountData.location}</p> }
      <p>
        <strong>
          {accountData.reach}&nbsp;
        </strong>
        Reach
      </p>
    </div>
  )
}

export default AccountSmallCard;
