const SearchHeader = (props) => {
  const { category } = props

  const renderSearchHeader = () => {
    return (
      <>
        <div className='search-header'>
          {`${category}`}
        </div>
      </>
    )
  }

  return renderSearchHeader()
}

export default SearchHeader
