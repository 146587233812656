import Avatar from '@material-ui/core/Avatar';

const HoverableAvatar = (props) => {
  const { username, src, classes } = props;
  return (
    <Avatar
      src={src}
      className={`profile-modal-opener ${classes || ''}`}
      data-profile-modal-username={username}
      />
  )
};

export default HoverableAvatar;
