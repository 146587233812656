import { useEffect, useState, useRef } from 'react';
import routes from 'UrlHelpers';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import EventTracking from "components/common/EventTracking";

const PhoneNumberVerification = (props) => {
  const {
    pnv
  } = props;

  const [pnvData, setPnvData] = useState(pnv);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const codeRef = useRef();

  const sendPhoneNumber = async () => {
    EventTracking('PNV - Send Phone Number');
    const resp = await Fetch(routes.APIPostPhoneNumberVerificationsPath(), {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({phone_number: phoneNumber})
    });

    if(resp.ok) {
      setPnvData(await resp.json())
    }
  };

  const sendCode = async () => {
    EventTracking('PNV - Send Code');
    const resp = await Fetch(routes.APIPutPhoneNumberVerificationsPath(pnvData.id), {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({code: codeRef.current.value})
    });

    if(resp.ok) {
      setPnvData(await resp.json())
    }
  };

  const restart = async () => {
    EventTracking('PNV - Start over');
    const resp = await Fetch(routes.APIDeletePhoneNumberVerificationsPath(pnvData.id), {
      method: 'DELETE'
    });

    if(resp.ok) {
      setPnvData(await resp.json())
    }
  };

  const onPhoneNumberChange = (valid, _, __, number) => {
    if (valid) {
      setPhoneNumber(number.replace(/[^0-9+]/g, ''))
    }
  }

  useEffect(() => {
    if (pnvData.state == "verified") {
      EventTracking('PNV - Verified');
      setTimeout(() => {
        $("#verify-pn").modal("hide");
      }, 2000)
    }
  }, [pnvData?.state]);

  const renderNew = () => {
    return(
      <div className={`text-left panel panel-info`}>
        <div className="panel-heading">
          <h4>Verify your phone number</h4>
        </div>

        <div className="panel-body">
          <p>
            To maintain the quality of our community, we require businesses
            to verify their identities before publishing campaigns. We only
            use this number for verification purposes. We will never text you
            otherwise, or share your number with anyone.
          </p>

          
          <label htmlFor="phone">Enter your mobile phone number</label><br/>

          <div className="row">
            <div className="col-sm-6">

              <IntlTelInput
                containerClassName="intl-tel-input"
                inputClassName="form-control input-lg"
                fieldName="phone_number"
                onPhoneNumberChange={onPhoneNumberChange}    
              />

            </div>
            <div className="col-sm-6">
              <p>
                <button onClick={sendPhoneNumber} className="btn btn-success btn-block btn-lg">Get a verification code</button>
              </p>
            </div>
          </div>

          <br/>
        

          { pnvData?.errors?.phoneNumber && <div className="alert alert-danger">That phone number is not valid or not a cell phone (voip numbers like Google Voice will not work).</div> }

        </div>
      </div>  
    )
  }

  const renderPending = () => {
    return(
      <div className={`text-left panel panel-warning`}>
        <div className="panel-heading">
          <h4>Enter verification code</h4>
        </div>

        <div className="panel-body">
          <p>
            <input ref={codeRef} type="text" inputmode="numeric" autocomplete="one-time-code" className="form-control input-lg"/>  
          </p>

          {
            pnvData?.errors?.code &&
              <div className="alert alert-danger">
                That code is not valid. Codes expire
                after 10 minutes. Click below if you
                need to start over
              </div>
          }

          <p>
            <button onClick={sendCode} className="btn btn-success btn-block">Continue</button>
          </p>

          <p className="text-right">
            <small>
              Need to start over? <a href="#" onClick={restart}>Click here</a>
            </small>
          </p>
        </div>
      </div>  
    )
  }

  const renderVerified = () => {
    return(
      <div className={`text-left panel panel-success`}>
        <div className="panel-heading">
          <h4>Account verified!</h4>
        </div>

        <div className="panel-body">
          <p className="lead">
            Thank you. You can continue where you left off.
          </p>
        </div>
      </div>  
    )
  }

  const renderBanned = () => {
    return(
      <div className={`text-left panel panel-danger`}>
        <div className="panel-heading">
          <h4>Contact Support</h4>
        </div>

        <div className="panel-body">
        <p className="lead">
            There is an issue with your account. Please contact support by emailing
            support@influence.co
          </p>
        </div>
      </div>  
    )
  }

  switch(pnvData?.state) {
    case 'new': return renderNew()
    case 'pending': return renderPending()
    case 'verified': return renderVerified()
    case 'banned': return renderBanned()
    default: return null
  }
};

export default PhoneNumberVerification;