(() => {
  $(() => {
    window.checkForDeletedComment = function() {
      if (window.location.hash && window.location.hash.indexOf('comment-') == 1) { // not 0 because # is first character of window.location.hash
        let comment_id = window.location.hash
        if($(comment_id).length == 0){
          // comment has been deleted
          $([document.documentElement, document.body]).animate({
            scrollTop: 0
          })
          let deleted_comment_element = $(`<div class='deleted-comment'>
            <span class="fa fa-exclamation-circle"></span>&nbsp;
            The comment was deleted
            <span class="fa fa-times"></span>
          </div>`)
          deleted_comment_element.hide();
          deleted_comment_element.find('.fa-times').click(()=>{
            deleted_comment_element.fadeOut()
          })
          if (!$('.deleted-comment')[0]) {
            $('.feed-middle-column').prepend(deleted_comment_element)
          }
          deleted_comment_element.fadeIn()
        }else{
          // Delay scroll to let window default hash behavior happen first
          setTimeout(()=>{
            // add class to comment to highlight it.
            $(comment_id).addClass('comment-highlight')
            
            // Scroll element into view
            $([document.documentElement, document.body]).animate({
              scrollTop: $(comment_id).offset().top - 200
            }, ()=>{

              // remove comment highlighting after a bit of time
              setTimeout(()=>{
                $(comment_id).removeClass('comment-highlight', 500)
              }, 5000)
            })
          }, 10)
        }
      }
    };
  });
})();
