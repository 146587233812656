import {createTheme} from '@material-ui/core/styles';
import useBoostrapVariables from "utilities/BootstrapVariables";
const cssDefaults = useBoostrapVariables();

const customBreakpointsTheme = createTheme({
  breakpoints: {
    values: {
      xs: parseInt(cssDefaults.screenXs.replace("px","")) + 1,
      sm: parseInt(cssDefaults.screenSm.replace("px","")) + 1,
      md: parseInt(cssDefaults.screenMd.replace("px","")) + 1,
      lg: parseInt(cssDefaults.screenLg.replace("px","")) + 1,
      xl: false
    },
  },
});

export default customBreakpointsTheme;
