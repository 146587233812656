import { useRef } from "react";
import LoadingSpinner from "components/common/Loading";
import CircularImage from "components/common/CircularImage/index";
import ConversationListLoadMore from "components/messages/LoadMore";
import UseMessagesState from 'data/MessagesState';
import StarConversationButton from 'components/messages/StarConversationButton';
import CurrentFilter from 'components/messages/CurrentFilter';
import { assetUrl } from "utilities/RailsHelpers";
import ScrollTracking from 'components/common/ScrollTracking';

const ConversationsList = (props) => {
  const messagesDrawerState = UseMessagesState();
  const {
    conversationListData,
    setMessagesDrawerData,
    selectConversation,
    messagingAccountId,
    filter,
    currentConversationListScrollPosition,
    campaignsAvailable
  } = messagesDrawerState.get();
  const conversationListElement = useRef();

  const {
    conversations,
    hideLoadMore,
    conversationsLoading
  } = conversationListData;


  function renderListItemsOutput() {
    let conversationItemOutput = [];
    if(Object.keys(conversations).length > 0){
      const conversationIds = Object.keys(conversations)
      conversationIds.forEach((currentConversationId) => {
        const currentConversation = conversations[currentConversationId];
        const { recipient, id, unread, unreadCount, lastMessage, starred } = currentConversation
        conversationItemOutput.push(
          <li
            className={`conversation-${ id } ${messagingAccountId == recipient.id ? 'selected' : ''}`}
            key={`conversation-${ id }`}
            onClick={()=>{selectConversation(recipient.id)}}>
            <div className='conversation-row'>
              <CircularImage src={ recipient.profilePicture } />
              <div className="conversation-item-data">
                <p className="no-wrap-ellipsis">
                  { recipient.username }
                  { unreadCount > 0
                      ? <span className="badge badge-info">{ unreadCount }</span>
                      : '' }
                </p>
                { recipient.headline != '' && recipient.headline != null
                    ? <p className="no-wrap-ellipsis influencer-headline">{ recipient.headline }</p>
                    : '' }
                { lastMessage != ''
                  ? <p className="text-muted">
                      <small>{lastMessage}</small>
                    </p>
                  : '' }
              </div>
              <StarConversationButton targetAccountId={currentConversationId} starred={starred} />
            </div>
          </li>
        )
      })
    }
    return conversationItemOutput;
  }

  function renderListOutput() {
    return (
      Object.keys(conversations).length > 0
        ? <ul>
            {renderListItemsOutput()}
          </ul>
        : <div className="container-fluid offset-gutter-left maxed-height">
            <div className="row maxed-height">
              <div className="col-xs-12 text-center no-conversations maxed-height">
                <div className="text-center">
                  <img src={assetUrl('no-messages.svg')} />
                </div>
                <strong>
                  {
                    filter == 'inbox'
                      ? 'No conversations yet, start one now.'
                      : `No ${filter} conversations.`
                  }
                </strong>
              </div>
            </div>
          </div>
    )
  }
  const hasCampaignsClass = () => {
    return (campaignsAvailable ? "has-campaigns" : "")
  }

  return (
    <div className={`maxed-height conversations-list-container ${hasCampaignsClass()}`}>
       <ScrollTracking
         trackElement={conversationListElement}
         trackValueName={'currentConversationListScrollPosition'}
         trackValue={currentConversationListScrollPosition}
         setDataMethod={setMessagesDrawerData} />
      <div id="conversations-list"
           key="conversations-list"
           className={`maxed-height`}
           ref={conversationListElement}>
        {
          conversationsLoading
            ? <div className="text-center"><LoadingSpinner /></div>
            : <>
                {filter != 'inbox' ? <CurrentFilter /> : ''}
                {renderListOutput()}
                <ConversationListLoadMore />
              </>
        }
      </div>
    </div>
  )
}

export default ConversationsList;
