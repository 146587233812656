import { useState } from "react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import UseMessagesState from 'data/MessagesState';


export default function MessagesFilters(props) {
  const messagesDrawerState = UseMessagesState();
  const { setMessagesDrawerData, fetchConversationsListData, filter } = messagesDrawerState.get();

  const handleFilterClick = filter => {
    if(filter == messagesDrawerState.get().filter){
      handleCloseMenu();
      return;
    }
    setMessagesDrawerData({filter: filter}, ()=>{fetchConversationsListData()});
    handleCloseMenu();
  };

  const [menuElement, setMenuElement] = useState(null);

  const handleMenuOpenClick = event => {
    setMenuElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuElement(null);
  };

  const renderOptions = () => {
    let optionsOutput = [];
    ['Inbox', 'Unread', 'Starred', 'Archived'].forEach((currentFilter) => {
      const selected = (currentFilter.toLowerCase() == filter || !filter && currentFilter == 'Inbox') ? 'selected' : ''
      optionsOutput.push(
        <a href="#"
           onClick={()=>{handleFilterClick(currentFilter.toLowerCase());}}
           className={selected}
           key={currentFilter}>
          {currentFilter}
        </a>
      );
    });
    return optionsOutput;
  }

  return (
    <div className="messages-filters pull-right" key="messages-filters">
      <div className='fa fa-sliders' onClick={handleMenuOpenClick} key="filters-menu-open"></div>
      <Menu
        anchorEl={menuElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        keepMounted
        disablePortal
        open={Boolean(menuElement)}
        onClose={handleCloseMenu}
        key="message-filters-menu"
      >
        {renderOptions()}
      </Menu>
    </div>
  );
}
