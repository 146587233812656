import JSONHeaders from "JSONHeaders";

const CustomFetch = async (url, options) => {
  options = options || {};
  options.credentials = options.credentials || 'same-origin';
  return fetch(url, options);
};
window.Fetch = CustomFetch;

const GetFetch = async (path) => {
  return await CustomFetch(path, {
    ...JSONHeaders,
    method: "GET"
  });
};

const PostFetch = async (path, body) => {
  return await CustomFetch(path, {
    ...JSONHeaders,
    method: "POST",
    body: JSON.stringify(body)
  });
};

const PutFetch = async (path, body) => {
  return await CustomFetch(path, {
    ...JSONHeaders,
    method: "PUT",
    body: JSON.stringify(body)
  });
};

const DeleteFetch = async (path, body) => {
  return await CustomFetch(path, {
    ...JSONHeaders,
    method: "DELETE",
    body: JSON.stringify(body)
  });
};

export default CustomFetch;
export {GetFetch, PostFetch, PutFetch, DeleteFetch}
