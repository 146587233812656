import ReactDOM from "react-dom";
import Drawer from "components/Drawer";

const MessageDrawerOpener = (props) => {
  const {
    messagingAccountId
  } = props;

  const openMessageDrawer = () => {
    let elm = $('<div></div>');

    const drawerContentProps = {
      messagingAccountId: messagingAccountId,
      showConversationList: true
    }

    $('body').append(elm);
    ReactDOM.render(
      <Drawer
        drawerHeaderContainerClass='hidden-xs'
        drawerContentType="MessageDrawer"
        drawerContentProps={drawerContentProps}
        redirectOnClose={true}
        fullWidth={true}
        idScope="messages" />
      ,
      elm[0]
    );
    return null;
  }
  return openMessageDrawer()
}

export default MessageDrawerOpener;
