import { useRef } from 'react';
import Modal from '@material-ui/core/Modal';
import { flagsAPIPath } from 'utilities/UrlHelpers';
import UseCurrentUserState from 'CurrentUserState';
import useSharedModalStyles from './ModalStyles';
import downloadAlertImage from './download-alert.svg';

export default function Warning(props){
  const { message, type, showWarning, setShowWarning, setShowPreview } = props;
  const { attachmentFilename, attachmentDownloadUrl } = message;
  const hideWarningRef = useRef();
  const classes = useSharedModalStyles();
  const currentUserState = UseCurrentUserState();

  const handleWarningDownloadClick = async (ev) => {
    ev.persist();
    if(hideWarningRef.current.checked){
      const resp = await Fetch(flagsAPIPath("hide_download_warning", true), { method: "POST" });
      currentUserState.merge({flags_hide_download_warning: true});
    }
    handleClose();
    if(type == "preview"){
      ev.preventDefault();
      ev.stopPropagation();
      setShowPreview(true);
    } else {
      return true;
    }
  }

  const handleClose = () => {
    setShowWarning(false);
  };

  return (
    <Modal open={!!showWarning} onClose={handleClose}>
      <div>
        <div className={`text-center ${classes.warningModal}`}>
          <div className="text-left">
            <div className="close-modal">
              <button onClick={handleClose} className="btn btn-link">X</button>
            </div>
            <div>
              <strong>{attachmentFilename}</strong>
            </div>
          </div>
          <div className="warning-container">
            <img src={downloadAlertImage} />
            <h1>Download File</h1>
            <p>Be careful when downloading files!</p>
            <p>influence.co isn't responsible for the content of the files being uploaded, so use caution when downloading, especially if the file is from someone you don't know or seems suspicious.</p>
            <p>
              <strong>
                <label>
                  <input type="checkbox" name="warning-checkbox" ref={hideWarningRef} />{' '}
                  Don't show this warning again for anyone
                </label>
              </strong>
            </p>
            {
              type == "preview"
              ? (
                <button onClick={handleWarningDownloadClick} className="btn btn-info">Preview</button>
              )
              : (
                <a href={attachmentDownloadUrl} onClick={handleWarningDownloadClick} className="btn btn-info">Download</a>
              )
            }
          </div>
        </div>
      </div>
    </Modal>
  )
};
