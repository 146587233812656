import Drawer from "components/Drawer";
import { conversationArchivePath, conversationUnarchivePath } from "utilities/UrlHelpers";
import UseMessagesState from 'data/MessagesState';

const UnarchiveAndSendMessageButton = (props) => {
  const messagesDrawerState = UseMessagesState();
  const {
    classes
  } = props;

  const {
    messagingAccountId,
    setMessagesDrawerData,
    conversationListData,
    fetchConversationsListData,
    fetchMessagesDrawerData,
    selectConversation
  } = messagesDrawerState.get();

  const conversation = conversationListData.conversations[messagingAccountId];
  const defaultCSSClasses = 'btn btn-default ga-actions-contact btn-lg truncate-text btn-contact';

  const unarchiveAndSendMessage = (event) => {
    event.preventDefault();
    const path = conversationUnarchivePath(messagingAccountId)
    Fetch(path, { method: 'PUT' })
      .then(response => response.json())
      .then(data => {
        setMessagesDrawerData({filter: 'inbox', messagingAccountId: messagingAccountId}, ()=>{
          fetchConversationsListData();
          fetchMessagesDrawerData();
        });
      });
  }

  return (
    <span className={`archive-message ${classes || defaultCSSClasses}`}
       onClick={unarchiveAndSendMessage}>
      Unarchive to send a message
    </span>
  )
}

export default UnarchiveAndSendMessageButton;
