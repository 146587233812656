import { Component } from "react";
import NotesDrawer from 'containers/NotesDrawer';
import MessageDrawer from 'containers/MessageDrawer';
import NotificationsDrawer from 'containers/NotificationsDrawer';
import "../../assets/stylesheets/drawer.scss";
import WithDefaultTheme from 'themes/WithDefaultTheme';

export default class Drawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idScope: props.idScope,
      drawerContentProps: props.drawerContentProps,
      drawerHeaderContainerClass: props.drawerHeaderContainerClass || '',
      defaultDrawerTitle: props.drawerTitle,
      drawerTitle: props.drawerTitle,
      defaultCloseDrawer: this.closeDrawer,
      closeDrawer: this.closeDrawer,
      backgroundClickClose: props.backgroundClickClose || false,
      showBack: false,
      drawerContentType: props.drawerContentType,
      windowLoadCallback: props.loadCallback,
      windowResizeCallback: props.resizeCallback,
      onMountCallback: props.onMount,
      redirectOnClose: props.redirectOnClose,
      onClose: props.onClose,
      isBaseDrawer: !document.getElementsByTagName('body')[0].querySelector('.drawer.in'),
      closed: true,
      closedClass: '',
      fullWidth: props.fullWidth
    };
  };

  componentDidMount = () => {
    const { state } = this;
    const { windowLoadCallback, windowResizeCallback, onMountCallback } = state;
    if (windowLoadCallback){
      window.addEventListener("load", windowLoadCallback);
    }
    if (windowResizeCallback){
      window.addEventListener("resize", windowResizeCallback);
    }
    if (onMountCallback){
      onMountCallback();
    }
    this.setState({
      closed: false
    });

    document.getElementsByTagName('body')[0].style.overflow = "hidden";

    setTimeout(()=>{
      this.setState({
        closedClass: 'in'
      });
    }, 200);
  };

  scopedId = (baseId) => {
    const { state } = this;
    const { idScope } = state;

    return idScope
           ? `${idScope}-${baseId}`
           : ''
  }

  closeDrawer = () => {
    const { state } = this;
    const { onClose, isBaseDrawer, redirectOnClose } = state;
    const onCloseDelayed = () => {
      // set this separately so that dom/css has time to render removal of "in" class before removing the element
      this.setState({
        closed: true
      });
      if(onClose){
        onClose();
      }
      if(isBaseDrawer){
        document.getElementsByTagName('body')[0].style.overflow = "auto";
      }
    };

    if(redirectOnClose){
      window.location = '/';
    }else{
      // Execute callback after changing the class
      setTimeout(onCloseDelayed, 200);
      this.setState({
        closedClass: ''
      });
    }
  }

  setDrawerTitle = (title) => {
    this.setState({
      drawerTitle: title
    });
  };

  resetDrawerTitle = () => {
    const { state } = this;
    const { defaultDrawerTitle } = state;
    this.setState({
      drawerTitle: defaultDrawerTitle
    });
  };

  setCloseDrawer = (closeDrawerFunction) => {
    this.setState({
      closeDrawer: closeDrawerFunction
    });
  };

  resetCloseDrawer = () => {
    const { state } = this;
    const { defaultCloseDrawer } = state;
    this.setState({
      closeDrawer: defaultCloseDrawer
    });
  };

  setShowBack = (showBack) => {
    this.setState({
      showBack: !!showBack
    });
  };

  drawerContent = () => {
    const { state } = this;
    const { drawerContentType, drawerContentProps } = state;

    switch (drawerContentType) {
      case 'NotesDrawer':
        return (
          <NotesDrawer { ...drawerContentProps } drawerMethods={this.drawerMethods()} />
        )
      case 'MessageDrawer':
        return (
          <MessageDrawer { ...drawerContentProps } drawerMethods={this.drawerMethods()} />
        )
      case 'NotificationsDrawer':
        return (
          <NotificationsDrawer { ...drawerContentProps } drawerMethods={this.drawerMethods()} />
        )
    }
  }

  drawerMethods = () => {
    return {
      setShowBack: this.setShowBack,
      setDrawerTitle: this.setDrawerTitle,
      resetDrawerTitle: this.resetDrawerTitle,
      setCloseDrawer: this.setCloseDrawer,
      resetCloseDrawer: this.resetCloseDrawer,
      closeDrawer: this.closeDrawer,
    }
  }

  handleBackgroundClick = () => {
    if(this.state.backgroundClickClose){
      this.closeDrawer();
    }
  }

  render() {
    const { state, handleBackgroundClick } = this;
    const { drawerTitle, drawerContentType, drawerHeaderContainerClass, isBaseDrawer, closed, closedClass, showBack, closeDrawer, fullWidth } = state;
    if(closed){
      return '';
    }

    return (
      <WithDefaultTheme>
      <div id={this.scopedId('drawer-container')}>
        <div
          className={`drawer ${closedClass} ${fullWidth ? 'full-width' : null}`}
          id={this.scopedId('drawer')}>
            <div
              className={`drawer-backdrop ${!isBaseDrawer ? 'no-opacity' : ''}`}
              onClick={handleBackgroundClick}
              id={this.scopedId('drawer-backdrop')}></div>
            <div className="drawer-content"
                 id={this.scopedId('drawer-content')}>
              <h2 className={drawerHeaderContainerClass}>
                <a style={{cursor: 'pointer'}}
                   id={this.scopedId('close-drawer')}
                   onClick={()=>closeDrawer()}>
                   {
                     isBaseDrawer && !showBack
                      ? <span>&times;</span>
                      : <i className="fas fa-angle-left"></i>
                   }
                 </a>
               {drawerTitle}&nbsp;
              </h2>
              {this.drawerContent()}
            </div>
        </div>
      </div>
      </WithDefaultTheme>
    )
  }
}
