import Drawer from "components/Drawer";
import { conversationArchivePath, conversationUnarchivePath } from "utilities/UrlHelpers";
import UseMessagesState from 'data/MessagesState';
import { none } from "@hookstate/core";

const ArchiveMessageButton = (props) => {
  const messagesDrawerState = UseMessagesState();
  const {
    messagingAccountId,
    classes,
  } = props;

  const {
    setMessagesDrawerData,
    conversationData,
    conversationListData,
    showConversationList,
    drawerMethods
  } = messagesDrawerState.get();

  const archived = conversationData.archived;

  const defaultCSSClasses = 'btn btn-default ga-actions-contact btn-lg truncate-text btn-contact';

  const archiveMessage = (event) => {
    event.preventDefault();
    if(showConversationList){
      messagesDrawerState.conversationListData.conversations[messagingAccountId].set(none);
    }
    const path = archived ? conversationUnarchivePath(messagingAccountId) : conversationArchivePath(messagingAccountId)
    Fetch(path, { method: 'PUT' })
      .then(response => response.json())
      .then(data => {
        if(showConversationList){
          setMessagesDrawerData({
            messagingAccountId: null,
            showSidebarMobile: false
          });
        }else{
          messagesDrawerState.conversationData.merge({archived: !archived});
          setMessagesDrawerData({
            messagingAccountId: null
          });
          drawerMethods.closeDrawer();
        }
      });
  }

  return (
    <span className={`archive-message ${classes || defaultCSSClasses}`}
       onClick={archiveMessage}>
      { archived ? 'Unarchive' : 'Archive' }
    </span>
  )
}

export default ArchiveMessageButton;
