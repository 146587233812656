import { useState, useRef, useEffect } from "react";
import JSONHeaders from "utilities/JSONHeaders";
import { commentAPIPath } from "utilities/UrlHelpers";
import AvatarUsername from "components/common/AvatarUsername";
import TimeSince from "components/common/TimeSince";
import CurrentUserData from "components/common/CurrentUserData";
import CustomMarkdown from "components/common/CustomMarkdown";
import FireballButton from "components/fireballs/FireballButton";
import CommentForm from "./CommentForm";
import { makeStyles } from "@material-ui/core/styles";
import useBoostrapVariables from "utilities/BootstrapVariables";
const cssDefaults = useBoostrapVariables();
import { none } from "@hookstate/core";

const useCommentStyles = makeStyles((theme) => ({
  commentsList: {

  },
  comment: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    borderBottom: "1px #EEE solid",
    "&:first-child": {
      paddingTop: 0
    },
    "&:last-child": {
      borderBottom: "0 none"
    },

    "&.comment-highlight":{
      backgroundColor: "#f0f8fd",
      borderRadius: 6,
      padding: 15,
      width: "100%",
    },
    "& .username-headline": {
      marginBottom: theme.spacing(1)
    },
    "& .actions-row": {
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(1),
      color: cssDefaults.brandLink,
      fontSize: 12,
      "& button": {
        fontSize: 12,
      }
    },
    "& .dot": {
      display: "inline-block",
      margin: theme.spacing(1),
      fontSize: 16
    },
    "& .fa-trash": {
      marginLeft: theme.spacing(1)
    },
    "& .edit-comment-form":{
      display: "flex",
      "& input": {
        flex: 2,
        margin: theme.spacing(1),
        marginLeft: 0
      }
    }
  }
}));

const Comment = (props) => {
  const { comment, comments, disableReplies, refreshComments, preview } = props;
  const { is_admin, messaging_blocked } = CurrentUserData();
  const currentUserId = CurrentUserData().id;
  const { children } = comment;
  const [previewState, setPreviewState] = useState(preview);
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [showEditCommentForm, setShowEditCommentForm] = useState(false);
  const [commentTextState, setCommentText] = useState(comment.text);
  const [deletedState, setDeleted] = useState(false);
  const classes = useCommentStyles();
  const editCommentForm = useRef();

  const toggleCommentForm = () => {
    if(!currentUserId){
      return window.triggerSignupWall('comment-reply');
    }
    setShowCommentForm(!showCommentForm);
  };

  const toggleEditCommentForm = () => {
    setShowEditCommentForm(!showEditCommentForm);
  };

  const handleUpdate = async () => {
    const newValue = editCommentForm.current.value;
    const resp = await Fetch(commentAPIPath(comment.id), {
      ...JSONHeaders,
      method: "PUT",
      body: JSON.stringify({
        text: newValue
      })
    });
    const data = await resp.json();
    const newComments = [...comments];

    refreshComments();
    setCommentText(newValue);
    toggleEditCommentForm();
  };

  const handleDestroy = async () => {
    if(confirm("Are you sure you want to delete this comment?")){
      const resp = await Fetch(commentAPIPath(comment.id), { method: "DELETE" })
      if(resp.ok){
        alertify.success("Your comment has been deleted");
        const newComments = [...comments];
        newComments.forEach((cmt, index, cmts) => {
          if(cmt.id ==  comment.id){
            cmts[index] = none;
          }
        });
        setDeleted(true);
      }else{
        alertify.error("The comment could not be deleted, please try again or contact us if the issue persists");
      }
    }
  };

  const addNewComment = (comment) => {
    refreshComments();
    setPreviewState(false);
    toggleCommentForm();
  };

  const getParentId = (comment) => {
    if(disableReplies){ return null; }

    return(
      comment.parent_comment_id ? comment.parent_comment_id : comment.id
    )
  };

  if(deletedState){ return null }

  return (
    <div className={`${classes.comment} comment-container`} id={`comment-${comment.id}`}>
      <div className="username-headline">
        <AvatarUsername account={comment.author} extraText={!comment.parent_comment_id ? "commented" : "replied"} />
      </div>
      { showEditCommentForm
        ? <div className="edit-comment-form">
            <textarea className="form-control comment-text" rows="4" type="text" ref={editCommentForm} defaultValue={commentTextState} />
            <button onClick={handleUpdate} className="btn btn-link no-padding">Update</button>
          </div>
        : <CustomMarkdown source={comment.text} />
      }
      <div className="actions-row">
        <FireballButton
          likeableType="Comment"
          likeableId={comment.id}
          liked={comment.liked}
          totalLikes={comment.totalLikes} />
        <span className="dot">•</span>

        {
          (is_admin || currentUserId == comment.author.id) && <>
            <button className="btn btn-link no-padding" onClick={toggleEditCommentForm}>
              { is_admin && "(Admin) "}
              { showEditCommentForm ? "Cancel" : "Edit" }
            </button>
            <span className="dot">•</span>
          </>
        }
        { !messaging_blocked && (<>
          <button className="btn btn-link no-padding" onClick={toggleCommentForm}>Reply</button>
          <span className="dot">•</span>
        </>) }

        <TimeSince date={comment.createdAtTimestamp} live />
        { (is_admin || comment.canDelete) && (
          <button className="fa fa-trash btn btn-link no-padding" onClick={handleDestroy}></button>
        )}
      </div>
      { showCommentForm && (
        <CommentForm
          commentableType={comment.commentable_type}
          commentableId={comment.commentable_id}
          parentCommentId={ getParentId(comment) }
          onCreate={addNewComment}
          onCancel={toggleCommentForm}
          defaultValue={`@${comment.author.username} `} />
      )}

      { children && children.length > 0 && (!preview || !previewState) && (<>
        <CommentsList disableReplies={disableReplies} comments={children} refreshComments={refreshComments} />
      </>)}


      { children && children.length > 0 && (previewState && preview) && (<>
        <button className="collapsed-replies btn btn-link" onClick={()=>{ setPreviewState(false); }}>
          <span className="fas fa-reply rotate-180"></span>
          {children.length} { children.length == 1 ? "reply" : "replies" }
        </button>
      </>)}
    </div>
  );
};

const CommentsList = (props) => {
  const { comments, preview, disableReplies, refreshComments } = props;
  const [previewState, setPreviewState] = useState(preview);
  const commentsDisplay = [];
  const classes = useCommentStyles();

  const commentsCount = () => {
    let count = comments.length || 0;
    comments.forEach((comment) => {
      if(comment.children){
        count += comment.children.length
      }
    });
    return count;
  };

  comments.forEach((comment, index) => {
    if((previewState && preview) && index > 1){
      return;
    }
    commentsDisplay.push(
      <Comment
        preview={previewState}
        refreshComments={refreshComments}
        disableReplies={disableReplies}
        comment={comment}
        key={comment.id}
        comments={comments} />
    )
  });

  return (
    <div className={classes.commentsList}>
      <hr className="no-bottom-margin" />
      {commentsDisplay}
      { preview && previewState && comments.length > 2 && (
        <button
          className="btn btn-link"
          onClick={ () => { setPreviewState(false); } }>
          Show all {commentsCount()} comments
        </button>
      )}
    </div>
  )
};

export default CommentsList;
