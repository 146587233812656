const Rules = () => {
  return(
    <>
      <p>
        Please follow our
        &nbsp;
        <a href="#rules-of-etiquette" data-toggle="collapse">
          rules of etiquette for contacting influencers
        </a>
        &nbsp;
        on our network.
      </p>
      <div className="collapse" id="rules-of-etiquette">
        <div className="well">
          <ul>
            <li>Please be polite and courteous in communication with influencers</li>
            <li>Sending inquiries from corporate or recognizable email addresses (e.g. john@adidas.com) will almost always receive a quicker response</li>
            <li>Being as specific as possible about how you’re looking to collaborate and in what timeframe will likely receive quicker responses</li>
            <li>Please allow 72 hours before following up with influencers and refrain from contacting them more than twice</li>
            <li>influence.co has a zero-tolerance policy for harassment, threats, inappropriate propositions, or illegal activities and will swiftly remove any members found engaging in such activity</li>
            <li>influence.co reserves the right to review any inquiry on our system before it is delivered to the influencer</li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Rules
