import { Component } from "react";
import { assetUrl } from "utilities/RailsHelpers";
import { influencerProfilePath } from "utilities/UrlHelpers";

class AccountBadge extends Component {
    generateBadge(accountType) {
        const badgeText = accountType ? accountType.toUpperCase() : '';
        return badgeText
            ? <span className="label label-default profile-type-badge">
                {badgeText}
              </span>
            : null
    }

    render() {
        return (
            <div className={'account-badge'}>
                {this.generateBadge(this.props.accountType)}
            </div>
        )
    }
}

class ProSelectBadge extends Component {
    generateBadge(isPro, isSelect) {
        if (isSelect) {
          return <span className="label label-warning">SELECT</span>
        } else if (isPro) {
          return <span className="label label-info profile-type-badge">PRO</span>
        } else {
          return ""
        }
    }

    render() {
      return (
            <div className={'pro-select-badge'}>
              {this.generateBadge(this.props.isPro, this.props.isSelect)}
            </div>
        )
    }
}

class CircularProfilePicture extends Component {
  render() {
    const { src, alt, username } = this.props;
    return (
      <div>
        {src && (
          <a href={influencerProfilePath(username)}>
            <img
              alt={alt || ''}
              className="img-circle"
              src={src}
            />
          </a>
        )}
      </div>
    )
  }
}


class NameAndReach extends Component {
    generateReach(reach) {
        return (
            <div className={'community-reach cell'}>
                {reach} Reach
            </div>
        )
    };

    render() {
        const {
            accountType,
            icon,
            username,
            reach,
        } = this.props;
        return (
            <div className={'infco-username'}>
                <div className={'username-icon cell'}>
                    <img src={icon}/>
                </div>
                <div className={'username-text cell'}>
                    {username}
                </div>
                {
                    (accountType === "influencer" || accountType === "business")
                        ? this.generateReach(reach)
                        : null
                }
            </div>
        );
    }
}

const Headline = (props) => {
  const { headline, editHeadlineUrl } = props;

  const showHeadline = () => {
    return(
      <p className="influencer-headline no-wrap-ellipsis">{headline}</p>
    );
  };

  const editHeadline = () => {
    return (
      <p className="no-wrap-ellipsis">
      <em>
      <a href={editHeadlineUrl}>Add a headline <span className="fa fa-edit"></span></a>
      </em>
      </p>
    );
  }

  if (headline && headline.length > 0) {
    return showHeadline();
  } else if (editHeadlineUrl) {
    return editHeadline();
  } else {
    return null;
  }
}

class CategoryTagsRibbons extends Component {
  generateTagRibbons(categories) {
    let output = [];
    categories.forEach((category, index) => {
      output.push((
        <span
          className="category-ribbon"
          key={index}
        >
          <i className="glyphicon glyphicon-bookmark"></i>
          <span className="content" dangerouslySetInnerHTML={{__html: category}}></span>
        </span>
      ))
    });
    return output;
  };

  render() {
    const {categories} = this.props;
    return (
      <div className={'category-ribbons'}>
        {categories
          ? this.generateTagRibbons(categories)
          : null
        }
      </div>
    );
  }
}

export default class ProfileCard extends Component {
  render() {
    const {
      account_type,
      avatarUrl,
      categories,
      reach,
      headline,
      editHeadlineUrl,
      fullName,
      username,
      isPro,
      isSelect,
    } = this.props;
    return (
      <div className={'profile-card row'}>
        <AccountBadge accountType={account_type}/>
        <ProSelectBadge isPro={isPro} isSelect={isSelect}/>
        <CircularProfilePicture
          username={username}
          alt={'Your Profile Image'}
          src={avatarUrl}
        />
        <div className={'full-name'}>{fullName}</div>
        <Headline editHeadlineUrl={editHeadlineUrl} headline={headline}/>
        <NameAndReach
          accountType={account_type}
          icon={ assetUrl('influence-icon.svg') }
          reach={reach}
          username={username}
        />
        <CategoryTagsRibbons categories={categories}/>
      </div>
    )
  }
}
