import { useEffect } from "react";
import LoadingSpinner from "components/common/Loading";
import CircularImage from "components/common/CircularImage/index";
import { spacesAPIPath, spacePath, spacesPath } from "utilities/UrlHelpers";
import UseSpaceState from "data/SpaceState";
import CurrentUserData from "components/common/CurrentUserData";
import useSharedSpacesStyles from "./SharedStyles";
import allSpacesIconUrl from "../../../assets/images/influence-icon.svg";
import { makeStyles } from "@material-ui/core/styles";
import useBoostrapVariables from "utilities/BootstrapVariables";
const cssDefaults = useBoostrapVariables();

const localStyles = makeStyles((theme) => ({
  spacesListMobileOpener: {
    cursor: "pointer",
    "& a": {
      color: cssDefaults.textColor
    },
    "& .white-content-block": {
      padding: theme.spacing(2)
    },
    "& .white-content-block:first-child": {
      marginTop: theme.spacing(2)
    }
  }
}));

const MobileSpacesListOpener = (listProps) => {
  const classes = useSharedSpacesStyles();
  const localClasses = localStyles();

  const spaceState = UseSpaceState();
  const { spaces, currentSpaceId, questions } = spaceState.get();

  if(!spaces || spaces.length == 0){ return null; }

  const getSpaces = (opts) => {
    const { memberOnly } = opts;
    const spaceRows = [];
    Object.keys(spaces).forEach((space_id) => {
      const space = spaces[space_id];
      if(memberOnly && space.member){
        spaceRows.push(space);
      }else if(!memberOnly && !space.member){
        spaceRows.push(space)
      }
    });
    return spaceRows;
  };

  const setActivePanel = (panel, spaceListPanel) => {
    spaceState.merge({
      currentMobilePanel: panel,
      mobileSpaceListPanel: spaceListPanel
    });
    if(panel == "left"){
      $("body").css("overflow", "hidden");
    }else{
      $("body").css("overflow", "auto");
    }
  };

  const mySpacesRows = getSpaces({memberOnly: true});
  const otherSpacesRows = getSpaces({memberOnly: false});

  return (
    <div className={`${localClasses.spacesListMobileOpener} row`}>
      <div className="col-sm-12 visible-sm visible-xs">
        { mySpacesRows.length > 0 && (
          <div className="white-content-block" onClick={()=>{setActivePanel("left", "mine");}}>
            <div className="pull-right">
              <span className="fa fa-angle-down" />
            </div>
            <a href="#">My Spaces</a>
          </div>
        ) }

        { otherSpacesRows.length > 0 && (
          <div className="white-content-block" onClick={()=>{setActivePanel("left", "all");}}>
            <div className="pull-right">
              <span className="fa fa-angle-down" />
            </div>
            <a href="#">All Spaces</a>
          </div>
        ) }
      </div>
    </div>
  )
}

export default MobileSpacesListOpener;
