import SearchHeader from './SearchHeader'
import SearchResult from './SearchResult'

const SearchResultCollection = (props) => {
  const { bucket } = props

  const renderSearchResultCollection = () => {
    return (
      <div className='search-result-collection'>
        <SearchHeader category={bucket.category} />
        {bucket.entries && bucket.entries.map(entry => (
          <SearchResult
            key={entry.label}
            rtype={entry.rtype}
            label={entry.label}
            description={entry.description}
            link={entry.link}
            icon={entry.icon}
            data1={entry.data1}
            data2={entry.data2}
            righticon={entry.righticon}
          />
        ))}

        {
        // bucket.category == "Influencers" ? <p className="text-center"><a href={`/influencer_searches/keyword?${bucket.entries[0].term}`}>See all</a></p> : null
        }
      </div>
    )
  }

  return renderSearchResultCollection()
}

export default SearchResultCollection
