import { useState, useEffect } from "react";
import { messagingBlockAccountPath } from "utilities/UrlHelpers";
import CurrentUserData from "components/common/CurrentUserData";
import UseMessagesState from 'data/MessagesState';
import { conversationBlockSettingAPIPath } from 'UrlHelpers';

const BlockButton = (props) => {
  const { classes = "btn btn-default" } = props;
  const messagesState = UseMessagesState();
  const { messagingAccountId, conversationData } = messagesState.get();
  if(!messagingAccountId || !conversationData){ return null; }
  const { blockedSetting } = conversationData;

  const blockedClick = () => {
    if(!blockedSetting){
      alertify.confirm("Are you sure you want to block this user?", blockClickCallback);
    }else{
      blockClickCallback(true);
    }
  };

  const blockClickCallback = async (confirmSuccess) => {
    if(!confirmSuccess){ return null; }

    let formData = new FormData();
    formData.append('block', !blockedSetting);
    const resp = await Fetch(conversationBlockSettingAPIPath(messagingAccountId), {
      method: "PUT",
      body: formData
    });
    if(resp.ok){
      const convData = await resp.json();
      messagesState.conversationData.merge({
        blocked: convData.blocked,
        blockedSetting: convData.blockedSetting
      });
    }else{
      const errorText = await response.text();
      alertify.error("There was a problem sending your block request, please try again or contact us to let us know about this error.");
      ReportError({message: `Block conversation API error: ${errorText}`});
    }
  };

  const buttonText = () => {
    return blockedSetting ? "Unblock" : "Block";
  };

  return (
    <button type="button" onClick={blockedClick} className={classes}>
      {buttonText()}
    </button>
  );
}

export default BlockButton;
