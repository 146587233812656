import Async from 'react-async'
import { assetUrl } from 'utilities/RailsHelpers'
import { recentSearchesPath, searchPath } from 'utilities/UrlHelpers'
import LoadingSpinner from "components/common/Loading";
import EventTracking from "components/common/EventTracking";

import SearchResultCollection from './SearchResultCollection'

const SearchBox = (props) => {
  const { onClose, term, allowSearch, accountId } = props

  const fetchSearch = async () => {
    if (!allowSearch || !term || term.length === 0) { return {} }
    EventTracking('Global Search - Searched', {term: term})
    const response = await Fetch(searchPath(encodeURIComponent(term)))
    if (!response.ok) throw new Error(response.statusText)
    return response.json()
  }

  const handleClose = () => (
    onClose()
  )

  const SearchPlaceholder = () => (
    <div className='empty-search-wrapper'>
      <p className="search-pill-wrapper">
        <a href="/influencer_searches/advanced" onClick={() => { EventTracking('Global Search - Drilldown', {type: 'influencers'})}} className="search-pill">Influencers</a>
        &nbsp;
        &nbsp;
        <a href="/influencer_searches/businesses" onClick={() => { EventTracking('Global Search - Drilldown', {type: 'businesses'})}}className="search-pill">Businesses</a>
        &nbsp;
        &nbsp;
        <a href="/go/campaigns" onClick={() => { EventTracking('Global Search - Drilldown', {type: 'campaigns'})}}className="search-pill">Campaigns</a>
      </p>

      <div className='empty-search-img'>
        <img src={assetUrl('search-empty-state.svg')} />
      </div>
      <div className='empty-search-instructions'>
        Try searching for influencers, brands, campaigns, and more!
      </div>
    </div>
  )

  const renderSearchBox = () => {
    return (
      <>
        <div className='search-content'>
          <Async promiseFn={fetchSearch}>
            <Async.Pending><LoadingSpinner text='Searching...'/></Async.Pending>
            <Async.Rejected>{error => `An error occurred: ${error.message}`}</Async.Rejected>
            <Async.Fulfilled>
              {data => {
                return (
                  <>
                    {(data && data.results && data.results.some( bucket => (
                      Array.isArray(bucket.entries) && bucket.entries.length > 0)))
                      ? data.results.filter(bucket => (
                        Array.isArray(bucket.entries) && bucket.entries.length))
                        .map(bucket => (
                          <SearchResultCollection key={bucket.category} bucket={bucket} />
                        ))
                      : <SearchPlaceholder />}
                  </>
                )
              }}
            </Async.Fulfilled>
          </Async>
        </div>
        <div className='search-backdrop' onClick={handleClose}/>
      </>
    )
  }

  return renderSearchBox()
}

export default SearchBox
