import CircularImage from 'CircularImage/index';
import AutoLink from 'AutoLink';
import UseMessagesState from 'data/MessagesState';
import AttachmentItem from './attachments/AttachmentItem';
import CurrentUserData from "CurrentUserData";

const MessageItem = (props) => {
  const messagesDrawerState = UseMessagesState();
  const { currentMessageId } = props
  const {
    setMessagesDrawerData,
    conversationData,
    currentMatch,
    searchWithinConversation
  } = messagesDrawerState.get();

  const {
    messages
  } = conversationData

  const message = messages[currentMessageId];
  const { id, authorId, authorProfileUrl, authorAvatar, authorUsername, timestamp } = message;
  let messageBody = message.body;

  const searchRegex = searchWithinConversation ? (new RegExp(searchWithinConversation, 'gi')) : null;

  function getMessagesMatchCount() {
    let count = 0;
    messages.map(msg => {
      if(msg.match(searchRegex)){
        count = count + msg.match(searchRegex).length;
      }
    });
  }

  function getCurrentMessageCountStart() {
    let count = 0;
    let stopCount = false;
    messages.map(msg => {
      if(msg.id == id){
        stopCount = true;
      }

      if(!stopCount && msg.body.match(searchRegex)){
        count = count + msg.body.match(searchRegex).length;
      }
    });
    return count;
  }

  function userIsAuthor (messageAuthor) {
    return CurrentUserData().id == messageAuthor;
  }

  if(searchWithinConversation){
    let replaceMatchCount = getCurrentMessageCountStart();
    messageBody = messageBody.replace(searchRegex, (match, offset) => {
      replaceMatchCount = replaceMatchCount + 1;
      let currentClass = (replaceMatchCount == currentMatch) ? 'current' : '';
      return `<span class='match ${currentClass} match-${replaceMatchCount}'>${match}</span>`
    })
  }

  return (
    <li className={`message-${ id }`}
        className={ userIsAuthor(authorId) ? "author" : ''}>
      <div className="msg-row">
        <a target="blank" href={ authorProfileUrl }>
          <CircularImage src={ authorAvatar } />
        </a>
        <div>
          <AutoLink text={messageBody} />
          <AttachmentItem messageId={ currentMessageId } />
        </div>
      </div>
      <p className="msg-timestamps text-muted">
        <small>
          { timestamp }
        </small>
      </p>
    </li>
  )
}

export default MessageItem;
