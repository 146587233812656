import AutoLink from 'components/common/AutoLink';

const NoteContent = (props) => {
  const {
    content
  } = props

  return (
    <>
      {
        content
          ? <AutoLink text={content} />
          : <div className="no-content">No notes yet, add some now!</div>
      }
    </>
  )
}

export default NoteContent;
