import { makeStyles } from "@material-ui/core/styles";
import TopPost from "components/posts/top_posts/TopPost";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    "& h2": {
      padding: 0,
      margin: 0,
      marginBottom: theme.spacing(2),
      fontSize: 20,
      fontWeight: 500,
    },
  },
}));

export default function TopPostsDesktop(props) {
  const { posts } = props;
  const classes = useStyles();

  if (!posts || posts.length === 0) { return null; }

  const RenderPosts = () => {
    const renderedPosts = [];

    posts.forEach((post) => {
      renderedPosts.push(
        <TopPost post={post} key={post.id} hidePreview hideDescription hideFullName />,
      );
    });
    return renderedPosts;
  };

  return (
    <div className={`top-posts-desktop ${classes.root}`}>
      <h2>Top Posts</h2>
      <RenderPosts />
    </div>
  );
}
