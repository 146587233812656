import { makeStyles } from "@material-ui/core/styles";
import useBoostrapVariables from "utilities/BootstrapVariables";
const cssDefaults = useBoostrapVariables();

const useSharedNavStyles = makeStyles((theme) => ({
  infoPanel: {
    backgroundColor: cssDefaults.brandPrimary,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    padding: 20,
    color: "white",
  },
  lightbulb: {
    display: "flex",
    marginTop: "15px",
    alignItems: "center",
    textAlign: "center",
    position: "relative",
    minHeight: 38 + 15,
    "& i":{
      fontSize: 28,
      width: 28,
      height: 28,
      position: "absolute",
      bottom: "15px",
      right: `calc(50% - ${38/2}px)`
    }
  },
  stats: {
    flex: 2,
    fontSize: 18,
    lineHeight: "24px",
    textAlign: "center",
    "& p": {
      margin: "5px 0"
    }
  },
  ctaButton: {
    backgroundColor: "transparent",
    border: "1px white solid",
    color: "white",
    whiteSpace: "normal",
    fontSize: 16,
    marginTop: 10,
    "&:hover, &:active": {
      color: "white"
    }
  },
  campaignStats: {
    display: "flex",
    flexDirection: "column",
    flex: 2,
    fontSize: 16,
    lineHeight: "18px",
    textAlign: "center",
    "& p": {
      fontSize: 14,
      margin: "5px 0",
      flex: 2,
      "& strong": {
        fontSize: 18,
        display: "block",
        marginBottom: "7px"
      }
    },
    "& h2": {
      fontSize: 18,
      marginTop: 0,
      fontWeight: "bold"
    },
    "& .biz-stats-icon": {
       marginRight: 5
    },
    "& a":{
      color: "white"
    }
  }
}));

export default useSharedNavStyles;
