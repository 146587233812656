import { withStyles, useTheme } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogContentText, Typography, IconButton, useMediaQuery } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    "& h5": {
      fontWeight: "bold",
      fontSize: 26
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5" align="center">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const dialogStyles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    "& label": {
      display: "block",
      fontSize: 14,
      fontWeight: 500
    },
    "& input": {
      fontSize: 14,
      padding: theme.spacing(1),
      lineHeight: "1.5em",
      border: "1px #B7B7B7 solid",
      borderRadius: "4px",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "100%",
      "&[type=checkbox]": {
        width: 15
      },
      "&.error": {
        borderColor: theme.palette.error.border,
        outlineColor: theme.palette.error.border
      },
      "&.checkbox": {
        width: "20px",
        marginLeft: "0px",
        float: "left",
      },
    },
    "& textarea": {
      fontSize: 14,
      padding: theme.spacing(1),
      lineHeight: "1.5em",
      border: "1px #B7B7B7 solid",
      borderRadius: "4px",
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      width: "100%",
      "&.error": {
        borderColor: theme.palette.error.border,
        outlineColor: theme.palette.error.border
      },
    }
  },
  title: {
    paddingRight: 40
  },
  content: {
    paddingTop: 0,
    paddingBottom: theme.spacing(2)
  }, 
  paper: {
    
  }
});

// CustomDialog expects 1, 2, or 3 "children".  Examples:
// 1 child, content only: <CustomDialog><div>Only content</div></CustomDialog>
// 2 children, title + content: <CustomDialog><>Dialog title</><div>Some content</div></CustomDialog>
// 3 children, title + description + content: <CustomDialog><>Dialog title</><>Do something in this modal</><div>Some content</div></CustomDialog>
const CustomDialog = withStyles(dialogStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let title = null;
  let description = null;
  let content = [];

  const { title: titleClass, content: contentClass, ...otherClasses} = classes;

  if(children.length == 1){
    content = children[0];
  }else if(children.length == 2){
    title = children[0];
    content = children[1];
  }else if(children.length == 3) {
    title = children[0];
    description = children[1];
    content = children[2];
  } else {
    content = children
  }

  return (
    <Dialog
        classes={otherClasses}
        fullScreen={isSmallScreen}
        {...other}>
      <DialogTitle className={titleClass} onClose={onClose}>{title}</DialogTitle>
      <DialogContent className={contentClass}>
        { description && (
          <DialogContentText>{description}</DialogContentText>
        )}
        {content}
      </DialogContent>
    </Dialog>
  );
});

export default CustomDialog;
