import { Component } from "react";
import NoteAttachmentList from 'components/notes/AttachmentList';
import UploadingFile from 'components/notes/UploadingFile';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import FileListToggle from 'components/notes/FileListToggle';
import { notePath, noteAttachmentsPath, noteAttachmentPath } from 'utilities/UrlHelpers';
import { assetUrl } from "utilities/RailsHelpers";

export default class NoteAttachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: props.note,
      attachmentData: null,
      showUploader: false,
      showFileTray: props.showFileTray || false,
      percent: null
    };

    this.localAttachment = null;
  }

  onPreprocess = async (file, next) => {
    const response = await Fetch(noteAttachmentsPath(this.state.note.id),
      {
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({name: file.name, content_type: file.type, size: file.size}),
        method: 'POST'
      });
    this.localAttachment = await response.json();
    this.setState({attachmentData: this.localAttachment});
    next(file);
  };

  attachmentSigningUrl = (file, callback) => {
    callback(this.localAttachment);
  }

  onError = () => {
    Fetch(noteAttachmentPath(this.state.note.id, this.state.attachmentData.id), {method: 'DELETE'});
    this.setState({attachmentData: null});
  };

  onProgress = (percent, message, file) => {
    this.setState({percent: percent});
  };

  onUploadFinish = async () => {
    const response = await Fetch(notePath(this.localAttachment.attachableId), {method: 'GET'});
    const data = await response.json();
    this.setState({
      note: data,
      percent: null,
      attachmentData: null,
      showFileTray: true,
      showUploader: false
    });
    this.localAttachment = null;
  };

  toggleUploader = () => {
    if (window._inf_user_data.is_pro) {
      this.setState({showUploader: !this.state.showUploader});
    } else {
      $.ajax({method: 'POST', url: '/go/paywall/upload_attachment'})
    }
  };

  toggleFileTray = () => {
    if(this.state.showFileTray && this.state.showUploader){
      this.toggleUploader();
    }
    this.setState({showFileTray: !this.state.showFileTray});
  };

  updateNote = (note) => {
    this.setState({note: note});
  }

  render() {

    return (
      <div
        key={`attachments-${this.state.note.id}`}
        className={`note-attachments note-attachments-${this.state.note.id}`}
        >
        <h4>
          Files
          <a href="#" className="btn btn-default" onClick={this.toggleUploader}>Upload</a>
          <FileListToggle state={this.state} toggleCallback={this.toggleFileTray} />
        </h4>

        {
          this.state.showUploader ?
            <DropzoneS3Uploader
              upload={{preprocess: this.onPreprocess, getSignedUrl: this.attachmentSigningUrl, uploadRequestHeaders: { 'x-amz-acl': 'private' }}}
              className="notes-file-upload-dropzone"
              multiple={false}
              s3Url="https://marmont-development.s3.amazonaws.com"
              onProgress={this.onProgress}
              style={
                {
                  border: '1px dashed rgb(153,153,153)',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  overflow: 'hidden',
                  width: '100%',
                  marginBottom: '20px'
                }
              }
              passChildrenProps={false}
              onFinish={this.onUploadFinish}>
              <div className="text-center">
                <p><img src={ assetUrl("files-empty.svg") }/></p>
                <p><span className="btn btn-info">Upload Files</span></p>
                <p className="text-center">or drag them here</p>
              </div>
            </DropzoneS3Uploader>
            :
            ""
        }

        {
          this.state.percent ?
            <UploadingFile percent={this.state.percent} />
            :
            ""
        }

        {
          this.state.showFileTray ?
            <NoteAttachmentList note={this.state.note} setNoteState={this.updateNote} />
            :
            ""
        }

      </div>
    )
  }
}
