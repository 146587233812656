import './circularImage.scss';

const CircularImage = (props) => {
  const { alt, classes, imgClasses, src } = props;

  return (
    <span className={`circular-image ${classes || ''}`}>
      {src
        ? (
          <img
            alt={alt || ''}
            className={`img-circle ${imgClasses || ''}`}
            src={src}
          />
        )
        : ''}
    </span>
  );
};
export default CircularImage;
