import useBoostrapVariables from "utilities/BootstrapVariables";
import iconDiscover from "../../../assets/images/tape-nav/community-discover-icon.svg";
import iconFeed from "../../../assets/images/tape-nav/community-feed-icon.svg";
import iconReferral from "../../../assets/images/tape-nav/community-referral-icon.svg";
import iconUniversity from "../../../assets/images/tape-nav/commerce-university-icon.svg";
import iconCampaign from "../../../assets/images/tape-nav/commerce-campaign-icon.svg";
import iconDashboard from "../../../assets/images/tape-nav/commerce-dashboard-icon.svg";
import iconImh from "../../../assets/images/tape-nav/content-imh-icon.svg";
import iconResource from "../../../assets/images/tape-nav/content-resource-center-icon.svg";
import iconContent from "../../../assets/images/tape-nav/content-icon.svg";
import iconCommunity from "../../../assets/images/tape-nav/community-icon.svg";
import iconCommerce from "../../../assets/images/tape-nav/commerce-icon.svg";
import iconInfluencerProfileEdit from "../../../assets/images/tape-nav/commerce-influencer-profile.svg";
import iconBusinessSearch from "../../../assets/images/tape-nav/commerce-business-find-icon.svg";
import iconBusinessUpgrade from "../../../assets/images/tape-nav/commerce-business-upgrade-account-icon.svg";
import iconBusinessHelp from "../../../assets/images/tape-nav/commerce-business-help-icon.svg";
import iconPersonalSwitch from "../../../assets/images/tape-nav/personal-switch-account-icon.svg";
import iconInfluencerBrandedContent from "../../../assets/images/tape-nav/commerce-influence-branded-content.svg";

// Starting to move these into the webpack pipeline exclusively so they don't get double compiled in sprockets and webpack
import iconAnswersTapeNav from "components/spaces/iconAnswersTapeNav.svg";

import {
  feedPath,
  discoveryPath,
  referPath,
  editProfilePath,
  influencerCampaignApplicationsPath,
  campaignsPath,
  upgradeLandingPageUrl,
  businessCampaignsPath,
  createCampaignPath,
  advancedInfluencerSearchesPath,
  goProPath,
  usernameInfluencerSearchesPath,
  spacesPath,
} from "utilities/UrlHelpers";
import CurrentUserData from "components/common/CurrentUserData";
import { PostsAndEngagementsStats, CampaignsCreatedStats, LiveCampaignStats } from "components/nav/BusinessPanels";
import { CampaignsCountPanel, CampaignStatsPanel } from "components/nav/InfluencerPanels";

const BasicPanels = {
  InfluencerFeed: {
    title: "Home",
    url: feedPath(),
    image: iconFeed,
    description: "Updates from the community and those you follow",
    eventName: "influencer_feed"
  },
  BusinessFeed: {
    title: "Home",
    url: feedPath(),
    image: iconFeed,
    description: "Updates from the community and those you work with",
    eventName: "business_feed"
  },
  PersonalFeed: {
    title: "Home",
    url: feedPath(),
    image: iconFeed,
    description: "Updates from the community and those you follow",
    eventName: "personal_feed"
  },
  InfluencerDiscovery: {
    title: "Discovery",
    badge: "New!",
    url: discoveryPath(),
    image: iconDiscover,
    description: "Explore Branded Content by other influencers & see where you rank",
    eventName: "influencer_explore_branded_content"
  },
  BusinessDiscovery: {
    title: "Discovery",
    badge: "New!",
    url: discoveryPath(),
    image: iconDiscover,
    description: "Explore Branded Content by influencers",
    eventName: "business_explore_branded_content"
  },
  PersonalDiscovery: {
    title: "Discovery",
    badge: "New!",
    url: discoveryPath(),
    image: iconDiscover,
    description: "Explore Branded Content by influencers",
    eventName: "personal_explore_branded_content"
  },
  Referral: {
    title: "Invite Friends",
    url: referPath(),
    image: iconReferral,
    description: "Refer ten friends and get a free PRO account",
    eventName: "invite"
  },
  UpdateInfluencerProfile: {
    title: "Update Profile",
    url: editProfilePath(),
    image: iconInfluencerProfileEdit,
    description: "Profiles serve as a resume, keep it up to date",
    eventName: "update_influencer_profile"
  },
  CommerceUpdateInfluencerProfile: {
    title: "Add Branded Content",
    url: `${editProfilePath()}#edit_highlighted-post`,
    image: iconInfluencerBrandedContent,
    description: "Showcase your past work to attract businesses",
    eventName: "update_influencer_profile_commerce"
  },
  UpdateBusinessProfile: {
    title: "Update Business Page",
    url: editProfilePath(),
    image: iconReferral,
    description: "Keep your profile up to date to attract top influencers",
    eventName: "update_business_profile"
  },
  UpdateIndividualProfile: {
    title: "Update Profile",
    url: editProfilePath(),
    image: iconReferral,
    description: "Keep your profile up to date for better engagement",
    eventName: "update_individual_profile"

  },
  UpdatePersonalProfile: {
    title: "Create Lead List",
    url: '/lists',
    image: iconReferral,
    description: "Group and track members of the community",
    eventName: "update_personal_profile"
  },
  InfluencerApplications: {
    title: "Pending Applications",
    url: influencerCampaignApplicationsPath(),
    image: iconCampaign,
    description: "Manage your current campaign applications",
    eventName: "influencer_applications"
  },
  InfluencerCampaigns: {
    title: "Businesses to Work With",
    url: campaignsPath(),
    image: iconDashboard,
    description: "Find businesses to proactively reach out to",
    eventName: "influencer_campaigns"
  },
  CreateCampaign: {
    title: "Create a Campaign",
    url: createCampaignPath(),
    image: iconCampaign,
    description: "Enable influencers to easily find and apply to work with you",
    eventName: "create_campaign"
  },
  BusinessCampaigns: {
    title: "My Campaigns",
    url: businessCampaignsPath(),
    image: iconCampaign,
    description: "Manage your campaigns and create new ones",
    eventName: "business_campaigns"
  },
  BusinessCustomerSupport: {
    title: "Need Help?",
    action: () => { Beacon('open') },
    image: iconBusinessHelp,
    description: "Get in touch with a member of our Customer Success team",
    eventName: "customer_support"
  },
  BusinessProUpgrade: {
    title: "Upgrade Your Account",
    url: () => { return upgradeLandingPageUrl(CurrentUserData())},
    image: iconBusinessUpgrade,
    description: "Schedule a demo and unlock the additional functionality of PRO",
    eventName: "pro_upgrade"
  },
  BusinessSearch: {
    title: "Find Influencers",
    url: advancedInfluencerSearchesPath(),
    image: iconBusinessSearch,
    description: "Find influencers to proactively reach out to",
    eventName: "business_search"
  },
  PersonalCampaigns: {
    title: "Browse Campaigns",
    url: campaignsPath(),
    image: iconDashboard,
    description: "Businesses looking to collaborate with influencers",
    eventName: "personal_campaigns"
  },
  PersonalSearch: {
    title: "Find Influencers",
    url: advancedInfluencerSearchesPath(),
    image: iconBusinessSearch,
    description: "Find influencers to proactively reach out to",
    eventName: "personal_search"
  },
  PersonalExplore: {
    title: "Explore Account Types",
    action: () => { $('#switch-account-types').modal('show') },
    image: iconPersonalSwitch,
    description: "Unlock additional functionality by changing account type",
    eventName: "personal_explore"
  },
  InfluencerFindUser: {
    title: "Find a User",
    url: usernameInfluencerSearchesPath(),
    image: iconDiscover,
    description: "Find influencers and businesses by username",
    eventName: "influencer_username_search"
  },
  BusinessFindUser: {
    title: "Find a User",
    url: usernameInfluencerSearchesPath(),
    image: iconDiscover,
    description: "Find influencers and businesses by username",
    eventName: "business_username_search"
  },
  PersonalFindUser: {
    title: "Find a User",
    url: usernameInfluencerSearchesPath(),
    image: iconDiscover,
    description: "Find influencers and businesses by username",
    eventName: "personal_username_search"
  },
  InfluencerQuestions: {
    title: "Answers",
    badge: "New!",
    url: spacesPath(),
    image: iconAnswersTapeNav,
    description: "Ask questions, get answers",
    eventName: "influencer_answers"
  },
  BusinessQuestions: {
    title: "Answers",
    badge: "New!",
    url: spacesPath(),
    image: iconAnswersTapeNav,
    description: "Ask questions, get answers",
    eventName: "business_answers"
  },
  PersonalQuestions: {
    title: "Answers",
    badge: "New!",
    url: spacesPath(),
    image: iconAnswersTapeNav,
    description: "Ask questions, get answers",
    eventName: "personal_answers"
  }
}

const Panels = () => {
  const currentUser = CurrentUserData();
  const { campaigns_applied, campaigns_created, campaigns_live, is_pro } = currentUser;
  return {
    Community: {
      influencer: {
        overview: "A community of influencers and creators learning together",
        image: iconCommunity,
        panels: [
          BasicPanels.InfluencerFeed,
          BasicPanels.InfluencerQuestions,
          BasicPanels.InfluencerDiscovery,
          BasicPanels.UpdateInfluencerProfile,
        ]
      },
      business: {
        overview: "Leverage an active community of influencers for your business",
        image: iconCommunity,
        panels: [
          BasicPanels.BusinessFeed,
          BasicPanels.BusinessQuestions,
          BasicPanels.BusinessDiscovery,
          BasicPanels.UpdateBusinessProfile
        ]
      },
      personal: {
        overview: "Learn from a professional community of influencers",
        image: iconCommunity,
        panels: [
          BasicPanels.PersonalFeed,
          BasicPanels.PersonalQuestions,
          BasicPanels.PersonalDiscovery,
          BasicPanels.UpdateIndividualProfile
        ]
      },
    },
    Commerce: {
      influencer: {
        overview: "Opportunities to collaborate with the businesses you love",
        image: iconCommerce,
        panels: [
          BasicPanels.InfluencerApplications,
          BasicPanels.InfluencerCampaigns,
          BasicPanels.CommerceUpdateInfluencerProfile,
          campaigns_applied
            ? <CampaignStatsPanel key="campaign-counts-panel" />
            : <CampaignsCountPanel key="campaign-stats-panel" />
        ]
      },
      business: {
        overview: "Opportunities to collaborate with influencers",
        image: iconCommerce,
        panels: [
          campaigns_created ? BasicPanels.BusinessCampaigns : BasicPanels.CreateCampaign,
          BasicPanels.BusinessSearch,
          is_pro ? BasicPanels.BusinessCustomerSupport : BasicPanels.BusinessProUpgrade,
          campaigns_live
            ? <LiveCampaignStats key="live-campaign-stats" />
            : <CampaignsCreatedStats key="campaigns-created-stats"/>
        ]
      },
      personal: {
        overview: "View influencers and businesses looking to collaborate",
        image: iconCommerce,
        panels: [
          BasicPanels.PersonalCampaigns,
          BasicPanels.PersonalSearch,
          BasicPanels.UpdatePersonalProfile,
          BasicPanels.PersonalExplore
        ]
      },
    }
  };
};

export default Panels;
