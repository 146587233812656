import { Component } from 'react';
import SuggestedFollow from 'components/follows/SuggestedFollow';
import LoadingSpinner from 'components/common/Loading';
import { suggestedFollowsPath, allSuggestedFollowsPath } from 'utilities/UrlHelpers';

import 'css/SuggestedFollows';

export default class SuggestedFollowsSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestedFollows: [],
      loaded: false,
    };
  }

  componentDidMount = () => {
    this.fetchInitialData();
  };

  fetchInitialData = async () => {
    const response = await Fetch(`${suggestedFollowsPath()}?limit=5`);
    if (response.ok) {
      const data = await response.json();
      this.setState({
        suggestedFollows: data,
        loaded: true,
      });
    }
  }

  renderSuggestedFollows = () => {
    const { state } = this;
    const { suggestedFollows } = state;
    const followsOutput = [];
    suggestedFollows.forEach((account) => {
      followsOutput.push(
        <SuggestedFollow account={account} key={`suggested-follow-${account.id}`} baseFollowClass='link-button'  />,
      );
    });
    return followsOutput;
  }

  render() {
    const { state, renderSuggestedFollows } = this;
    const { loaded } = state;

    return (
      <div id="suggested-follows">
        <div className="pull-right">
          <a href={allSuggestedFollowsPath()}>View All</a>
        </div>
        <h2>Suggestions</h2>
        {!loaded
          ? <LoadingSpinner text="Loading suggestions..." classes="text-center" />
          : renderSuggestedFollows()}
      </div>
    );
  }
}
