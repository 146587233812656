import { useState } from "react";
import { TextField } from "@material-ui/core";
import useBoostrapVariables from "utilities/BootstrapVariables";
import { makeStyles } from "@material-ui/core/styles";
const cssDefaults = useBoostrapVariables();

const useInputStyles = makeStyles((theme) => ({
  limitedInput: {
    "& input, & textarea": {
      fontFamily: "'TT Norms', Helvetica, Arial, sans-serif !important",
      "&::placeholder": {
        fontFamily: "'TT Norms', Helvetica, Arial, sans-serif !important",
        color: "#3B3C4B !important",
        opacity: "50% !important"
      }
    }
  }
}));

export default function LimitedInput(props) {

  const {
    name,
    value,
    limit,
    placeholder,
    required,
    classes,
    type,
    multiline,
    rows,
    skipReplaceNewline,
    onChange,
    onKeyDown,
    autoFocus,
    inputRef,
    ref,
    variant
  } = props;

  const [stateValue, setStateValue] = useState(value || '');
  const customCss = useInputStyles()

  const handleChange = (event) => {
    let targetValue = event.target.value;
    if(!skipReplaceNewline){
      targetValue = targetValue.replace("\n", " ");
    }
    setStateValue(targetValue);

    if(onChange){
      onChange(event);
    }
  };

  const handleKeyDown = (event) => {
    if(onKeyDown){
      onKeyDown(event);
    }
  }
  
  return (
    <TextField
      inputProps={ {maxLength: limit} }
      multiline={multiline}
      rows={rows}
      name={name}
      value={stateValue}
      placeholder={placeholder}
      required={required}
      helperText={`${stateValue.length}/${limit}`}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      margin="normal"
      className={`${customCss.limitedInput} ${classes || ''}`}
      autoFocus={autoFocus}
      inputRef={inputRef}
      ref={ref}
      variant={variant || "standard"}
    />
  );
}
