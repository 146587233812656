import { useState } from "react";
import UseMessagesState from 'data/MessagesState';
import DownloadButton from './DownloadButton';
import PreviewButton from './PreviewButton';
import { makeStyles } from "@material-ui/core/styles";
import useBoostrapVariables from "utilities/BootstrapVariables";
import customBreakpointsTheme from "utilities/CustomBreakpointsTheme";
import Collapse from '@material-ui/core/Collapse';
const cssDefaults = useBoostrapVariables();

const useStyles = makeStyles((theme) => ({
  sidebarAttachment: {
    display: 'flex',
    padding: `${theme.spacing(1)}px 0`,
    '& a:last-child': {
      marginLeft: theme.spacing(1)
    },
  }
}));


export default function AttachmentsSidebar(props){
  const messagesState = UseMessagesState();
  const { conversationData } = messagesState.get();
  const { messages } = conversationData;
  const [showDetails, setShowDetails] = useState(false)
  const classes = useStyles();

  const messageAttachments = [];
  messages.forEach((message) => {
    const { attachmentFilename, attachmentDownloadUrl, attachmentUrl } = message;
    if(message.attachmentFilename){
      messageAttachments.push(
        <div className={classes.sidebarAttachment} key={`sidebar-attachment-${message.id}`}>
          <div className="flex-grow">
            <DownloadButton message={message} classes={""} buttonText={attachmentFilename} />
          </div>
          <PreviewButton message={message} classes={"fa fa-eye"} buttonText="" />
          <DownloadButton message={message} classes={"fa fa-download"} buttonText="" />
        </div>
      );
    }
  });

  if(messageAttachments.length == 0){
    return null;
  }

  return (
    <div className={classes.sidebarAttachments}>
      <div className="collapsible-widget-header" onClick={ ()=>{ setShowDetails(!showDetails); } }>
        <h2>Shared Attachments</h2>
        <span className={`fa ${showDetails ? "fa-chevron-down" : "fa-chevron-left"}`}></span>
      </div>
      <Collapse in={showDetails}>
        { messageAttachments }
      </Collapse>
    </div>
  );
};
