import { assetUrl } from 'utilities/RailsHelpers'
import ReactMarkdown from 'react-markdown'
import EventTracking from "components/common/EventTracking";

const SearchResult = (props) => {
  const { rtype, label, link, icon, data1, data2, description, righticon } = props

  const StockIcon = () => {
    switch (rtype) {
      case 'location':
        return (
          <i className="fas fa-map-marker-alt"></i>
        )
      case 'category':
        return (
          <i className="fas fa-bookmark"></i>
        )
      case 'post':
        return (
          <i style={{ width: '1px', marginRight: '15px' }}>&nbsp;</i>
        )
      default:
        return (
          <i className="fas fa-search"></i>
        )
    }
  }

  const IconStyle = () => {
    switch (rtype) {
      case 'influencer':
        return 'img-circle search-result-left-avatar'
      case 'business':
        return 'img-circle search-result-left-avatar'
      default:
        return 'search-result-left-avatar'
    }
  }

  const trackClick = () => {
    EventTracking('Global Search - Clicked', {link: link, type: rtype})
  }

  const renderSearchResult = () => {
    return (
      <div className='search-result'>
        <a onClick={trackClick} href={link}>
          {icon
            ? <img src={icon} className={IconStyle()}/>
            : StockIcon()}
          <div className='search-result-left'>
            <div className='inner-top'>
              <ReactMarkdown
                source={label}
                escapeHtml={false}
              />
            </div>
            <div className='inner-bottom'>{description}</div>
            <div className='search-clear-all'/>
          </div>
          <div className='search-result-right'>
            {righticon
              ? <img src={righticon} className='img-circle search-result-right-icon'/>
              : ''}
            <div style={{ float: 'left' }}>
              <div className='inner-top'>{data1}</div>
              <div className='inner-bottom'>
                <ReactMarkdown
                  source={data2}
                  escapeHtml={false}
                />
              </div>
            </div>
            <div className='search-clear-all'/>
          </div>
          <div className='search-clear-all'/>
        </a>
      </div>
    )
  }

  return renderSearchResult()
}

export default SearchResult
