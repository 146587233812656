import { useState } from "react";
import CircularImage from "components/common/CircularImage/index";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import CurrentUserData from "components/common/CurrentUserData";
import CSRFHeaders from "components/common/CSRFHeaders";
import { workflowStages } from "utilities/RailsHelpers";
import { workflowMemberPath, bulkCampaignApplicationActionPath, goProPath } from "utilities/UrlHelpers";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  select: {
    padding: 5,
    marginTop: 3,
    verticalAlign: "middle",
    width: "100%",
    "& .MuiSelect-root": {
      paddingTop: 8,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 20,
      fontSize: 12,
      lineHeight: "12px"
    },
    "& .MuiSelect-icon": {
      top: "calc(50% - 8px)"
    }
  },
  menuItem: {
    fontSize: 14
  },
  timestamp: {
    color: "#aaaaaa",
    fontSize: 12
  },
  formControl: {
    width: "100%",
    marginBottom: 5
  }
}));

const CampaignApplicationCard = (props) => {
  const classes = useStyles();

  const {
    app
  } = props;

  const defaultWorkflowState = app.workflow ? app.workflow.stage : 'accepted';
  const [workflowValue, setWorkflowValue] = useState(defaultWorkflowState);

  if(app.length == 0){
    return '';
  }

  const formattedDate = (seconds_timestamp) => {
    const monthOptions = { month: 'short', day: '2-digit', year: 'numeric'}
    return new Intl.DateTimeFormat('en-US', monthOptions).format(new Date(seconds_timestamp * 1000));
  }

  const workflowOptions = (currentStage) => {
    const options = [];

    Object.keys(workflowStages).forEach((key) => {
      options.push(
        <MenuItem value={key} key={key} className={classes.menuItem}>{workflowStages[key]}</MenuItem>
      );
    })
    return options;
  }

  const handleWorkflowChange = async (evt) => {
    const newWorkflowValue = evt.target.value;
    const path = workflowMemberPath(
      CurrentUserData().id,
      app.campaignId,
      app.workflow.id
    );
    const response = await Fetch(path, {
      method: "PUT",
      headers: CSRFHeaders(),
      body: JSON.stringify({workflow_member: {stage: evt.target.value }})
    });
    if(response.status == 403){
      alertify.error(`You need to be <a href="${goProPath()}">Pro</a> to do that.`);
    }else{
      alertify.success(`Workflow stage updated to ${evt.target.value}`)
      setWorkflowValue(evt.target.value)
    }
  };

  const handleApplicationChange = async (evt) => {
    const newStatus = evt.target.value;
    const path = bulkCampaignApplicationActionPath(
      CurrentUserData().id,
      app.campaignId
    );
    const response = await Fetch(`${path}?skip_msg=1`, {
      method: "POST",
      headers: CSRFHeaders(),
      body: JSON.stringify({
        include_msg: "off",
        applicationIds: [app.id],
        status: newStatus
      })
    });

    if(response.ok){
      alertify.success(
          `Application ${
            evt.target.value == "considering"
              ? "moved to considering"
              : `${evt.target.value}`
          }. Make sure to send a message to @${app.account.username} letting them know.`);
    }
  };

  const renderWorkflowData = () => {
    if(!app.workflow){ return ''; }

    return (
      <>
        <div className="row">
          <div className="col-xs-6">
            <p><strong>Workflow Stage</strong></p>
          </div>
          <div className="col-xs-6">
            <p className="text-right">
              <a href={app.workflow.url}>View Workflow</a>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                value={workflowValue}
                onChange={handleWorkflowChange}
                className={classes.select}>
                { workflowOptions(app.workflow.stage) }
              </Select>
            </FormControl>
            <p className={classes.timestamp}>
            Last updated: { formattedDate(app.workflow.stage_changed_at) }
            </p>
          </div>
        </div>
      </>
    )
  }

  return(
    <div className="campaign-app-sidebar">
      <div className="header">
        <a href={app.campaignDetails.url} target="_BLANK">
          <CircularImage src={app.campaignDetails.imageUrl} />
          {app.campaignDetails.title}
        </a>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <p><strong>Application Status</strong></p>
        </div>
        <div className="col-xs-6">
          <p className="text-right">
          <a href={app.url}>View Application</a>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              defaultValue={app.status}
              onChange={handleApplicationChange}
              className={classes.select}>
              <MenuItem value="pending" className={classes.menuItem} disabled>Pending</MenuItem>
              <MenuItem value="considering" className={classes.menuItem}>Considering</MenuItem>
              <MenuItem value="accepted" className={classes.menuItem}>Accepted</MenuItem>
              <MenuItem value="declined" className={classes.menuItem}>Declined</MenuItem>
            </Select>
          </FormControl>
          <p className={classes.timestamp}>
          Applied on: { formattedDate(app.addedAt) }
          </p>
          {
            CurrentUserData().has_goaffpro &&    
              <div className="alert alert-warning">
                <p>
                  You have enabled the Goaffpro Affilliate Integration. If you would like to assign
                  this applicant an affiliate link or coupon code, please click 'View Application'
                  link above and manage their application from there.
                </p>
              </div>
          }
        </div>
      </div>
      { renderWorkflowData() }
    </div>
  )
}

export default CampaignApplicationCard;
