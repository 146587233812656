import { Component } from 'react';
import LoadingSpinner from 'components/common/Loading';
import SuggestedFollow from 'components/follows/SuggestedFollow';
import { suggestedFollowsPath } from 'utilities/UrlHelpers';

import 'css/SuggestedFollows';

export default class SuggestedFollowsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestedFollows: props.suggestedFollows,
      seed: props.seed,
      limit: props.limit,
      skip: 0,
      showLoadMore: true,
      loadingMore: false,
      type: '',
    };
  }

  setData = (data) => {
    this.setState(data);
  };

  fetchMoreData = async (newSkip) => {
    const { state, setData } = this;
    const {
      suggestedFollows, limit, seed, skip, type
    } = state;
    setData({loadingMore: true})
    const response = await Fetch(`${suggestedFollowsPath()}?limit=${limit}&seed=${seed}&skip=${newSkip}&type=${type}`);
    if (response.ok) {
      const data = await response.json();
      setData({
        suggestedFollows: suggestedFollows.concat(data),
        skip: newSkip,
        loadingMore: false,
      });
      setData({
        showLoadMore: data && !(data.length < limit),
      });
    }
  }

  setTypeFilter = (e) => {
    this.setState({
      type: e.target.value,
      skip: 0,
      suggestedFollows: [],
      loadingMore: true
    }, () => {
      this.fetchMoreData(0);
    });
  }

  SuggestedFollows = () => {
    const { state } = this;
    const { suggestedFollows } = state;
    const followsOutput = [];
    suggestedFollows.forEach((account) => {
      if(account == null){
        return;
      }
      followsOutput.push(
        <div className="col-xs-12" key={`suggested-follow-${account.id}`}>
          <div className="white-content-block">
            <SuggestedFollow
              account={account}
              baseFollowClass="btn btn-lg"
              followClass="btn-info"
              followedClass="btn-default"
            />
          </div>
        </div>,
      );
    });
    return followsOutput;
  }

  LoadMore = () => {
    const { state } = this;
    const { loadingMore, showLoadMore, skip, limit } = state;

    if (loadingMore) {
      return (
        <LoadingSpinner text="Loading suggestions..." />
      );
    } if (!showLoadMore) {
      return '';
    }

    return (
      <button type="button" onClick={() => { this.fetchMoreData(skip + limit); }} className="btn btn-default">Load More...</button>
    );
  }


  render() {
    const { SuggestedFollows, LoadMore, state, setTypeFilter } = this;
    const { type } = state;
    return (
      <div id="suggested-follows-page">
        <div className="row">
          <div className="col-xs-6">
            <h2>Suggestions</h2>
          </div>
          <div className="col-xs-6">
            <div className="form-inline">
              <p className="text-right">
                <select name="type" className="form-control" value={type} onChange={(e)=>{setTypeFilter(e)}}>
                  <option value="">All</option>
                  <option value="influencer">Influencers</option>
                  <option value="business">Businesses</option>
                </select>
              </p>
            </div>
          </div>
        </div>
        <div id="suggested-follows-container">
          <SuggestedFollows key="suggested-follows" />
        </div>
        <div className="text-center">
          <LoadMore key="load-more" />
        </div>
      </div>
    );
  }
}
