import { useState } from "react";
import useBoostrapVariables from "utilities/BootstrapVariables";
import { makeStyles } from "@material-ui/core/styles";
const cssDefaults = useBoostrapVariables();

const useInputStyles = makeStyles((theme) => ({
  limitedInput: {
    "& input, & textarea": {
      display: "block",
      border: "1px #ccc solid",
      borderRadius: "4px",
      padding: "15px",
      fontFamily: "'TT Norms', Helvetica, Arial, sans-serif",
      "&:hover": {
        border: "1px #000 solid"
      },
      "&::placeholder": {
        color: "#3B3C4B !important",
        opacity: "50% !important"
      }
    }
  }
}));
export default function BasicLimitedInput(props) {

  const {
    name,
    baseClass,
    value,
    limit,
    placeholder,
    required,
    classes,
    type,
    multiline,
    rows,
    skipReplaceNewline,
    onChange,
    onKeyDown,
    autoFocus,
    inputRef
  } = props;
  const baseClasses = useInputStyles();
  const [stateValue, setStateValue] = useState(value || '');
  const [focusState, setAutofocusState] = useState(autoFocus || false);
  const handleChange = (event) => {
    let targetValue = event.target.value;
    if(!skipReplaceNewline){
      targetValue = targetValue.replace("\n", "");
    }
    setStateValue(targetValue);

    if(onChange){
      onChange(event);
    }
  };

  const handleKeyDown = (event) => {
    if(onKeyDown){
      onKeyDown(event);
    }

    event.target.style.height = "auto";
    event.target.style.height = `${event.target.scrollHeight + 8}px`;
  }
  const setFocused = () => {
    setAutofocusState(true);
  };

  const unsetFocused = () => {
    setAutofocusState(false);
  };

  return (
    <div className={`${baseClasses.limitedInput} ${baseClass}`}>
      {
        multiline
          ? <textarea
              type={type || "text"}
              maxLength={limit}
              rows={rows}
              name={name}
              value={stateValue}
              placeholder={placeholder}
              required={required}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              className={`${classes || ''}`}
              autoFocus={focusState}
              onFocus={setFocused}
              onBlur={unsetFocused}
              ref={inputRef}
              value={stateValue} />
          : <input
              type={type || "text"}
              maxLength={limit}
              rows={rows}
              name={name}
              value={stateValue}
              placeholder={placeholder}
              required={required}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              className={`${classes || ''}`}
              autoFocus={focusState}
              onFocus={setFocused}
              onBlur={unsetFocused}
              ref={inputRef}
            />
      }
      <p className="MuiFormHelperText-root MuiFormHelperText-contained">{stateValue.length}/{limit}</p>
    </div>
  );
}
