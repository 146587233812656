import Rollbar from "rollbar";

import { rollbarKey, currentEnvironment, rollbarEnabled, rollbarCatchAll } from "utilities/RailsHelpers";
import CurrentUserData from "components/common/CurrentUserData";


const initRollbar = ()=>{
  const userData = CurrentUserData();
  if(!userData){
    setTimeout(initRollbar, 10);
    return;
  }

  window.rollbarjs = new Rollbar({
    accessToken: rollbarKey,
    enabled: rollbarEnabled,
    captureUncaught: rollbarCatchAll,
    captureUnhandledRejections: rollbarCatchAll,
    payload: {
      environment: currentEnvironment,
      person: {
        id: userData.id,
        username: userData.username
      }
    }
  });
};

export default function ReportError(props) {
  const rollbar = window.rollbarjs;
  const { message } = props;
  rollbar.error(message);
  return null;
}

if(!window.rollbarjs){
  setTimeout(initRollbar, 1);
}
