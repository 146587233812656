import React from 'react';
import { ThemeProvider, withTheme } from '@material-ui/styles';

// We can create a different one from chat if we want eventually, but this should suffice for now
import { baseTheme } from "themes/Chat";

function WithThemeComponent(props) {
  return props.children || null;
}

const ThemedComponent = withTheme(WithThemeComponent);

export default function WithDefaultTheme(props) {
  const { theme = baseTheme } = props;

  return (
    <ThemeProvider theme={theme}>
      <ThemedComponent children={props.children} />
    </ThemeProvider>
  );
}