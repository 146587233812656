import { assetUrl } from "utilities/RailsHelpers";

const LoadingSpinner = (props) => {
  const {
    hideText, classes, text
  } = props;

  let loadingText = 'Loading...'
  if(hideText){
    loadingText = '';
  }else if(text != '' && typeof text != 'undefined'){
    loadingText = text;
  }

  return (
    <div className={`react-loading-spinner ${classes || ''}`}>
      {loadingText} <img src={assetUrl('loading.gif')} />
    </div>
  )
}

export default LoadingSpinner;
