import { useEffect } from "react";
import ReactDOM from "react-dom";
import Drawer from "components/Drawer";
import UseMessagesState from 'data/MessagesState';
import getConsumer from "channels/consumer";
import CurrentUserData from "CurrentUserData";
import routes from "UrlHelpers";
const consumer = getConsumer();

const SendMessageButton = (props) => {
  const {
    messagingAccountId,
    buttonText,
    context,
    drawerTitle,
    iconClasses,
    wrapperClasses,
    newMessages,
    newMessagesCount,
    showCount,
    showBadge,
    openDrawer = true,
    onClick = ()=>{}
  } = props;

  const messagesDrawerState = UseMessagesState();
  const newMessagesCountStateValue = messagesDrawerState.get().newMessagesCount;

  useEffect(()=>{
    let sub;
    //only update messages count in header / not attached to a specific conversation for now
    if(!messagingAccountId){
      if(newMessagesCount > 0){
        messagesDrawerState.merge({
          newMessagesCount: newMessagesCount
        })
      }
      sub = consumer.subscriptions.create("NotificationChannel", {
        received(notification_counts) {
          if('newMessagesCount' in notification_counts){
            messagesDrawerState.merge({
              newMessagesCount: notification_counts.newMessagesCount
            });
          }
        }
      });
    }

    return () => { sub?.unsubscribe(); };
  },[]);

  const defaultCSSClasses = 'btn btn-default ga-actions-contact truncate-text btn-contact';

  const allWrapperClasses = () => {
    return `open-message ${wrapperClasses || defaultCSSClasses}`;
  }

  const openSingleMessageDrawer = () => {
    let elm = document.createElement("div");
    const onClose = function(){
      elm.remove();
    };

    const drawerContentProps = {
      messagingAccountId: messagingAccountId,
      context: context,
      newMessages: newMessagesCountStateValue > 0
    }

    document.getElementsByTagName('body')[0].appendChild(elm);
    ReactDOM.render(
      <Drawer
        drawerTitle={drawerTitle}
        drawerHeaderContainerClass='hidden-xs'
        drawerContentType="MessageDrawer"
        drawerContentProps={drawerContentProps}
        onClose={onClose}
        idScope="messages" />
      ,
      elm
    );
  }

  const checkIfCurrentUser = () => {
    let currentUserId = window._inf_user_data.id;
    return currentUserId == messagingAccountId
  }

  const UnreadCount = () => {
    if(!newMessagesCountStateValue || !showBadge){
      return null;
    }

    return(
      <div className="unread-count">
        {newMessagesCountStateValue > 9 ? "9+" : newMessagesCountStateValue}
      </div>
    );
  }

  const handleClick = () => {
    if(openDrawer){ 
      openSingleMessageDrawer(); 
    } else {
      window.open(routes.influencerConversationsPath(CurrentUserData().id), "_blank")
    }
    if(onClick){
      onClick();
    }
  };

  const renderButton = () => {
    if(checkIfCurrentUser()){
      return '';
    }else{
      return (
        <span className={allWrapperClasses()} onClick={handleClick}>
          <span className={iconClasses}>
            <UnreadCount />
          </span>
          {buttonText || 'Message'}
        </span>
      )
    }
  }

  return renderButton()
}

export default SendMessageButton;
