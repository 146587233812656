import { createState, useState } from "@hookstate/core";

const FollowState = createState({
  follows: {}
});

export default function UseFollowState(accessDirectly) {
  if (accessDirectly) {
    return FollowState;
  }

  return useState(FollowState);
}
