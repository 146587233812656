import ReactDOM from "react-dom";

//  Components that use the autoloader
import MessageDrawerOpener from 'components/messages/MessageDrawerOpener';
import SendMessageButton from 'components/messages/SendMessageButton';
import NoteOpener from 'components/NoteOpener';
import NoteSidebarWidget from 'components/notes/SidebarWidget';
import ProfileActivitySummary from 'components/ProfileActivitySummary';
import ActivitySummaryCard from 'components/ActivitySummaryCard';
import ProfileCard from 'components/ProfileCard';
import LimitedInput from 'components/common/LimitedInput';
import SuggestedFollowsSidebar from "containers/SuggestedFollowsSidebar";
import SuggestedFollowsPage from "containers/SuggestedFollowsPage";
import SearchInput from 'components/search/SearchInput.jsx';
import AvatarBar from 'components/AvatarBar';
import NotificationsDrawer from 'containers/NotificationsDrawer';
import ShowNotificationsButton from 'components/notifications/ShowNotificationsButton';
import InfluenceAd from 'components/common/InfluenceAd';
import TimeSince from 'components/common/TimeSince';
import CampaignsAvailable from 'components/CampaignsAvailable';
import TrendingFollowsPage from 'containers/TrendingFollowsPage';
import TopPosts from "components/posts/TopPosts";
import SpacesPage from "containers/SpacesPage";
import QuestionPage from "containers/QuestionPage";
import CampaignApplicationButton from "components/campaign_applications/CampaignApplicationButton";
import CampaignInvitationButton from "components/campaign_invitations/CampaignInvitationButton";
import AskQuestionOpener from "components/spaces/AskQuestionOpener";
import QuestionListItem from "components/spaces/QuestionListItem";
import CommentableList from "components/comments/CommentableList"
import AvatarUsername from "components/common/AvatarUsername";
import PhoneNumberVerification from "components/PhoneNumberVerification";
import ChatOpener from "chat/ChatOpener";
import { ProfileDropdownMenuOpener } from "accounts/ProfileDropdownMenu";
import FollowButton from "FollowButton";

import WithDefaultTheme from "themes/WithDefaultTheme";

const Mappings = {
  CampaignApplicationButton,
  CampaignInvitationButton,
  MessageDrawerOpener,
  SendMessageButton,
  NoteOpener,
  NoteSidebarWidget,
  ActivitySummaryCard,
  ProfileActivitySummary,
  LimitedInput,
  SuggestedFollowsSidebar,
  SuggestedFollowsPage,
  ProfileCard,
  SearchInput,
  AvatarBar,
  ShowNotificationsButton,
  NotificationsDrawer,
  InfluenceAd,
  CampaignsAvailable,
  TrendingFollowsPage,
  TopPosts,
  SpacesPage,
  AskQuestionOpener,
  TimeSince,
  QuestionListItem,
  QuestionPage,
  CommentableList,
  AvatarUsername,
  ChatOpener,
  PhoneNumberVerification,
  ProfileDropdownMenuOpener,
  FollowButton,
};

$(()=>{
  $('body').on('reactLoad', function(){
    $('.ReactComponent').each(function(){
      const jqueryNode = $(this);
      const node = jqueryNode[0];
      //No need to JSON.parse with $.data
      const props = jqueryNode.data('react-props');
      const componentName = jqueryNode.data('react-component');
      const Component = Mappings[componentName];

      if(Component){
        jqueryNode.data('initialized', true);
        ReactDOM.render(<WithDefaultTheme><Component {...props} /></WithDefaultTheme>,node);
      }else{
        console.log(`Need to add a mapping for ${componentName}`);
      }
    });
  });

  $(document).bind("ajaxComplete", function(){
    setTimeout(()=>{
      $('body').trigger('reactLoad');
    }, 10);
   });


   $('body').trigger('reactLoad');
});
