import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import UseTapeNavState from "data/TapeNavState";
import TapePanel from "components/nav/TapePanel";
import useBoostrapVariables from "utilities/BootstrapVariables";
import customBreakpointsTheme from "utilities/CustomBreakpointsTheme";
import EventTracking from "components/common/EventTracking";
import CurrentUserData from "components/common/CurrentUserData";
const cssDefaults = useBoostrapVariables();

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "64px",
    width: "100%",
    backgroundColor: "#fff",
    padding: 15,
    borderTop: "1px solid #e5e5e5",
    boxShadow: "0px 10px 10px #e5e5e5",
    zIndex: cssDefaults.zindexNavbarFixed,
    position: "fixed",
    top: 0,
    [customBreakpointsTheme.breakpoints.down('xs')]: {
      position: "fixed",
      backgroundColor: "#fff",
      width: "100%",
      height: "calc(100% - 63px)",
      padding: 15,
      zIndex: cssDefaults.zindexNavbarFixed,
      top: 0,
      left: 0,
      borderTop: "0px",
      boxShadow: "",
      marginTop: 0,
      overflow: "auto"
    }
  },
  mobileClose: {
    float: "left",
    fontSize: "18px",
    marginBottom: 10,
    zIndex: cssDefaults.zindexNavbarFixed,
    color: cssDefaults.textColor
  }
}));

export default function TapeNav() {
  const navState = UseTapeNavState(false);
  const classes = useStyles();
  const { visible, currentPanel, mobile } = navState.get();

  if (!visible) {
    return null;
  }

  const modalHoverEnter = () => {
    navState.merge({
      hovering: true,
      visible: true,
    });
  };

  const modalHoverLeave = () => {
    const urlParams = new URLSearchParams(window.location.search)
    if(urlParams.get("testingCCC")){ return; }
    navState.merge({
      hovering: false,
    });
    setTimeout(() => {
      if (!navState.get().hovering) {
        $(".tape-nav-opener[data-panel], .mobile-tape-nav-opener[data-panel]").removeClass("active");
        $("body").css("overflow", "auto");
        navState.merge({
          visible: false,
          mobile: false
        });
      }
    }, 200);
  };

  const tapeClose = () => {
    $(".tape-nav-opener[data-panel], .mobile-tape-nav-opener[data-panel]").removeClass("active");
    $("body").css("overflow", "auto");
    navState.merge({
      visible: false,
      mobile: false
    });
  };

  $(".tape-nav-opener[data-panel], .mobile-tape-nav-opener[data-panel]").removeClass("active");
  $(`.tape-nav-opener[data-panel=${currentPanel}], .mobile-tape-nav-opener[data-panel=${currentPanel}]`).addClass("active");


  return (
    <div
      className={`${classes.root}`}
      onMouseEnter={modalHoverEnter}
      onMouseLeave={modalHoverLeave}
    >
      {mobile && (
        <div className={classes.mobileClose} onClick={tapeClose}><i className="fas fa-times"></i></div>
      )}
      <TapePanel currentPanel={currentPanel} tapeClose={tapeClose} />
    </div>
  );
}

$(() => {
  const globalState = UseTapeNavState(true);
  const tapeNavJQElement = $("<div id='tape-nav'></div>");
  const tapeNavElement = tapeNavJQElement[0];
  $("body").append(tapeNavJQElement);
  ReactDOM.render(<TapeNav />, tapeNavElement);

  $("body").on("mouseover", ".tape-nav-opener[data-panel]", function (event) { // eslint-disable-line
    const elm = $(this);
    const targetPanel = elm.data("panel") || "Community";
    globalState.merge({
      visible: true,
      hovering: true,
      currentPanel: targetPanel,
    });
  });
  $("body").on("click", ".tape-nav-opener[data-panel] a", function (event) { // eslint-disable-line
    const elm = $(this);
    const targetPanel = elm.data("panel") || "Community";
    EventTracking(`Tape Nav Opener Click`, {
      ...CurrentUserData(),
      item_name: `${targetPanel.toLowerCase()}_desktop_tape_opener`,
      tape_name: targetPanel
    });
  });

  $("body").on("click", ".mobile-tape-nav-opener[data-panel]", function (event) { // eslint-disable-line
    const elm = $(this);
    const targetPanel = elm.data("panel") || "Community";
    globalState.merge({
      visible: true,
      hovering: true,
      currentPanel: targetPanel,
      mobile: true
    });
    $("body").css("overflow","hidden");
  });

  $("body").on("mouseout", ".tape-nav-opener[data-panel]", function() { // eslint-disable-line
    globalState.merge({
      hovering: false,
    });
    setTimeout(() => {
      if (!globalState.get().hovering) {
        $(".tape-nav-opener[data-panel], .mobile-tape-nav-opener[data-panel]").removeClass("active");
        globalState.merge({
          visible: false,
        });
      }
    }, 200);
  });
});
