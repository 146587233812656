import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import UseTapeNavState from "data/TapeNavState";
import useBoostrapVariables from "utilities/BootstrapVariables";
import {
  campaignsPath,
  influencerCampaignsPublishedPath,
  influencerCampaignStatsPath,
  influencerCampaignApplicationsPath,
  myCampaignsPath
} from "utilities/UrlHelpers";
import useSharedNavStyles from "components/nav/SharedStyles";
import EventTracking from "components/common/EventTracking";
import CurrentUserData from "components/common/CurrentUserData";
const cssDefaults = useBoostrapVariables();

const CampaignsCountPanel = (props) => {
  const classes = useSharedNavStyles();
  const navState = UseTapeNavState(false);
  const { campaignsPublishedCount } = navState.get();
  const setData = (data) => {
    navState.merge({
      campaignsPublishedCount: data
    });
  }

  const getCampaignsPublishedCount = async () => {
    if(campaignsPublishedCount){ return; }
    const response = await Fetch(influencerCampaignsPublishedPath());
    if(response.ok){
      const data = await response.json();
      setData(data);
    }
  };

  const trackClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    EventTracking("Tape Nav Item Click", {
      ...CurrentUserData(),
      item_name: "influencer_campaign_count_panel",
      tape_name: "Commerce",
    });
    setTimeout(()=>{
      window.location = campaignsPath();
    },100);
  };

  useEffect(()=>{
    getCampaignsPublishedCount();
    EventTracking("Tape Nav Item View", {
      ...CurrentUserData(),
      item_name: "influencer_campaign_count_panel",
      tape_name: "Commerce"
    });
  },[]);

  if(!campaignsPublishedCount){ return null; }

  return (
    <div className={`col-xs-12 col-sm-5ths ${classes.infoPanel}`}>
      <div className={classes.lightbulb}><i className="fa fa-lightbulb"></i></div>
      <div className={classes.stats}>
        Businesses published <strong>{campaignsPublishedCount}</strong> campaigns in the last 3 days.
      </div>
      <a href={campaignsPath()} onClick={trackClick} className={`btn btn-block ${classes.ctaButton}`}>
        Browse Campaigns
      </a>
    </div>
  )
};

const CampaignStatsPanel = (props) => {
  const classes = useSharedNavStyles();
  const navState = UseTapeNavState(false);
  const { influencerCampaignStats } = navState.get();
  const setData = (data) => {
    navState.merge({
      influencerCampaignStats: data
    });
  }

  const getCampaignStats = async () => {
    if(influencerCampaignStats){ return; }
    const response = await Fetch(influencerCampaignStatsPath());
    if(response.ok){
      const data = await response.json();
      setData(data);
    }
  };

  const trackClick = (evt) => {
    EventTracking("Tape Nav Item Click", {
      ...CurrentUserData(),
      item_name: "influencer_campaign_stats_panel",
      tape_name: "Commerce",
    });
  };

  useEffect(()=>{
    getCampaignStats();
    EventTracking("Tape Nav Item View", {
      ...CurrentUserData(),
      item_name: "influencer_campaign_stats_panel",
      tape_name: "Commerce"
    });
  },[]);

  if(!influencerCampaignStats){ return null; }
  const { accepted, pending, eligibile } = influencerCampaignStats;

  return (
    <div className={`col-xs-12 col-sm-5ths ${classes.infoPanel} ${classes.campaignStats}`}>
      <p>
        <a onClick={trackClick} href={`${influencerCampaignApplicationsPath('accepted')}`}>
          <strong>{accepted}</strong>
          Accepted Applications
        </a>
      </p>
      <p>
        <a onClick={trackClick} href={`${influencerCampaignApplicationsPath('pending')}`}>
          <strong>{pending}</strong>
          Pending Applications
        </a>
      </p>
      <p>
        <a onClick={trackClick} href={`${myCampaignsPath()}?`}>
          <strong>{eligibile}</strong>
          Eligible Campaigns
        </a>
      </p>
    </div>
  )
};

export {
  CampaignsCountPanel,
  CampaignStatsPanel
}
