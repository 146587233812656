import { useState, useEffect } from "react";
import { followsPath, followPath } from "utilities/UrlHelpers";
import CurrentUserData from "components/common/CurrentUserData";
import { Button } from "@material-ui/core";
import UseFollowState from "FollowState";


const FollowButton = (props) => {
  const [state, setState] = useState({
    source: props.source || "follow",
    onClick: props.onClick,
    baseClass: props.baseClass || "",
    followedClass: props.followedClass || "",
    followClass: props.followClass || "",
    useButtonComponent: props.useButtonComponent || false
  });
  const followState = UseFollowState();
  const followed  = followState.get()?.[props.followedId]?.followed;
  const followedBack  = followState.get()?.[props.followedId]?.followedBack;
  const buttonText  = followState.get()?.[props.followedId]?.buttonText || "Follow";

  const getClass = () => {
    const { baseClass, followedClass, followClass } = state;

    return followed
      ? `${baseClass} ${followedClass}`
      : `${baseClass} ${followClass}`;
  }

  const unfollow = async () => {
    const { source } = state;

    // post data
    const response = await Fetch(followPath(props.followedId, source), { method: "DELETE" });
    // check response data
    if (response.ok) {
      followState[props.followedId].merge({
        followed: false,
        buttonText: followedBack ? "Follow Back" : "Follow"
      });
    }
  }

  const follow = async () => {
    const { source } = state;

    // post data
    const response = await Fetch(followsPath(props.followedId, source), { method: "POST" });

    // check response data
    if (response.ok) {
      followState[props.followedId].merge({
        followed: true,
        buttonText: "Following",
      });
    }
  }

  const getFollow = async () => {
    // post data
    const response = await Fetch(followPath(props.followedId), { method: "GET" });
    // check response data
    if (response.ok) {
      const data = await response.json();
      followState[props.followedId].merge({
        followed: data.following,
        followedBack: data.followedBack,
        buttonText: data.following ? "Following" : ( data.followedBack ? "Follow Back" : "Follow" )
      });
    }
  }

  const followClick = (e) => {
    const { onClick } = state;
    e.preventDefault();
    e.stopPropagation();

    if (!CurrentUserData().id) {
      return window.triggerSignupWall('follow-button');
    }

    if(onClick){
      onClick();
    }

    if (followed) {
      return unfollow();
    }
    return follow();
  }

  const ButtonComponent = () => {
    if(state.useButtonComponent){
      return (
        <Button variant="contained" color={state.followed ? "default" : "primary"} onClick={followClick} className={getClass()}>
          {buttonText}
        </Button>
      )
    } else {
      return (
        <button type="button" onClick={followClick} className={getClass()}>
          {buttonText}
        </button>
      )
    }
  };

  useEffect(() => {
    if (!props.followedId || CurrentUserData().nmoUser || CurrentUserData()?.id == props.followedId) { 
      return; 
    }
    const cachedFollow = followState.get()[props.followedId];
    if (cachedFollow){
      return;
    }else if (typeof props.followed == "undefined" || props.followed === null) {
      // get current following status
      getFollow();
    }else{
      followState[props.followedId].merge({
        followed: props.followed,
        followedBack: props.followedBack,
        buttonText: props.followed ? "Following" : (props.followedBack ? "Follow Back" : "Follow")
      });
    }
  }, [props.followedId]);

  if (!props.followedId || CurrentUserData()?.id == props.followedId || typeof followed == "undefined") { return null; }

  return (<ButtonComponent />);
}

export default FollowButton;
