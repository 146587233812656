import { notesPath } from 'utilities/UrlHelpers';

const NoteForm = (props) => {
  const { notesDrawerData } = props
  const {
    setNotesData,
    editNoteContent,
    selectedNoteId,
    selectedAboutId,
    noteListData
  } = notesDrawerData
  const note = noteListData[selectedNoteId];
  const { content } = note || {};

  const saveNoteClick = () => {
    // Build formData object.
    let formData = new FormData();
    formData.append('note[content]', editNoteContent);

    Fetch(
      notesPath(selectedAboutId),
      {
        method: 'POST',
        body: formData
      }
    ).then(response => response.json())
     .then(data => {
       let newNoteListData = {...noteListData};
       newNoteListData[selectedNoteId] = data
       setNotesData({refreshSelectedData: true, noteListData: newNoteListData, editingNote: false});
     });
  };

  const cancelNoteClick = () => {
    setNotesData({
      editingNote: false,
      editNoteContent: content
    });
  }

  const noteChangeCallback = (e) => {
    setNotesData({ editNoteContent: e.target.value || '' });
  };

  const focusTextArea = (input) => {
    if(!input || (input.dataset && input.dataset.alreadyFocused)){
      return false;
    }
    // Magic to make the cursor start at the end
    const val = input.value;
    input.focus();
    input.value='';
    input.value=val;
    input.dataset.alreadyFocused = true;
    return true;
  };

  return (
    <div className="note-form">
      <textarea name="note-form-textarea"
                onChange={ noteChangeCallback }
                ref={focusTextArea}
                defaultValue={editNoteContent || content || ''}
                autoComplete='off' ></textarea>
      <div className="note-form-buttons">
        <button className="cancel-note btn btn-default" onClick={cancelNoteClick}>Cancel</button>
        <button className="save-note btn btn-info"  onClick={saveNoteClick}>
          Save
        </button>
      </div>
    </div>
  )
}

export default NoteForm;
