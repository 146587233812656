const MimeTypeIcon = (props) => {
  const { contentType } = props;
  let resp = ['fa-file', '000000'];
  
  let mapping = {
    // Media
    'image': ['fa-file-image', '31ac36'],
    'audio': ['fa-file-audio', 'e96e00'],
    'video': ['fa-file-video', 'fe0000'],
    // Documents
    'application/pdf': ['fa-file-pdf', 'de231a'],
    'application/msword': ['fa-file-word', '2a5699'],
    'application/vnd.ms-word': ['fa-file-word', '2a5699'],
    'application/vnd.oasis.opendocument.text': ['fa-file-word', '2a5699'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml': ['fa-file-word', '2a5699'],
    'application/vnd.ms-excel': ['fa-file-excel', '01723a'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml': ['fa-file-excel', '01723a'],
    'application/vnd.oasis.opendocument.spreadsheet': ['fa-file-excel', '01723a'],
    'application/vnd.ms-powerpoint': ['fa-file-powerpoint', 'd04625'],
    'application/vnd.openxmlformats-officedocument.presentationml': ['fa-file-powerpoint', 'd04625'],
    'application/vnd.oasis.opendocument.presentation': ['fa-file-powerpoint', 'd04625'],
    'text/plain': ['fa-file-text', '28a6a5'],
    'text/html': ['fa-file-code', '9942d0'],
    'application/json': ['fa-file-code', '585858'],
    // Archives
    'application/gzip': ['fa-file-archive', '000000'],
    'application/zip': ['fa-file-archive', '000000'],
  };

  Object.keys(mapping).forEach((key) => {
    let pos = contentType.indexOf(key);

    if (pos > -1) {
      resp = mapping[key];
    };
  });

  return <i className={`fas ${resp[0]}`} style={{color: `#${resp[1]}`}}></i>
};

export default MimeTypeIcon;
