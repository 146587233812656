import { makeStyles } from "@material-ui/core/styles";
import useBoostrapVariables from "utilities/BootstrapVariables";
import customBreakpointsTheme from "utilities/CustomBreakpointsTheme";
import "./GlobalSpaceStyles.scss";
const cssDefaults = useBoostrapVariables();

const useSharedSpacesStyles = makeStyles((theme) => ({
  spacesListContainer: {
    "& .mobile-close": {
      position: "absolute",
      top: 16,
      right: 16
    },
    "& .white-content-block": {
      padding: theme.spacing(2),
      "& hr": {
        margin: `${theme.spacing(2)}px 0`
      },
      "& img": {
        width: 26,
        height: 26,
        marginRight: theme.spacing(1)
      },
      "& a": {
        color: cssDefaults.textColor,
        textDecoration: "none",
        fontSize: "14px",
        flex: "2",
        display: "flex",
        alignItems: "center",
        "&.selected": {
          fontWeight: "bold"
        }
      },
      "& .space-list-item": {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1.5),
        "&:last-child": {
          marginBottom: 0
        },
        "& button": {
          float: "right"
        }
      },
      "& .space-unread-count": {
        backgroundColor: cssDefaults.brandSecondary,
        color: "white",
        fontSize: 12,
        fontWeight: 700,
        borderRadius: "30px",
        padding: "3px 8px",
        minWidth: "23px",
        textAlign: "center"
      }
    },
    "& h2": {
      margin: `${theme.spacing(.5)}px 0 ${theme.spacing(2)}px 0`,
      fontSize: 20,
      fontWeight: "700"
    },
    "& .load-more-button": {
      margin: theme.spacing(2)
    },
    [customBreakpointsTheme.breakpoints.down('sm')]: {
      backgroundColor: "white",
      paddingTop: theme.spacing(2),
      "& .white-content-block": {
        boxShadow: "none",
        padding: 0
      }
    }
  },
  askQuestionOpener: {
    cursor: "pointer",
    "&.type-text": {
      fontSize: 14,
      color: cssDefaults.textColor,
      "&.white-content-block": {
        padding: theme.spacing(2),
      },
      "& .opener-text": {
        fontSize: 20,
        fontWeight: "bold"
      },
      "& .avatar-row": {
        display: "flex",
        alignItems: "center",
        paddingBottom: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderBottom: "1px #EEEEEE solid",
        "& .question-text": {
          opacity: "50%"
        },
        "& img": {
          width: 33,
          height: 33,
          marginRight: theme.spacing(1)
        }
      }
    },
    "&.type-button": {

    }
  },
  askQuestionModal: {
    zIndex: cssDefaults.zindexModal,
    "& .modal-body": {
      padding: theme.spacing(2)
    },
    "& h2": {
      margin: `0 0 ${theme.spacing(2)}px`,
      fontSize: 18
    },
    "& .avatar-row": {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(2),
      "& img": {
        width: 33,
        height: 33,
        marginRight: theme.spacing(1)
      }
    },
    "& .space-select":{
      minWidth: 228,
      "& .MuiSelect-select": {
        fontSize: 16,
        paddingBottom: theme.spacing(1)
      },
      "& .MuiSelect-icon": {
        top: `calc(50% - ${theme.spacing(1)}px)`
      }
    },
    "& .title-input-container": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    "& .title-input": {
      width: "100%",
      fontSize: 16,
      resize: "vertical"
    },
    "& .description-input": {
      width: "100%",
      marginTop: 0,
      "&  .MuiInputBase-root": {
        fontSize: 16,
        "& textarea": {
          minHeight: 100,
          resize: "vertical"
        }
      }
    },
    "& .space-instructions": {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      backgroundColor: "#F3F5F6",
      borderRadius: theme.spacing(1)
    },
    "& .dropdown-row": {
      display: "flex",
      alignItems: "center",
      [customBreakpointsTheme.breakpoints.down('sm')]: {
        display: "block",
        "& .MuiFormControl-root": {
          display: "block"
        },
        "& select": {
          width: "100%"
        }
      }
    },
    "& .follow-space": {
      flex: 2,
      textAlign: "right"
    }
  },
  spaceSelect: {
    zIndex: cssDefaults.zindexPopover,
  },
  spaceHeader: {
    marginBottom: theme.spacing(2),
    "& img.cover": {
      width: "100%",
      borderRadius: "8px 8px 0 0"
    },
    "& .img-circle": {
      width: 100,
      height: 100,
      margin: theme.spacing(2)
    },
    "& .space-avatar": {
      display: "flex",
      alignItems: "center",
      marginTop: 0 - theme.spacing(2) - (100/4),
      [customBreakpointsTheme.breakpoints.down('sm')]: {
        display: "block",
        textAlign: "center",
        "& h2": {
          margin: `0 0 ${theme.spacing(2)}px`
        }
      }
    },
    "& p": {
      margin: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
      paddingBottom: theme.spacing(2),
      borderBottom: "1px #eee solid",
      fontSize: 14,
      [customBreakpointsTheme.breakpoints.down('sm')]: {
        textAlign: "center",
        "& .btn-link": {
          marginTop: theme.spacing(2)
        }
      }
    },
    "& .mobile-stats": {
      display: "none",
      alignItems: "center",
      marginBottom: theme.spacing(2),
      "& div:last-child": {
        flex: 2
      },
      [customBreakpointsTheme.breakpoints.down('sm')]: {
        display: "flex",
      }
    },
    "& .action-buttons": {
      display: "flex",
      alignItems: "center",
      padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
      "& button": {
        marginRight: theme.spacing(2),
        [customBreakpointsTheme.breakpoints.down('sm')]: {
          flex: 2,
          "&:nth-child(2)": {
            marginRight: 0
          }
        }
      },
    },
    "& .question-avatars": {
      paddingLeft: "10px",
      marginRight: theme.spacing(1),
      display: "flex",
      "& img": {
        width: 20,
        height: 20,
        margin: "0 0 0 -10px"
      },
    },
    "& .question-counts": {
      marginRight: theme.spacing(2)
    },
    "& .counts": {
      fontSize: 12
    },
    "& .mobile-question-counts": {
      display: "flex",
      alignItems: "center"
    }
  },
  spaceDetail: {
    padding: theme.spacing(2),
    fontSize: 14,
    "& h2":{
      margin: `0 0 ${theme.spacing(2)}px`,
      fontSize: 20,
      fontWeight: "700"
    }
  },
  answersHero: {
    backgroundColor: cssDefaults.brandPrimary,
    borderRadius: 8,
    padding: theme.spacing(2),
    color: "white",
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2)",
    display: "flex",
    alignItems: "center",
    "& img": {
      width: 110,
      height: 83,
      marginRight: theme.spacing(2)
    },
    "& h2": {
      fontSize: 18,
      fontWeight: "bold"
    },
    "& p": {
      fontSize: 14
    },
    "& a": {
      alignSelf: "flex-start",
      height: "100%",
      color: "white",
      cursor: "pointer"
    }
  },
  questionsListFilters: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& a": {
      fontSize: 14,
      fontWeight: "500",
      padding: theme.spacing(1),
      marginRight: theme.spacing(3),
      borderBottom: "2px transparent solid  ",
      color: cssDefaults.textColor,
      "&:hover, &:active, &:focus, &:visited": {
        textDecoration: "none",
        borderBottomColor: cssDefaults.textColor,
      },
      "&.selected, &:active, &:focus": {
        color: "#347F9C",
        borderBottomColor: "#347F9C"
      }
    }
  },
  questionListItem: {
    padding: theme.spacing(2),
    wordBreak: "break-word",
    "&.no-container": {
      padding: 0
    },
    "& h2": {
      fontSize: 20,
      fontWeight: "bold",
      "& a": {
        color: cssDefaults.textColor
      }
    },
    "& .avatar-row": {
      display: "flex",
      alignItems: "center",
      "& img": {
        width: 32,
        height: 32,
        marginRight: theme.spacing(1)
      },
      "& .space-name": {
        flex: 2,
        textAlign: "right",
        "& span": {
          display: "inline",
          padding: `${theme.spacing(.5)}px ${theme.spacing(2)}px`
        }
      },
      "& .space-dropdown": {
        flex: 2,
        textAlign: "right",
        display: "none",
        [customBreakpointsTheme.breakpoints.down('sm')]: {
          display: "block"
        },
        "& button, & span": {
          color: `${cssDefaults.textColor} !important`,
          padding: theme.spacing(.5)
        }
      },
      "&:hover .space-dropdown": {
        display: "block"
      }
    },
    "&.no-container .avatar-row .space-name": {
      marginTop: -6,
      marginRight: theme.spacing(2.5),
      width: "auto"
    },
    "& .actions-row": {
      display: "flex",
      alignItems: "center",
      "& .right-actions": {
        flex: 2,
        textAlign: "right",
        "& .fa-share": {
          marginRight: theme.spacing(1)
        }
      }
    },
    "& .top-vote-avatars": {
      "& img": {
        width: 30,
        height: 30
      }
    },
    "& .fa-trash": {
      marginLeft: theme.spacing(1)
    },
    "& .edit-question-form":{
      "& input": {
        display: "block",
        margin: theme.spacing(1)
      }
    }
  },
  answerForm: {
    border: "1px #EEEEEE solid",
    marginBottom: theme.spacing(2),
    "& .avatar-cancel": {
      padding: theme.spacing(1),
      display: "flex",
      "& .account-username": {
        "& a": {
          color: cssDefaults.textColor,
        }
      },
      "& .cancel-container": {
        flex: 2,
        textAlign: "right",
      },
      "& button": {
        fontWeight: 400,
        fontSize: 14,
        color: cssDefaults.brandLink
      }
    },
    "& hr": {
      margin: 0
    },
    "& .answer": {
      padding: theme.spacing(1),
      "& textarea": {
        width: "100%",
        border: "0px none"
      }
    }
  },
  answerDetail: {
    "& .dot": {
      display: "inline-block",
      margin: theme.spacing(1),
      fontSize: 16
    },
    "& button, & span": {
      fontSize: 12,
      color: cssDefaults.brandLink
    },
    "& .fa-trash": {
      marginLeft: theme.spacing(1)
    },
    "& .editing-input":{
      display: "flex",
      "& input": {
        flex: 2,
        margin: theme.spacing(1),
        marginLeft: 0
      }
    }
  }
}));

export default useSharedSpacesStyles;
