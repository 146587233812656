import { useState, useRef } from 'react';
import LimitedInput from 'components/common/LimitedInput';

const WAIT_INTERVAL_DEFAULT = 1000
const ENTER_KEY = 13

const WaitSearchInput = (props) => {
  const waitInterval = props.waitInterval || WAIT_INTERVAL_DEFAULT;
  const autoFocus = props.autoFocus || false;
  const { onChange, type, className, placeholder, name, limit } = props;
  const [currentState, setCurrentState] = useState({inputValue: props.value, timer: null})
  const waitSearchElement = useRef();
  let { inputValue, timer } = currentState;

  const handleChange = e => {
    const inputElement = getWaitSearchElementInput();
    clearTimeout(timer);
    if(inputElement){
      inputValue = inputElement.value;
      setCurrentState({
        inputValue: inputElement.value,
        timer: setTimeout(triggerOnChange, waitInterval)
      })
    }else{
      setCurrentState({timer: timer})
    }
  }

  const getWaitSearchElementInput = () => {
    if(!waitSearchElement || !waitSearchElement.current){
      return;
    }
    return waitSearchElement.current;
  }

  const handleKeyDown = e => {
    if (e.keyCode === ENTER_KEY) {
      e.preventDefault();
      e.stopPropagation();
      clearTimeout(timer)
      setCurrentState({timer: timer})
      triggerOnChange()
    }
  }

  const triggerOnChange = (eventTarget) => {
    const inputElement = getWaitSearchElementInput();
    if(!inputElement){
      return;
    }
    // Only trigger change if input value matches current value in state.
    if(inputElement && inputElement.value == inputValue){
      onChange(inputValue)
    }
  }

  return (
    limit
    ? <LimitedInput
        classes={className}
        name={name}
        limit={limit}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        autoFocus={autoFocus}
        inputRef={waitSearchElement} />
    : <input
        type={type}
        className={className}
        name={name}
        limit={limit || 1000}
        placeholder={placeholder}
        defaultValue={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        autoFocus={autoFocus}
        ref={waitSearchElement} />
  )
}

export default WaitSearchInput
