import { Component } from 'react';
import LoadingSpinner from 'components/common/Loading';
import NoteSidebarWidget from "components/notes/SidebarWidget";
import AccountSmallCard from 'components/accounts/SmallCard';
import AccountListsCard from 'components/accounts/ListsCard';
import SidebarApplicationsCard from 'components/accounts/SidebarApplicationsCard';
import AttachmentsSidebar from 'components/messages/attachments/AttachmentsSidebar';
import { accountSidebarDataPath } from 'utilities/UrlHelpers';
import InfluenceAd from 'components/common/InfluenceAd';
 
export default class AccountSidebarWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountId: props.accountId,
      showNoteWidget: props.showNoteWidget !== false,
      showNoteButton: props.showNoteButton !== false,
      showMessageButton: props.showMessageButton !== false,
      conversationListData: props.conversationListData || null,
      loading: true,
      sidebarData: {
        account: null,
        note: null,
        campaignApplications: [],
        lists: [],
      },
    };
  }

  componentDidMount = () => {
    this.fetchAccountSidebarData();
  };

  componentDidUpdate = (oldProps) => {
    if (this.props.accountId != oldProps.accountId) {
      this.setState({
        accountId: this.props.accountId,
        loading: true,
      }, () => {
        this.fetchAccountSidebarData();
      });
    }
  };

  dataPath = () => {
    let path = accountSidebarDataPath(this.state.accountId);
    if (this.state.showNoteWidget) {
      path += '?includeNoteData=1';
    }
    return path;
  };

  fetchAccountSidebarData = async () => {
    const response = await Fetch(this.dataPath());
    if (response.ok) {
      const data = await response.json();
      this.setState({
        loading: false,
        sidebarData: data,
      });
    }
  }

  render() {
    const {
      sidebarData,
      showNoteWidget,
      showNoteButton,
      showMessageButton,
      setMessagesDrawerData,
      accountId
    } = this.state;

    const {
      account, note
    } = sidebarData;

    if (this.state.loading || account == null) {
      return(
        <div className="text-center">
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <>
        <AccountSmallCard
          accountData={sidebarData.account}
          followed={sidebarData.followed}
          showNoteWidget={showNoteWidget}
          showNoteButton={showNoteButton}
          showMessageButton={showMessageButton}
        />
        {
          showNoteWidget
            ? <>
                <NoteSidebarWidget noteData={note} aboutId={accountId} />
                <AttachmentsSidebar />
              </>
            : ''
        }
        <SidebarApplicationsCard campaignAppData={this.state.sidebarData.campaignApplications} />
        <AccountListsCard listData={this.state.sidebarData.lists} account={sidebarData.account} />
        <InfluenceAd />
      </>
    );
  }
}
