import { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import UseMessagesState from 'data/MessagesState';
import CurrentUserData from "CurrentUserData";
import AttachmentFileInput from "./AttachmentFileInput";
import { uploadMessageAttachmentURL } from "UrlHelpers";

import { makeStyles } from '@material-ui/core/styles';
import useBoostrapVariables from "utilities/BootstrapVariables";
const cssDefaults = useBoostrapVariables();
const useStyles = makeStyles((theme) => ({
  opener: {
    height: 40,
    width: 40,
    lineHeight: "40px",
    fontSize: 20,
    color: cssDefaults.linkColor,
    cursor: "pointer"
  }
}));

const AttachmentOpener = (props) => {
  const classes = useStyles();
  const messagesDrawerState = UseMessagesState();
  const {
    messagingAccountId,
    conversationData,
    setMessagesDrawerData,
    clickUploadInput
  } = messagesDrawerState.get();

  const handleAttachFileOpen = () => {
    if(clickUploadInput){
      clickUploadInput();
    }
  }

  return (
    <>
      <i className={`fa fa-paperclip ${classes.opener}`}
         onClick={handleAttachFileOpen}></i>
      <AttachmentFileInput />
    </>
  );
}

export default AttachmentOpener;
