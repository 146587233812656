import { useState, useEffect } from "react";
import CurrentUserData from "components/common/CurrentUserData";

import imgCreateCampaign from "../../assets/invitation-create-campaign.svg";
import imgSuccess from "../../assets/invitation-success.svg";
import imgRateLimit from "../../assets/invitation-rate-limit.svg";

const CampaignOptionsList = (props) => {
  let output = [];
  let not_invited = [];
  let already_invited = [];

  output.push(
    <option key="none" value="none" disabled="disabled">Choose a Campaign</option>
  );

  props.campaigns.forEach((campaign) => {
    campaign.is_invited ?
      already_invited.push(
        <option disabled="disabled" key={campaign.id} value={campaign.id}>{campaign.name}</option>
      ) :
      not_invited.push(
        <option
          key={campaign.id}
          value={campaign.id}>
          {campaign.name}
        </option>
      )
  });

  output.push(not_invited)

  if (already_invited.length > 0) {
    output.push(<optgroup key="campaign-options-optgroup" label="Already Invited">{already_invited}</optgroup>)
  }

  return(
    <select
      value={props.selected}
      className="form-control"
      name="campaign_id"
      onChange={props.onChange}>

    {output}

    </select>
  );
};

const StatusLine = (props) => {
  if (props.campaign.can_apply) {
    return(
      <>
        <div className="alert alert-success">
          <p>
          <strong>@{props.username}</strong> is
          eligible for your campaign!
          </p>
        </div>
      </>
    );
  } else {
    return(
      <>
        <div className="alert alert-info">
          <p>
          <strong>@{props.username}</strong> does
          not meet the Applicaton Filters you set for your campaign. You can still
          invite them. If you do, they'll be able to apply despite not
          meeting the criteria.
          </p>
        </div>
      </>
     )
   }
};

const RateLimit = (props) => {
  return(
    <p>
    You have <strong>{props.remaining}</strong> invitations remaining
    this month.
    &nbsp;
    <a href="https://resources.influence.co/article/63-campaign-invitations-and-application-limits" target="_blank">
      Learn more about rate limits.
    </a>
    </p>
  );
};

const Paywall = () => {
  return(
    <>
    <div className="text-center">
      <h2>Rate Limit Reached</h2>
      <p>
      Looks like you hit your rate limit for
      inviting influencers to your campaigns.
      </p>
      <p>
      <img src={imgRateLimit}/>
      </p>
      <p>
      Your rate limit resets on the 1st of each month.
      To invite unlimited amounts of influencers to your
      campaigns, talk to a member of our team about
      becoming a PRO Account!
      </p>
      <p>
        <a href="https://try.influence.co/pages/bpro" target="_blank" className="btn btn-info">
          Upgrade to PRO
        </a>
      </p>
    </div>
    </>
  );
};

const SuccessPane = (props) => {
  return(
    <div className="text-center">
      <h2>Invitation Sent!</h2>

      <p className="lead">
      <strong>@{props.username}</strong> has been invited to your campaign.
      </p>

      <p>
      <img src={imgSuccess} />
      </p>
    </div>
  );
};

const CampaignInvitationForm = (props) => {
  let [state, setState] = useState({
    campaigns: [],
    isSubmitting: false,
    values: {
      account_id: props.invitationAccountId,
      campaign_id: "none",
      invitation_text: ""
    },
    ...props
  });

  const getCampaigns = async () => {
    let resp = await Fetch(`/go/api/campaigns/accounts/${state.invitationAccountId}/invitations`);
    let jsonResp = await resp.json();
    return jsonResp;
  };

  const updateFormState = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setState({...state, values: {...state.values, [name]: value}});
  }

  const submitForm = async (e) => {
    e.preventDefault();
    setState((prev) => { return {...prev, isSubmitting: true} });
    let resp = await Fetch(`/go/api/campaigns/accounts/${state.invitationAccountId}/invitations`, {
      method: 'POST',
      body: JSON.stringify(state.values),
      headers: {'Content-Type': 'application/json'}
    });
    setState((prev) => { return {...prev, isSubmitting: false} });

    let data = await resp.json();
    setState((prev) => { return {...prev, success: true} });
  }

  const loadCampaigns = async () => {
    let campaignResp = await getCampaigns();
    let campaignList = campaignResp.campaigns;
    let campaignMap = {};
    campaignList.forEach((c) => { campaignMap[c.id] = c });

    setState({
      ...state,
      rateLimit: campaignResp.rate_limit,
      campaignMap: campaignMap,
      campaigns: campaignList,
      campaignsLoaded: true,
      values: {...state.values, invitation_text: campaignResp.default_invitation_text}
    });
  };

  useEffect(() => {
    loadCampaigns();
  }, []);

  const selectedCampaign = () => {
    return state.campaignMap[state.values.campaign_id];
  };

  const showRateLimit = () => {
    if (selectedCampaign()) {
      return selectedCampaign().show_rate_limit;
    } else {
      return false;
    }
  }

  // https://www.pluralsight.com/guides/submit-form-react-without-jquery-ajax

  if (state.campaignsLoaded == null) {
    return "";
  }

  if (state.success) {
    return(<SuccessPane username={props.username}/>);
  }

  if (state.campaigns.length == 0) {
    return(
      <>
        <div className="text-center">
          <h2>Invite to Campaign</h2>

          <p className="lead">
          You don’t have any published campaigns! Please create and publish a
          campaign to begin inviting influencers.
          Make sure your start
          date is today to invite influencers immediately - you can't invite
          influencers to future campaigns.
          </p>

          <p>
          <img src={imgCreateCampaign}/>
          </p>
          <br/>
          <p>
          <a href={`/influencers/${CurrentUserData().id}/campaigns/new`} className="btn btn-info">Create a Campaign</a>
          </p>
        </div>
      </>
    )
  } else if (state.rateLimit == 0) {
    return(<Paywall />);
  } else {
    let reqChars = 100;

    return(
      <>
        <h2 className="text-center">Invite to Campaign</h2>
        <p>
        Tell <strong>@{props.username}</strong> why you think they're a
        great fit for your campaign. We’ll send your note to them
        on influence.co and via email.
        </p>

        <p>
        Note: A link to your campaign will automatically be added to this message, no need to include it.
        </p>
        <form onSubmit={submitForm}>
          <p>
          <CampaignOptionsList
            selected={state.values.campaign_id}
            onChange={updateFormState}
            campaigns={state.campaigns}/>
          </p>
          {
            selectedCampaign() ?
              <StatusLine username={props.username} campaign={selectedCampaign()}/>
              :
              ""
          }

          <p>
          <textarea
            placeholder="Write your note here"
            className="form-control"
            rows="6"
            disabled={!selectedCampaign()}
            name="invitation_text"
            onChange={updateFormState}
            value={state.values.invitation_text} />
          </p>
          <span
            className={`text-right text-small help-block ${state.values.invitation_text.length >= reqChars ? "invisible" : ""}`}>
            Please write at least {reqChars} characters.
          </span>

         <div className="row">
            <div className="col-xs-6">
              {
                showRateLimit() ?
                  <RateLimit remaining={state.rateLimit}/>
                  :
                  ""
              }
            </div>

            <div className="col-xs-6 text-right">
              <input
                type="submit"
                disabled={!selectedCampaign() || !state.values.invitation_text || state.isSubmitting}
                onChange={updateFormState}
                value={state.isSubmitting ? "One moment..." : "Send Invitation"}
                className="btn btn-success"/>
            </div>
          </div>
        </form>
      </>
    )
  }
};

export default CampaignInvitationForm;
