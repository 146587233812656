import UseMessagesState from 'data/MessagesState';
import WaitSearchInput from 'components/common/WaitSearchInput';
import {conversationSearchPath} from 'utilities/UrlHelpers';

const SearchBox = (props) => {
  const messagesDrawerState = UseMessagesState();

  const {
    search,
    startNewConversation,
    setMessagesDrawerData
  } = messagesDrawerState.get();

  const searchConversations = async (searchValue) => {
    setMessagesDrawerData(
      {searchFocused: true, search: searchValue, searchData: null, startNewConversation: false},
      () => {
        Fetch(conversationSearchPath(searchValue))
          .then(response => response.json())
          .then(searchData => {
            setMessagesDrawerData({searchData: searchData, currentSearchScrollPosition: 0});
          });
      }
    );


  };

  const clearSearch = () => {
    setMessagesDrawerData({searchFocused: false, search: null, searchData: null, startNewConversation: false});
  }

  return (
    <div className="current-search">
      <form action='#'>
        <WaitSearchInput
          type="text"
          name="conversation-search"
          value={search}
          placeholder={
            startNewConversation
              ? 'Type a name or username to start a new chat'
              : 'Search by message, username, name, bio, or headline'
          }
          onChange={searchConversations}
          waitInterval={500}
          limit={50}
          autoFocus={search !== null ? true : false} />
        <i className="fa fa-search"></i>
        { (search || startNewConversation) && <span className="clear-search" onClick={clearSearch}>x</span> }
      </form>
    </div>
  )
}

export default SearchBox;
