import Carousel from "components/common/Carousel";
import TopPost from "components/posts/top_posts/TopPost";

export default function TopPostsCarousel(props) {
  const { posts } = props;

  if (!posts || posts.length == 0) { return null; }
  const slides = [];

  posts.forEach((post) => {
    slides.push(<TopPost post={post} />);
  });

  return (
    <div>
      <Carousel
        title="Top Posts"
        slides={slides}
      />
    </div>
  );
}
