import ReactDOM from "react-dom";
import { Avatar, Link } from "@material-ui/core";
import FollowButton from "components/FollowButton";
import SendMessageButton from "components/messages/SendMessageButton";
import UseProfileModalState from "data/ProfileModalState";
import { accountsHoverModalPath } from "utilities/UrlHelpers";
import SelectProLabels from "components/accounts/SelectProLabels";
import ProfileTypeBadge from "components/accounts/ProfileTypeBadge";
import CurrentUserData from "components/common/CurrentUserData";
import { makeStyles } from "@material-ui/core/styles";
import EventTracking from "components/common/EventTracking";
import useBoostrapVariables from "utilities/BootstrapVariables";
import CampaignInvitationButton from "components/campaign_invitations/CampaignInvitationButton";
const cssDefaults = useBoostrapVariables();

// import images ;)
import infFireballImage from "../../../assets/images/influence-icon.svg";
import ReportError from "components/common/ReportError";

const supportsHover = window.matchMedia('(hover: hover)').matches;

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 262,
    borderRadius: 8,
    backgroundColor: "#fff",
    padding: 15,
    boxShadow: '0px 0px 5px #e5e5e5',
    zIndex: cssDefaults.zindexModal
  },
  avatar: {
    width: 62,
    height: 62,
    marginRight: 10,
  },
  userDataContainer: {
    display: "flex",
    flexDirect: "row",
    alignItems: "top",
  },
  userData: {
    "& .profile-full-name": {
      display: "block",
      fontSize: 18,
      fontWeight: "bold",
      marginTop: 12,
      marginBottom: 6
    },
    "& .profile-badges .label": {
      fontWeight: 'normal',
    },
    "& .profile-username-reach": {
      fontSize: 14,
    },
    "& .profile-headline": {
      display: "block",
      fontSize: 12,
      color: "#969696",
      fontWeight: "normal",
      marginBottom: 10,
    },
    "& .profile-followed-back": {
      marginTop: 10,
      fontSize: 12,
      "& i": {
        marginRight: 5
      }
    }
  },
  fireball: {
    width: 13,
    marginTop: -2,
    marginRight: 3
  },
  profileTypeBadge: {
    marginRight: 5,
  },
  followButton: {
    display: "block",
    padding: 10,
    marginTop: 10,
    width: "100%",
  },
  igLink: {
    "& .fa-instagram": {
      marginRight: 2,
      verticalAlign: "middle"
    }
  }
}));

const getUserdata = (scopedState) => scopedState.get().userData;
const isVisible = (scopedState) => scopedState.get().visible;
const isHovering = (scopedState) => scopedState.get().hovering;
const setVisible = (scopedState, visibility) => scopedState.merge({ visible: !!visibility });
const setUserData = (scopedState, userData) => scopedState.merge({ userData });
const setHovering = (scopedState, hovering) => scopedState.merge({ hovering: !!hovering });
const setPosition = (scopedState, position) => scopedState.merge({ position });
const HOVER_TIMEOUT = 250;

let hoverEnterTimeout = null;
let hoverLeaveTimeout = null;

const ProfileHoverModal = () => {
  const componentState = UseProfileModalState(false);
  const localState = componentState.get();
  const userData = getUserdata(componentState);
  const classes = useStyles();
  const {
    id,
    username,
    igUsername,
    reach,
    fullName,
    accountType,
    isPro,
    isSelect,
    profilePicture,
    headline,
    profileUrl,
    followed,
    followedBack,
  } = userData;

  const modalHoverEnter = () => {
    clearTimeout(hoverEnterTimeout);
    setHovering(componentState, true);
    setVisible(componentState, true);
  };

  const modalHoverLeave = () => {
    clearTimeout(hoverLeaveTimeout);
    setHovering(componentState, false);
    hoverLeaveTimeout = setTimeout(() => {
      if (isHovering(componentState)) { return; }
      setVisible(componentState, false);
    }, HOVER_TIMEOUT);
  };
  return (
    <div
      onMouseEnter={() => modalHoverEnter()}
      onMouseLeave={() => modalHoverLeave()}
      className={classes.root}
      style={{
        display: isVisible(componentState) ? "block" : "none",
        position: "absolute",
        top: localState.position.top,
        left: localState.position.left,
      }}
    >
      <div className={classes.userDataContainer}>
        <Link href={profileUrl} target="_blank" onClick={ ()=>{ EventTracking('Profile Modal - Click - Profile - Avatar') }}>
          <Avatar src={profilePicture} className={classes.avatar} />
        </Link>
        <div className={classes.userData}>
          <div className="profile-badges">
            <span className={classes.profileTypeBadge}>
              <ProfileTypeBadge accountType={accountType} />
            </span>
            <SelectProLabels isSelect={isSelect} isPro={isPro} />
          </div>
          <div className="profile-full-name">{fullName}</div>
          { headline && <div className="profile-headline">{headline}</div> }
          <div className="profile-username-reach">
            <a target="_blank" href={profileUrl} onClick={ ()=>{ EventTracking('Profile Modal - Click - Profile') } }>
              <img src={infFireballImage} alt="Influence Logo" className={classes.fireball} />
              {" "}
              {username}
            </a>
            {
              igUsername && (<>
                <br/>
                <a
                  className={ classes.igLink }
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://instagram.com/${igUsername}`}
                  onClick={ ()=>{ EventTracking('Profile Modal - Click - Instagram Profile') } }>
                  <i className="fa fa-instagram"></i>
                  {" "}
                  { igUsername }
                </a>
              </>)
            }
            { (reach + '' !== '' && (reach + '' !== '0')) && (<> <br/> <strong>{reach}</strong> reach</>) }
          </div>
          { followedBack && (
            <div className="profile-followed-back">
              <i className="fa fa-user"></i>
              Follows you
            </div>
          ) }
        </div>
      </div>
      { id && CurrentUserData().id && id !== CurrentUserData().id && (
        <>
          <FollowButton
            source="FollowButton:ProfileHoverModal"
            followedId={id}
            followed={followed}
            followedBack={followedBack}
            baseClass={`${classes.followButton} btn`}
            followedClass="btn-default"
            followClass="btn-info"
            onClick={ ()=>{ EventTracking('Profile Modal - Click - Follow') } }
          />
          {
            CurrentUserData().account_type == "business" && accountType == "influencer" ?
              <div style={{marginTop: "5px"}}>
                <CampaignInvitationButton
                  username={username}
                  invitationAccountId={id}
                  wrapperClasses={'btn-block'} />
              </div>
            :
            ""
          }
          <SendMessageButton onClick={modalHoverLeave} messagingAccountId={id} wrapperClasses="padding-10 btn btn-info btn-block margin-top " />
        </>
      ) }
    </div>
  );
};

export default ProfileHoverModal;

const calcPosition = (openerElement, hoverModal) => {
  let basePosition = {
    top: openerElement.offset().top + openerElement.height(),
    left: openerElement.offset().left + 5,
  };
  const rightBoundary = (basePosition.left + hoverModal.find('> div').width());

  if($(window).width() < rightBoundary){
    basePosition.left = basePosition.left - (rightBoundary - $(window).width());
  }

  return basePosition;
};

window.initUnmarkedProfileLinks = () => {
  $(".unmarked-profile-hover-links a").each(function(){
    const link = $(this);
    const link_text = link.text().trim()
    if(!link_text.startsWith("@")){ return; }
    link.addClass("profile-modal-opener");
    link.data("profile-modal-username", link_text.replace("@",""));
  });
};

$(() => {
  const globalState = UseProfileModalState(true);

  // init long lived profile modal to pull node from
  $("body").append("<div id=\"profile-hover-modal\"> </div>");

  const node = $("#profile-hover-modal")[0];

  const fetchProfileData = async (username, element) => {
    const response = await Fetch(accountsHoverModalPath(username));
    if(response.ok){
      const data = await response.json();
      // don't do anything if we're no longer hovering.
      if (!isHovering(globalState)) { return; }
      EventTracking('Profile Modal - Displayed');
      setUserData(globalState, data);
      setVisible(globalState, true);
      setPosition(globalState, calcPosition(element, $(node)));
    } else {
      const text = await response.text();
      if(!text.match("not_found")){
        ReportError({message: `Error pulling profile data for profile hover modal, error: ${text}`})
      }
    }
  };

  ReactDOM.render(<ProfileHoverModal />, node);

  // Force use of anonymous function(){} over ()=>{} for proper usage of $(this) to work
  $("body").on("mouseover", ".profile-modal-opener", function (event) { // eslint-disable-line
    const openerElement = $(this);
    if(!supportsHover){ return; }
    event.preventDefault();
    event.stopPropagation();
    const username = openerElement.data("profile-modal-username");
    if (!username) { return; }
    setHovering(globalState, true);
    clearTimeout(hoverEnterTimeout);
    hoverEnterTimeout = setTimeout(() => {
      // don't do anything if we're no longer hovering or already hovering.
      if (!isHovering(globalState) || (isVisible(globalState) && username == getUserdata(globalState).username)) { return; }
      fetchProfileData(username, openerElement);
    }, HOVER_TIMEOUT);
  });
  $("body").on("mouseout", ".profile-modal-opener", function() { // eslint-disable-line
    if(!supportsHover){ return; }
    clearTimeout(hoverLeaveTimeout);
    setHovering(globalState, false);
    hoverLeaveTimeout = setTimeout(() => {
      if (isHovering(globalState)) { return; }
      setVisible(globalState, false);
    }, HOVER_TIMEOUT);
  });
});