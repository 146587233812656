import { useEffect } from 'react';

const ScrollTracking = (props) => {
  const { trackElement, trackValueName, trackValue, setDataMethod } = props;

  function handleScroll(event){
    let data = {};
    data[trackValueName] = event.srcElement.scrollTop;
    setDataMethod(data);
  }

  function scrollToCurrent(){
    if(!trackElement.current){
      return;
    }
    $(trackElement.current).scrollTop(trackValue || 0);
  }

  // attach scroll events
  useEffect(() => {
    if(!trackElement.current){
      return;
    }
    scrollToCurrent();
    trackElement.current.addEventListener('scroll', handleScroll);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if(!trackElement.current){
        return;
      }
      trackElement.current.removeEventListener('scroll', handleScroll);
    };
  });

  return null;
};

export default ScrollTracking;
