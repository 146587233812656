import { useEffect } from "react";
import CircularImage from "components/common/CircularImage/index";
import "components/notifications/NotificationStyles";
import {influencerSetNotificationReadPath} from "utilities/UrlHelpers";
import TimeSince from 'components/common/TimeSince';
import EventTracking from 'components/common/EventTracking';
import ReactHtmlParser from 'react-html-parser';
import CurrentUserData from "CurrentUserData";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const NotificationItem = (props) => {
  const {
    notification,
    closeDrawer
  } = props;
  const { ghost_mode } = CurrentUserData();

  const {
    id,
    notification_type,
    notifiable_type,
    notifiable,
    actor,
    read_at,
    created_at_timestamp
  } = notification;

  const {
    action_text,
    display_image_url,
    public_url,
    javascript_action,
    avatar
  } = notifiable

  const {
    username,
    fullName
  } = actor;

  const openUrlOrDoAction = () => {
    if(javascript_action){
      eval(javascript_action);
    }
    if(public_url){
      window.location = public_url;
    }
    closeDrawer();
  };

  const setNotificationRead = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    EventTracking("Notification Click", {notification_type: notification_type, notifiable_type: notifiable_type});
    if(!ghost_mode){
      await Fetch(influencerSetNotificationReadPath(id), {method: 'PUT'})
    }
    openUrlOrDoAction();
  };

  const visitProfile = (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    window.location = `/${username}`;
    return false;
  }

  const NotificationText = () => {
    return (
      <span dangerouslySetInnerHTML={{__html: action_text}} />
    );
  }

  const NotificationBadge = () => {
    if (notification_type == "generic") {
      return(
        <div className={`circular-icon generic-badge`}>
          <FontAwesomeIcon icon="flag" />
        </div>
      );
    }

    switch(notifiable_type){
      case 'CampaignApplication':
        let icon = '';
        let css_class = 'campaign-application-badge';
        if(notification_type=='application_created'){
          icon = 'file-alt'
        }else if(notification_type == 'application_accepted'){
          icon = 'user-plus'
          css_class = css_class + ' green';
        }else if(notification_type == 'application_rejected'){
          icon = 'user-times'
          css_class = css_class + ' red';
        }
        return(
          <div className={`circular-icon ${css_class}`}>
            <FontAwesomeIcon icon={icon} />
          </div>
        )
      case 'Campaign':
        return (
          <div className="circular-icon campaign-badge">
            <FontAwesomeIcon icon="bullhorn" />
          </div>
        )
      case 'Comment':
        return (
          <div className="circular-icon comment-badge">
            <FontAwesomeIcon icon="comment" />
          </div>
        )
      case 'Fireball':
        return(
          <div className="circular-icon fireball-badge">
            <i className="fireball-icon"></i>
          </div>
        )
      case 'Follow':
        return(
          <div className="circular-icon follow-badge">
          <FontAwesomeIcon icon="user-plus" />
          </div>
        )
      case 'HighlightedPost':
        return(
          <div className="circular-icon highlighted-post-badge">
            <FontAwesomeIcon icon="calendar-plus" />
          </div>
        )
      case 'Post':
        return(
          <div className="circular-icon post-mention-badge">
            <FontAwesomeIcon icon="at" />
          </div>
        )
      case 'User':
        return(
          <div className="circular-icon user-badge">
            <FontAwesomeIcon icon="hand-paper" />
          </div>
        )
      case "Space::Question":
        return(
          <div className="circular-icon question-badge">
            <FontAwesomeIcon icon="question" />
          </div>
        )
      case "Space::Answer":
        return(
          <div className="circular-icon answer-badge">
            <FontAwesomeIcon icon="comment-dots" />
          </div>
        )
      case "Vote":
        return(
          <div className="circular-icon vote-badge">
            <FontAwesomeIcon icon="arrow-up" />
          </div>
        )
    }
    return null;
  }

  return (
    <a href={public_url} className={`notification ${!read_at && 'unread'}`} onClick={setNotificationRead}>
      <div className="notification-avatar" onClick={visitProfile}>
        <CircularImage src={avatar} classes='avatar-image' />
        <NotificationBadge />
      </div>
      <p className="notification-text">
        <NotificationText /><br />
        <span className="time-ago"><TimeSince date={created_at_timestamp} live /></span>
      </p>
      { display_image_url &&
          <img src={display_image_url} className="notification-image" /> }
    </a>
  )
}

export default NotificationItem;
