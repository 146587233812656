import { createState, useState } from "@hookstate/core";

const ActivitiesState = createState({
  activities: {}
});

export default function UseActivitiesState(accessDirectly) {
  if (!accessDirectly) {
    return useState(ActivitiesState);
  }
  return ActivitiesState;
}
