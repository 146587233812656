import { useState } from 'react';
import CurrentUserData from 'CurrentUserData';
import Warning from './Warning';
import useSharedModalStyles from './ModalStyles';
import Modal from '@material-ui/core/Modal';

export default function PreviewButton(props){
  const { message, classes = "btn btn-info", buttonText = "Preview" } = props;
  const sharedClasses = useSharedModalStyles();
  const { attachmentFilename, attachmentUrl, attachmentDownloadUrl } = message;
  const [showDownloadWarning, setShowDownloadWarning] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const handlePreviewShow = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if(CurrentUserData().flags_hide_download_warning){
      setShowPreview(true);
    }else{
      setShowDownloadWarning(true)
    }
  };

  const handlePreviewClose = () => {
    setShowPreview(false);
  }

  if(!attachmentFilename || !attachmentFilename.match(/.(jpg|jpeg|png|gif|svg)$/i)){
    return null;
  }

  return (
    <>
      <a href={attachmentUrl} onClick={handlePreviewShow} className={classes}>{buttonText}</a>
      <Warning
        type="preview"
        message={message}
        showWarning={showDownloadWarning}
        setShowWarning={setShowDownloadWarning}
        setShowPreview={setShowPreview} />
      <Modal open={showPreview} onClose={handlePreviewClose}>
        <div className={sharedClasses.previewModal}>
          <div className="text-left">
            <div className="close-modal">
              <button onClick={handlePreviewClose} className="btn btn-link">X</button>
            </div>
            <div>
              <strong>{attachmentFilename}</strong>
            </div>
          </div>
          <img onClick={handlePreviewClose} src={attachmentUrl} />
          <div className="text-right">
            <a href={attachmentDownloadUrl} className="btn btn-info">Download</a>
          </div>
        </div>
      </Modal>
    </>
  );
};
