import { useEffect } from "react";
import UseSpaceState from "data/SpaceState";
import useSharedSpacesStyles from "./SharedStyles";

const SpaceDetail = (props) => {
  const { space } = props;
  const spaceState = UseSpaceState();
  const classes = useSharedSpacesStyles();

  if(!space || !space.description || space.description == ""){
    return null;
  }

  return (
    <>
      <div className="visible-xs visible-sm margin-top"></div>
      <div className={`${classes.spaceDetail} white-content-block`}>
        <div className="pull-right visible-xs visible-sm" onClick={()=>{
            spaceState.merge({currentMobilePanel: "middle"});
          }}>
          <span className="fa fa-times"></span>
        </div>
        <h2>Details</h2>
        <div dangerouslySetInnerHTML={{__html: space.description}}></div>
      </div>
    </>
  )
}

export default SpaceDetail;
