import { Component } from 'react';
import { campaignsAvailablePath } from 'utilities/UrlHelpers';

class CampaignsAvailable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaignsAvailable: null
        };
    }

    render() {
      if (this.state.campaignsAvailable) {
        return (
          <a className="campaigns-available" href="/go/campaigns/discovery?utm_source=campaigns-available">
            <h1>{this.state.campaignsAvailable}</h1>
            <p>
            There are currently {this.state.campaignsAvailable} campaigns
            on influence.co that you are eligible to apply for. Click here
            to check them out today!
            </p>
            <p>
              <span className="btn btn-info btn-block">View Campaigns</span>
            </p>
          </a>
        )
      } else {
        return ""
      }
    }

    componentDidMount() {
      this.getCampaigns();
    }

    async getCampaigns() {
      const response = await Fetch(campaignsAvailablePath());
      const localState = await response.json();
      this.setState(localState);
    }
}

export default CampaignsAvailable;
