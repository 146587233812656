import { useEffect, useState, useRef } from "react";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import CircularImage from "components/common/CircularImage/index";
import CurrentUserData from "components/common/CurrentUserData";
import LimitedInput from "components/common/LimitedInput";
import BasicLimitedInput from "components/common/BasicLimitedInput";
import { spaceQuestionCreateAPIPath, spaceMembershipCreateAPIPath } from "utilities/UrlHelpers";
import EventTracking from "components/common/EventTracking";
const cssDefaults = useBoostrapVariables();

import useSharedSpacesStyles from "./SharedStyles";
import useBoostrapVariables from "utilities/BootstrapVariables";

// Going to rely on SpaceState for now, eventually may just switch to the modal making it's own (potentially duplicate) request to spaces so it can be opened from anywhere
import UseSpaceState from "data/SpaceState";

const AskQuestionModal = (props) => {
  const { onClose, defaultSpaceSlug, onShow } = props;
  const { avatar, fullName, username } = CurrentUserData();
  const classes = useSharedSpacesStyles();
  const spaceState = UseSpaceState();
  const { spaces } = spaceState.get();
  const [spaceSlug, setSelectedSpace] = useState(defaultSpaceSlug);
  const [autoSubscribeChecked, setAutoSubscribeChecked] = useState(true);
  const titleInputRef = useRef();
  const descriptionInputRef = useRef();
  const [titleState, setTitleState] = useState(null);
  const space = spaceSlug ? spaces[spaceSlug] : null;

  const close = () => {
    onClose();
  };

  const handleAutoSubscribeChange = (event) => {
    setAutoSubscribeChecked(!autoSubscribeChecked);
  };

  const handleSpaceChange = evt => {
    const newSpaceSlug = evt.target.value;
    setSelectedSpace(newSpaceSlug);
  };

  const handleSubmit = async () => {
    if(autoSubscribeChecked){
      const resp = await Fetch(spaceMembershipCreateAPIPath(spaceSlug), {method: "post"});
    }

    const resp = await Fetch(spaceQuestionCreateAPIPath(spaceSlug), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({title: getTitle(), description: getDescription()})
    });

    if(resp.ok){
      const data = await resp.json();
      window.location = data.url;
    }
  };

  useEffect(() => {
    EventTracking("Question Modal Opened");
    onShow();
  }, []);

  const SpaceDropdown = () => {
    return (
      <div className="space-dropdown">
        <div className="dropdown-row">
          <FormControl variant="outlined" className={classes.spaceSelect}>
            <select name="space-select"
              onChange={handleSpaceChange}
              value={spaceSlug || ""}
              className="space-select form-control">
              {renderSpaceOptions()}
            </select>
          </FormControl>
          { space && !space.member && (
            <div className="follow-space">
              Follow the <strong>{space.title}</strong> space?
              <Checkbox
                checked={autoSubscribeChecked}
                onClick={handleAutoSubscribeChange}
                color="primary" />
            </div>
          )}
        </div>
        {
          space && (
            <>
              <div className="space-instructions">
                {space.instructions}
              </div>
              <br/>
              <div className="alert alert-warning">
               ⚠️  Questions that are self-promotional, solicit influencers, or break community policies will be deleted. <a href="#" data-beacon-article-modal="5ef9f6d92c7d3a10cba9b66b">Read Community Guidelines</a>
              </div>
            </>
          )
        }
      </div>
    )
  };

  const renderSpaceOptions = () => {
    const spaceOptions = [
      <option value="" disabled={true} key="default">Choose a space</option>
    ];
    Object.keys(spaces).forEach((spcSlug) => {
      const spc = spaces[spcSlug];
      spaceOptions.push(
        <option value={spc.slug} key={spc.id}>{spc.title}</option>
      );
    });
    return spaceOptions;
  };

  const getTitle = () => {
    if(!titleInputRef.current){
      return null;
    }
    return titleInputRef.current.value;
  }

  const getDescription = () => {
    if(!descriptionInputRef.current){
      return null;
    }
    return descriptionInputRef.current.value;
  }

  return (
    <div
      className={`modal-dialog modal-lg`}>
      <div className={`${classes.askQuestionModal} modal-content`}>
        <div className="modal-body">
          <h2>Ask a Question</h2>
          <hr />
          <div className="avatar-row">
            <CircularImage src={avatar} />
            {fullName} @{username}
          </div>
          <div>
            <SpaceDropdown />
          </div>
          <BasicLimitedInput
            name="question_title"
            classes="title-input"
            baseClass="title-input-container"
            limit={250}
            multiline
            rows={1}
            placeholder={"What's your question? (required)"}
            autoFocus={true}
            inputRef={titleInputRef}
            onChange={ e => setTitleState( e.target.value ) } />
          <LimitedInput
            name="question_description"
            classes="description-input"
            limit={750}
            multiline
            variant="outlined"
            placeholder="Write a description... (optional)"
            skipReplaceNewline={true}
            inputRef={descriptionInputRef} />
          <div className="row">
            <div className="col-xs-6 col-sm-8">
              <ul className="list-unstyled">
              <li>Question descriptions support some additional formatting. <a href="#" data-beacon-article-modal="5fbd6b8ccff47e0017d35118">Learn more</a></li>
              </ul>
            </div>
            <div className="col-xs-6 col-sm-4">
              <p className="text-right">
              <button
                onClick={handleSubmit}
                className={`${(!titleState || !space) && "disabled"} btn btn-primary `}
                disabled={(!titleState || !space)} >
                Ask a Question
              </button>
              </p>
            </div>
          </div>
        </div>
        <div className="modal-close-button" onClick={close}>×</div>
      </div>
    </div>
  )
}

export default AskQuestionModal;
