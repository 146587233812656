import { useEffect, useState } from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import UseMessagesState from 'data/MessagesState';
import { makeStyles } from "@material-ui/core/styles";
import useBoostrapVariables from "utilities/BootstrapVariables";
import CurrentUserData from "components/common/CurrentUserData";
const cssDefaults = useBoostrapVariables();

const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    borderRight: `1px ${cssDefaults.messagesDrawerGridColor} solid`,
    borderBottom: `1px ${cssDefaults.messagesDrawerGridColor} solid`,
    padding: 15,
    verticalAlign: "middle",
    "& .MuiSelect-root": {
      paddingTop: 15,
      paddingBottom: 15,
      fontSize: 14,
      lineHeight: "14px"
    },
    "& .MuiSelect-icon": {
      top: "calc(50% - 8px)"
    }
  },
  menuItem: {
    fontSize: 14
  }
}));

export default function CampaignsFilters() {
  const classes = useStyles();
  const messagesDrawerState = UseMessagesState();
  const { setMessagesDrawerData, fetchConversationsListData, campaignId, campaignsAvailable } = messagesDrawerState.get();
  const { account_type } = CurrentUserData();

  if(account_type != "business" || !campaignsAvailable || campaignsAvailable.length == 0){ return null; }

  const handleChange = evt => {
    const newCampaignId = evt.target.value;
    if(newCampaignId == campaignId){
      return;
    }
    setMessagesDrawerData({campaignId: newCampaignId}, ()=>{fetchConversationsListData()});
  };

  const renderOptions = () => {
    const optionsOutput = [
      <MenuItem value="" key="default" className={classes.menuItem}>Filter by Campaign</MenuItem>
    ];
    campaignsAvailable.forEach((campaign) => {
      optionsOutput.push(
        <MenuItem value={campaign.id} key={campaign.id} className={classes.menuItem}>{campaign.title}</MenuItem>
      )
    });
    return optionsOutput;
  }

  return (
    <div className="messages-campaign-filters">
      <FormControl variant="outlined" className={classes.select}>
        <Select
          labelId="messages-campaign-filter-label"
          id="messages-campaign-filter"
          value={campaignId || ""}
          onChange={handleChange}
          displayEmpty
        >
          {renderOptions()}
        </Select>
      </FormControl>
    </div>
  );
}
