import { useState } from "react";
import LoadingSpinner from "components/common/Loading";
import EnterInput from "components/common/EnterInput";
import { searchNotesUsernamePath } from "utilities/UrlHelpers";
import { assetUrl } from "utilities/RailsHelpers";

const NoteAccountSearch = (props) => {
  const {
    notesDrawerData,
    mobile
  } = props

  const {
    setNotesData,
    searchAccount,
    noteListData,
    drawerMethods,
    resetDrawer
  } = notesDrawerData;


  const {
    setShowBack,
    setDrawerTitle,
    setCloseDrawer
  } = drawerMethods;

  const [noteSearching, setNoteSearching] = useState(false);
  const [showError, setShowError] = useState(false);

  const searchAccounts = async (e) => {
    setNoteSearching(true);
    // Build formData object.
    let formData = new FormData();
    formData.append('handle', searchAccount);
    const response = await Fetch(searchNotesUsernamePath(), {method: 'POST', body: formData});

    setNoteSearching(false);
    if(response.ok){
      const data = await response.json();
      if(data && data.note && data.note.id && data.account && data.account.id){
        const noteData = {}
        noteData[data.note.id] = data.note;
        // "pop" this note to the top of the list
        const updatedNoteListData = { ...noteData, ...noteListData };
        setShowError(false);
        setNotesData({
          noteListData: updatedNoteListData,
          selectedAboutId: data.account.id,
          selectedNoteId: data.note.id,
          editNoteContent: data.note.content,
          editingNote: true
        });
        if(mobile){
          setDrawerTitle(`Notes on ${ data.note.about_name }`);
          setShowBack(true);
          setCloseDrawer(resetDrawer);
        }
      }else{
        setShowError(true);
      }
    }else{
      setShowError(true);
    }
  }

  const setSearchData = (e) => {
    setNotesData({ searchAccount: e.target.value });
  }

  const backToList = () => {
    setNotesData({
      showAddNoteMobile: false
    })
  };

  return (
    <div className="note-account-search">
      <h2 className="note-add-header">What's Notes?</h2>
      <p>
        <img className="whats-notes-image" src={assetUrl('whats_notes.png')} />
        You can use notes to record your personal thoughts on different influencers, as well as add files and other media.
      </p>
      <p><strong>Enter a username to get started:</strong></p>
      {
        noteSearching
          ? <LoadingSpinner />
          : <>
              <EnterInput
                name={'note-account-search'}
                onChange={setSearchData}
                onEnter={searchAccounts}
                autoFocus={true}
                value={searchAccount || ''} />
              {
                showError
                  ? <div className="search-note-error">That username could not be found, please try a different one.</div>
                  : ''
              }
              <div className="text-center">
                <button onClick={backToList} className="visible-xs-inline-block btn btn-default">Back</button>
                <button onClick={searchAccounts}
                        disabled={ searchAccount ? false : true }
                        className="btn btn-info">Next</button>
              </div>
            </>
      }
    </div>
  )
}

export default NoteAccountSearch;
