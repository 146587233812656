import { useEffect, useState } from "react";
import { spaceMembershipCreateAPIPath, spaceMembershipDestroyAPIPath } from "utilities/UrlHelpers";
import UseSpaceState from "data/SpaceState";
import CurrentUserData from "components/common/CurrentUserData";

const MembershipButton = (props) => {
  const { spaceSlug, hideUnfollow, linkButton } = props;
  const spaceState = UseSpaceState();
  const { spaces } = spaceState.get();

  if(!spaces[spaceSlug]){
    return null;
  }

  const space = spaces[spaceSlug];
  const isMember = space.member;

  if(isMember && hideUnfollow){
    return null;
  }

  const clickFollow = async () => {
    if(!CurrentUserData().id){
      return window.triggerSignupWall('space-follow');
    }
    const path = isMember ? spaceMembershipDestroyAPIPath(space.id) : spaceMembershipCreateAPIPath(space.id);
    const method = isMember ? "delete" : "post";
    const resp = await Fetch(path, { method: method });
    spaceState.spaces[spaceSlug].member.set(!isMember);
  };

  return (
    <button onClick={clickFollow} className={linkButton ? "link-button" : "btn btn-info"}>
      {
        isMember
          ? "Unfollow"
          : "Follow"
      }
    </button>
  )
}

export default MembershipButton;
