import { useEffect } from 'react';
import SpacesList from "components/spaces/SpacesList";
import SpaceHeader from "components/spaces/SpaceHeader";
import SpaceDetail from "components/spaces/SpaceDetail";
import QuestionDisplay from "components/spaces/QuestionDisplay";
import MobileSpacesListOpener from "components/spaces/MobileSpacesListOpener";
import UseSpaceState from "data/SpaceState";

const QuestionPage = (props) => {
  const currentSpaceState = UseSpaceState();
  const { currentQuestion, spaces } = props;
  const currentSpace = spaces ? spaces[currentQuestion.spaceSlug] : {};
  const { currentMobilePanel } = currentSpaceState.get();

  const mobileClass = (panel) => {
    return panel == currentMobilePanel ? "" : "hidden-sm hidden-xs"
  };

  return (
    <div className={`container ${currentMobilePanel == "left" && "sm-fullscreen xs-fullscreen"}`}>
      <div className="row margin-top hidden-xs"></div>
      <div className="row">
        <div className={`col-sm-12 col-md-3 ${mobileClass('left')} sticky`}>
          <SpacesList />
        </div>
        <div className={`col-sm-12 col-md-6 no-padding-xs no-padding-sm ${mobileClass('middle')}`}>
          <MobileSpacesListOpener />
          <SpaceHeader space={currentSpace} hideTextQuestionBox={true} />
          <QuestionDisplay question={currentQuestion} spaces={spaces} hideTopAnswer={true} />
        </div>
        <div className={`col-sm-12 col-md-3 ${mobileClass('right')}`}>
          <SpaceDetail space={currentSpace} />
        </div>
      </div>
    </div>
  )
};

export default QuestionPage;
