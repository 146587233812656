import getConsumer from "../channels/consumer"

const consumer = getConsumer();

function ROIWatch(campaignId) {
  consumer.subscriptions.create(
    { channel: "RoiChannel", campaign_id: campaignId },
    {
       received(data) {
         $(data.selector).replaceWith(data.body)
       }
    }
  );
  return () => { sub.unsubscribe(); };
}

window.ROIWatch = ROIWatch;

export default ROIWatch;
