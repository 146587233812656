import { Component } from 'react';

// adapted from https://stackoverflow.com/a/31273404/542550
export default class EnterInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onEnter: props.onEnter || null,
      onKeyDown: props.onKeyDown || null,
      onChange: props.onChange || null,
      value: props.value || null,
      defaultValue: props.defaultValue || null,
      classes: props.classes || '',
      autoFocus: props.autoFocus || false,
      name: props.name || '',
      inputRef: props.inputRef || null
    };
  }

  componentDidUpdate = (oldProps) => {
    const { props } = this;
    if (props !== oldProps) {
      this.setState({ ...props });
    }
  }

  handleOnChange = (e) => {
    const { state } = this;
    const { onChange } = state;

    if (onChange) {
      onChange(e);
    }
    return true;
  }

  handleOnKeyDown = (e) => {
    const { state } = this;
    const { onEnter, onKeyDown } = state;
    if (onKeyDown) {
      onKeyDown(e);
    }
    if (onEnter) {
      if (e.key === 'Enter') {
        onEnter(e);
      }
    }
    return true;
  }

  render() {
    const { state, handleOnChange, handleOnKeyDown } = this;
    const { value, classes, autoFocus, name, defaultValue, inputRef } = state;

    const valueProps = value ? { value: value } : { defaultValue: defaultValue };
    const refProps = inputRef ? { ref: inputRef } : {};
    return (
      <input
        type="text"
        onKeyDown={handleOnKeyDown}
        onChange={handleOnChange}
        {...valueProps}
        {...refProps}
        className={classes}
        autoFocus={autoFocus}
        name={name}
      />
    );
  }
}
