import { useEffect } from "react";
import Pluralize from 'pluralize';
import CircularImage from "components/common/CircularImage/index";
import MembershipButton from "components/spaces/MembershipButton";
import AskQuestionOpener from "components/spaces/AskQuestionOpener";
import UseSpaceState from "data/SpaceState";
import useSharedSpacesStyles from "./SharedStyles";
import CurrentUserData from "components/common/CurrentUserData";

const SpaceHeader = (props) => {
  const { space, hideTextQuestionBox } = props;
  const spaceState = UseSpaceState();
  if(!space || space == {}){
    return null;
  }
  const {
    iconDetailUrl,
    coverImageUrl,
    title,
    about,
    slug,
    membershipCount,
    questionsCount,
    topQuestionAvatars } = space;
  const classes = useSharedSpacesStyles();


  const TopQuestionAvatars = () => {
    const avatars = [];

    topQuestionAvatars.forEach((avatarUrl) => {
      avatars.push(
        <CircularImage key={avatarUrl} src={avatarUrl} />
      )
    });

    return avatars;
  };

  return (
    <>
      <div className={`${classes.spaceHeader} white-content-block`}>
        <img src={coverImageUrl} className="cover" />
        <div className="space-avatar">
          <CircularImage src={iconDetailUrl} />
          <h2>{title}</h2>
        </div>
        <p>
          {about}
          <span className="visible-xs visible-sm text-center">
            <button className="btn btn-link" onClick={()=>{
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome,
                spaceState.merge({currentMobilePanel: "right"});
              }}>View Details</button>
          </span>
        </p>
        <div>
          <div className="col-xs-12 mobile-stats">
            <div className="mobile-question-counts">
              <div className="question-avatars">
                <TopQuestionAvatars />
              </div>
              <span className="question-counts counts">{ Pluralize("questions", questionsCount, true) }</span>
            </div>
            <div className="counts text-right">{ Pluralize("members", membershipCount, true) }</div>
          </div>
          <div className="action-buttons">
            <MembershipButton spaceSlug={slug} />
            { !CurrentUserData().ff_hide_posting && <AskQuestionOpener spaceSlug={slug} type="button" /> }
            <div className="question-avatars hidden-xs hidden-sm">
              <TopQuestionAvatars />
            </div>
            <div className="question-counts counts hidden-xs hidden-sm">{ Pluralize("questions", questionsCount, true) }</div>
            <div className="counts hidden-xs hidden-sm">{ Pluralize("members", membershipCount, true) }</div>
          </div>
        </div>
      </div>
      { !CurrentUserData().ff_hide_posting && !hideTextQuestionBox && <AskQuestionOpener spaceSlug={slug} type="text" /> }
    </>
  )
}

export default SpaceHeader;
