import { useEffect } from "react";
import LoadingSpinner from "components/common/Loading";
import CircularImage from "components/common/CircularImage/index";
import { spacesAPIPath, spacePath, spacesPath } from "utilities/UrlHelpers";
import UseSpaceState from "data/SpaceState";
import CurrentUserData from "components/common/CurrentUserData";
import MembershipButton from "./MembershipButton";
import useSharedSpacesStyles from "./SharedStyles";


import allSpacesIconUrl from "../../../assets/images/influence-icon.svg";

const SpacesList = (listProps) => {
  const classes = useSharedSpacesStyles();
  const DEFAULT_SPACES = {
    all: {
      id: "all",
      slug: "all",
      title: "All Spaces",
      iconSmallUrl: allSpacesIconUrl,
      url: spacesPath()
    },
    mine: {
      id: "mine",
      slug: "mine",
      title: "Feed",
      iconSmallUrl: CurrentUserData().avatar,
      url: `${spacesPath()}?member_only=true`
    }
  }
  const spaceState = UseSpaceState();
  const { spaces, currentSpaceId, questions, mobileSpaceListPanel } = spaceState.get();
  const getSpaces = (opts) => {
    const { memberOnly } = opts;
    const spaceRows = [];
    Object.keys(spaces).forEach((space_id) => {
      const space = spaces[space_id];
      if(memberOnly && space.member){
        spaceRows.push(space);
      }else if(!memberOnly && !space.member){
        spaceRows.push(space)
      }
    });
    return spaceRows;
  };
  const mySpacesRows = getSpaces({memberOnly: true});
  const otherSpacesRows = getSpaces({memberOnly: false});

  const loadSpaces = async () => {
    const res = await Fetch(spacesAPIPath());

    if(res.ok){
      const data = await res.json();
      updateSpaceData({spaces: data})
    }
  };

  const updateSpaceData = (newData) => {
    spaceState.merge(newData);
  }

  useEffect(()=>{
    loadSpaces();
  }, []);

  if(!spaces || (spaces.length == 0 && !mobileSpaceListPanel)){ return null; }
  const setMobilePanel = (panel) => {
    spaceState.merge({currentMobilePanel: panel});
    if(panel == "left"){
      $("body").css("overflow", "hidden");
    }else{
      $("body").css("overflow", "auto");
    }
  }

  const RenderSpacesArray = (props) => {
    const renderSpaces = props.spaces;
    const renderedSpaces = [];

    Object.keys(renderSpaces).forEach((space_id) => {
      const space = renderSpaces[space_id];
      renderedSpaces.push(
        <RenderSpace key={space.id} space={space} />
      )
    });
    return renderedSpaces;
  };

  const RenderSpace = (props) => {
    const { space } = props;
    const { slug, id, unreadCount, member, url, title, iconSmallUrl } = space
    const isCurrentSpace = (
      slug == currentSpaceId ||
      (id == 'all' && !currentSpaceId) ||
      (id == 'mine' && !currentSpaceId && otherSpacesRows.length == 0 )
    );
    return (
      <div className="space-list-item">
        <a href={url} key={id} className={isCurrentSpace ? "selected" : ""}>
          <CircularImage src={iconSmallUrl} />
          {title}
        </a>
        {
          id != "all" && id != "mine" &&
          <>
            <MembershipButton linkButton={true} spaceSlug={slug} hideUnfollow={true} />
            {
              member && unreadCount > 0 && (
                <div className="space-unread-count">{unreadCount}</div>
              )
            }
          </>
        }
        { id == "mine" && totalUnreadCount() > 0 &&
          <div className="space-unread-count">
            {totalUnreadCount()}
          </div> }
      </div>
    )
  };
  const totalUnreadCount = () => {
    const myspaces = getSpaces({memberOnly: true});
    let total = 0;
    myspaces.forEach((space) => {
      total += space.unreadCount;
    });
    return total;
  };

  const RenderSpaces = () => {
    if(mobileSpaceListPanel == "all" && otherSpacesRows.length == 0){
      return (
        <div className="white-content-block all-spaces">
          <h2>Spaces</h2>
          <RenderSpace space={DEFAULT_SPACES['all']} />
          <hr />
        </div>
      );
    }
    return (
      <div>
        {
          mySpacesRows.length > 0 && (!mobileSpaceListPanel || mobileSpaceListPanel == "mine")  &&
          <div className="white-content-block my-spaces">
            <h2>My Spaces</h2>
            <RenderSpace space={DEFAULT_SPACES['mine']} />
            <hr />
            <RenderSpacesArray spaces={mySpacesRows} />
          </div>
        }
        {
          otherSpacesRows.length > 0 && (!mobileSpaceListPanel || mobileSpaceListPanel == "all") &&
          <div className="white-content-block all-spaces">
            <h2>Spaces</h2>
            <RenderSpace space={DEFAULT_SPACES['all']} />
            <hr />
            <RenderSpacesArray spaces={otherSpacesRows} />
          </div>
        }
      </div>
    );
  };

  return (
    <div className={classes.spacesListContainer}>
      <div className="visible-sm visible-xs mobile-close">
        <div className="fa fa-times" onClick={()=>{setMobilePanel('middle')}}></div>
      </div>
      <div>
        <RenderSpaces />
      </div>
    </div>
  );
};

export default SpacesList;
