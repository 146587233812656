import { useState } from 'react';
import { assetUrl } from 'utilities/RailsHelpers'
import SearchBox from 'components/search/SearchBox'
import EventTracking from "components/common/EventTracking";

const KEY_INTERVAL = 700
const CLICK_INTERVAL = 10
const ENTER_KEY = 13

const SearchInput = (props) => {
  const { accountId } = props
  const [timer, setTimer] = useState(null)
  const [myval, setMyVal] = useState('')
  const [allowSearch, setAllowSearch] = useState(false)
  const [boxIsHidden, setBoxIsHidden] = useState(true)

  const triggerChange = () => {
    setAllowSearch(true)
    openSearchBox()
  }

  const handleChange = (e) => {
    clearTimeout(timer)
    setAllowSearch(false)
    setMyVal(e.target.value)
    setTimer(setTimeout(triggerChange, KEY_INTERVAL))
  }

  const handleClick = () => {
    clearTimeout(timer)
    setAllowSearch(false)
    setTimer(setTimeout(triggerChange, CLICK_INTERVAL))
  }

  const openSearchBox = () => {
    EventTracking('Global Search - Opened')
    $('._pendo-badge').addClass('hidden-xs hidden-sm')
    $('body').css({'overflow': 'hidden'})
    setBoxIsHidden(false)
  }

  const onClose = () => {
    $('._pendo-badge').removeClass('hidden-xs hidden-sm')
    $('body').css({'overflow': 'auto'})
    setAllowSearch(false)
    setBoxIsHidden(true)
  }

  const renderSearchInput = () => {
    return (
      <div className='search-box-container'>
        <div className='search-box in'>
            <input
            autoComplete="off"      
            className='z9002'
            placeholder='Search...'
            type='text'
            name='global_search_term'
            id='global_search_term'
            onChange={handleChange}
            onClick={handleClick}
            style={{
              backgroundImage: `url(${assetUrl('header-search-icon.png')})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '16px 16px',
              backgroundPosition: 'right 10px center'
            }}
          />
          {!boxIsHidden && <SearchBox
            onClose={onClose}
            term={myval}
            accountId={accountId}
            allowSearch={allowSearch}
          />}
        </div>
      </div>
    )
  }

  return renderSearchInput()
}

export default SearchInput
