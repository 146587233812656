import { useState, useRef, useEffect } from "react";
import JSONHeaders from "utilities/JSONHeaders";
import AvatarUsername from "components/common/AvatarUsername";
import CommentForm from "components/comments/CommentForm";
import CommentableList from "components/comments/CommentableList";
import UpvoteButton from "../UpvoteButton";
import ShareButton from "components/common/ShareButton";
import Dropdown from "./Dropdown";
import useSharedSpacesStyles from "./SharedStyles";
import TimeSince from 'components/common/TimeSince';
import UseSpaceState from "data/SpaceState";
import CurrentUserData from "components/common/CurrentUserData";
import { answerAPIPath, adminEditAnswerPath } from "utilities/UrlHelpers";
import CustomMarkdown from "components/common/CustomMarkdown";

const AnswerDetail = (props) => {
  const { answer } = props;
  const { voteCount, voted, id, account, description, url, activityId, created_at_timestamp, preview } = answer;
  const classes = useSharedSpacesStyles();
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [deletedState, setDeleted] = useState(false);
  const [descriptionState, setDescription] = useState(description);
  const [rerenderTrigger, setRerenderTrigger] = useState(1);
  const [editing, setEditing] = useState(false);
  const editingInput = useRef();

  const spaceState = UseSpaceState();

  const toggleCommentForm = () => {
    if(!CurrentUserData().id){
      return window.triggerSignupWall('answer-reply');
    }
    setShowCommentForm(!showCommentForm);
  };

  const toggleEditForm = () => {
    setEditing(!editing);
  };

  const handleUpdate = async () => {
    const newValue = editingInput.current.value
    const resp = await Fetch(answerAPIPath(answer.id), {
      ...JSONHeaders,
      method: "PUT",
      body: JSON.stringify({
        description: newValue
      })

    })
    setDescription(newValue);
    setEditing(false);
  };

  const handleDestroy = async () => {
    if(confirm("Are you sure you want to delete this answer?")){
      const resp = await Fetch(answerAPIPath(answer.id), { method: "DELETE" })
      if(resp.ok){
        alertify.success("Your answer has been deleted");
        setDeleted(true);
        setTimeout(()=>{
          window.location.reload();
        }, 2000)
      }else{
        alertify.error("The answer could not be deleted, please try again or contact us if the issue persists");
      }
    }
  };

  const addNewComment = (comment) => {
    setRerenderTrigger(Math.random());
    toggleCommentForm();
  };

  if(deletedState){ return null }

  return (
    <div key={id} id={`answer-${id}`} className={classes.answerDetail}>
      <div className="avatar-row">
        <AvatarUsername account={account} extraText="answered" />
        <Dropdown
          viewUrl={url}
          editUrl={adminEditAnswerPath(id)}
          activityId={activityId}
          followAccountId={account.id} />
      </div>
      {
        editing
          ? <div  className="editing-input">
              <textarea name="answer_description" rows="4" ref={editingInput} type="text" className="comment-text form-control" defaultValue={descriptionState}/>
              <button onClick={handleUpdate} className="btn btn-link no-padding">Update</button>
            </div>
          : <CustomMarkdown source={descriptionState} />
      }
      <div className="actions-row">
        <UpvoteButton
          defaultCount={voteCount}
          defaultVoted={voted}
          voteableId={id}
          voteableType="Space::Answer" />
        {
          (CurrentUserData().is_admin || CurrentUserData().id == answer.account.id) &&
            <button onClick={toggleEditForm} className="btn btn-link no-padding">
              { CurrentUserData().is_admin && "(Admin) " }
              {
                editing ? "Cancel" : "Edit"
              }
            </button>
        }

        <span className="dot">•</span>
        { !CurrentUserData().messaging_blocked && (<>
          <button onClick={toggleCommentForm} className="btn btn-link no-padding">Reply</button>
          <span className="dot">•</span>
        </>) }
        <ShareButton
          url={url}
          title={"Check out this answer on Influence.co"}
          hashtags="#influenceco"
          textButton={true} />
        <span className="dot">•</span>
        <TimeSince date={created_at_timestamp} live />
        { (CurrentUserData().is_admin || CurrentUserData().id == answer.account.id) && (
          <button className="fa fa-trash btn btn-link no-padding" onClick={handleDestroy}></button>
        )}
      </div>
      { showCommentForm && (
        <CommentForm
          commentableType="Space::Answer"
          commentableId={id}
          onCreate={addNewComment}
          onCancel={toggleCommentForm} />
      )}
      <CommentableList
        preview={preview}
        rerenderTrigger={rerenderTrigger}
        disableReplies={true}
        commentableId={id}
        commentableType={"Space::Answer"}
        hideForm={true} />
    </div>
  )
}

export default AnswerDetail;
