import { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import useBoostrapVariables from "utilities/BootstrapVariables";

const cssDefaults = useBoostrapVariables();


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    flexGrow: 1,
    padding: theme.spacing(1),
    "& h2": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      margin: 0,
      fontSize: 20,
      "& .arrows": {
        flex: 2,
        textAlign: "right",
        color: cssDefaults.brandLink,
        "& svg": {
          fontSize: 40,
        },
      },
    },
  },
  content: {
    display: "block",
    overflow: "hidden",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxHeight: 100,
    "&.active": {
      maxHeight: "none",
    },
    "& img": {
      maxWidth: "100%",
    },
  },
}));

const Carousel = (props) => {
  const CarouselSwipeableViews = props.autoPlay ? autoPlay(SwipeableViews) : SwipeableViews;
  const { slides, title, useDotNav } = props;
  let { interval } = props;
  const { length } = slides;

  if (!slides || length === 0) {
    return null;
  }

  if (!interval) {
    interval = 5000;
  }

  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = slides.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      let next = prevActiveStep + 1;
      if (next > maxSteps - 1) { next = 0; }
      return next;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      let prev = prevActiveStep - 1;
      if (prev < 0) { prev = maxSteps - 1; }
      return prev;
    });
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const renderDots = () => {
    const dots = [];
    for(let i=0; i < maxSteps; i++){
      dots.push(
        <div
          key={`dot-${i}`}
          style={
            {
              width: 10,
              height: 10,
              borderRadius: 20,
              margin: 5,
              display: 'inline-block',
              backgroundColor: i == activeStep ? "#ee7d76" : "pink"
            }
          }
          onClick={()=>{handleStepChange(i)}}>
          <div></div>
        </div>
      )
    }
    return dots;
  };

  return (
    <div className={classes.root}>
      <h2>
        {title}
        { !useDotNav && (
          <div className="arrows">
            <KeyboardArrowLeft
              fontSize="large"
              onClick={handleBack}
              style={{ cursor: "pointer" }}
            />
            <KeyboardArrowRight
              fontSize="large"
              onClick={handleNext}
              style={{ cursor: "pointer" }}
            />
          </div>
        )}
      </h2>
      <CarouselSwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        interval={interval}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {
          slides.map(
            (step, index) => (
              <div
                key={`${step}-${index}`}
                className={`${classes.content} ${activeStep === index ? "active" : ""}`}
              >
                { Math.abs(activeStep - index) <= 1 ? step : null }
              </div>
            ),
          )
        }
      </CarouselSwipeableViews>
      { useDotNav && (
        <div style={{display: "flex"}}>
            <div style={{flex: 2}}></div>
            { renderDots() }
            <div style={{flex: 2}}></div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
