import { createState, useState } from '@hookstate/core';

const MessagesState = createState({
  newMessages: null,
  newMessagesCount: 0,
  search: null,
  searchData: null,
  currentSearchScrollPosition: 0,
  startNewConversation: false,
  messagingAccountId: null,
  showConversationList: false,
  currentConversationListScrollPosition: 0,
  searchWithinConversation: null,
  currentMatch: 1,
  matchCount: 0,
  drawerMethods: null,
  context: null,
  conversationData: {
    messages: [],
    archived: false,
    starred: false,
    recipient: null,
    blocked: false,
    blockedSetting: false
  },
  conversationListData: {
    recipient: {},
    conversations: [],
    conversationsLoading: true
  },
  filter: 'inbox',
  messagesLoading: true,
  setMessagesDrawerData: ()=>{},
  selectConversation: ()=>{},
  showLoading: ()=>{},
  removeLoading: ()=>{},
  fetchConversationsListData: ()=>{},
  fetchMessagesDrawerData: ()=>{},
  hideLoadMore: true,
  showSidebarMobile: false,
  uploadProgress: null,
  downloadProgress: null,
  uploading: null
});

export default function UseMessagesState() {
    return useState(MessagesState)
}
