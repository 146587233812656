import { useEffect, useState } from "react";
import LoadingSpinner from "components/common/Loading";
import CircularImage from "components/common/CircularImage/index";
import QuestionListItem from "components/spaces/QuestionListItem";
import { spaceQuestionsAPIPath, spacePath } from "utilities/UrlHelpers";
import { questionsPageLimit } from "utilities/RailsHelpers";
import { assetUrl } from "utilities/RailsHelpers";
import UseSpaceState from "data/SpaceState";
import EmptySpaceImage from "./spaces_empty.svg";

const QuestionsList = (props) => {
  const spaceState = UseSpaceState();
  const { spaces, questions, currentSpaceId, currentFilter, currentOffset, showLoadMore } = spaceState.get();
  const space = spaces[currentSpaceId] || {};

  const [activeFilter, setActiveFilter] = useState(currentFilter);

  const { memberOnly } = props;

  useEffect(()=>{
    if(currentSpaceId == null && memberOnly){
      updateQuestionsData({currentSpaceId: "mine"});
    }
  }, []);

  const loadQuestions = async () => {
    const urlParams = [];
    if(!currentFilter){
      return;
    }

    if(currentSpaceId == "mine"){
      urlParams.push(`member_only=true`)
    } else if (currentSpaceId && currentSpaceId != "all"){
      urlParams.push(`space_id=${currentSpaceId}`);
    }
    if(currentOffset > 0){
      urlParams.push(`offset=${currentOffset}`)
    }
    urlParams.push(`query=${currentFilter}`)
    const res = await Fetch(`${spaceQuestionsAPIPath()}?${urlParams.join("&")}`);

    if(res.ok){
      const data = await res.json();

      if(activeFilter != currentFilter){
        spaceState.questions.set(data);
      } else {
        spaceState.questions.merge(data);
      }
      spaceState.showLoadMore.set((data.length > 0 && data.length % questionsPageLimit == 0));
      initAutoScroll();
    }
  };

  const updateQuestionsData = (newData) => {
    spaceState.merge(newData);
  }

  useEffect(()=>{
    loadQuestions();
    setActiveFilter(currentFilter);
  }, [currentSpaceId, currentFilter, currentOffset]);

  if(!questions){
    return (
      <div className="text-center">
        <LoadingSpinner />
      </div>
    )
  }

  if(questions.length == 0){
    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="text-center padding">
            <h2>Nothing to see here!</h2>
            <img src={EmptySpaceImage} />
          </div>
        </div>
      </div>
    );
  };

  const RenderQuestions = () => {
    const questionsRows = [];
    questions.forEach((question) => {
      questionsRows.push(
        <QuestionListItem key={`${question.id}-${currentFilter}`} question={question} spaces={spaces} />
      );
    });
    return questionsRows;
  }

  const openQuestion = (questionUrl) => {
    setTimeout(()=>{
      window.location = questionUrl;
    }, 200);
  }

  const loadMore = () => {
    updateQuestionsData({currentOffset: currentOffset + questionsPageLimit});
  };

  return (
    <div className="questions-list">
      <RenderQuestions />
      { showLoadMore &&
        <div className="text-center load-more-button">
          <button onClick={loadMore} className="btn btn-default auto-scroll-button">Load more</button>
        </div> }
    </div>
  )
}

export default QuestionsList;
