import { makeStyles } from "@material-ui/core/styles";
import useBoostrapVariables from "utilities/BootstrapVariables";
const cssDefaults = useBoostrapVariables();

const useStyles = makeStyles((theme) => ({
  root: {
    "& h2": {
      margin: `0 ${theme.spacing(2)}px`,
      fontSize: 18
    },
    "& .clear": {
      float: "none",
      clear: "both"
    },
    "& a.activity": {
      display: "flex",
      alignItems: "top",
      padding: theme.spacing(2),
      borderBottom: `1px ${cssDefaults.grayLighter} solid`,
      color: cssDefaults.textColor,
      "& > div": {
        marginTop: 0,
        fontSize: 14,
        "& .title": {
          color: cssDefaults.brandLink,
          fontWeight: "bold",
          fontSize: 16,
        },
      },
    },
    "& .activity-image": {
      width: 40,
      height: 40,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      marginRight: theme.spacing(2)
    },
    "& .view-all-link": {
      marginTop: theme.spacing(2),
      display: "inline-block"
    }
  }
}));

const ProfileActivitySummary = (props) => {
  const { post_activity_summary_data, show_activity_path } = props;
  const classes = useStyles();

  if(!post_activity_summary_data || Object.keys(post_activity_summary_data).length == 0){
    return null;
  }

  const ActivitySummaryItem = (props) => {
    const { itemData } = props;
    const title = itemData.share_title || itemData.post_description;
    const truncateText = (str, length) => {
      return (str && str.length > length) ? str.substring(0, length-3) + "..." : str;
    }
    return (
      <a href={itemData.cta_url}
        className="activity"
        onClick={(e) => handleActivityClick(e, itemData)}>
        { itemData.image_url != '' && (
          <div>
            <div className="activity-image" style={ { backgroundImage: `url(${itemData.image_url})` } }></div>
          </div>
        )}
        <div className="truncate-text">
          { title && (
            <div className="title truncate-text">
              {itemData.share_title || truncateText(itemData.post_description, 140)}
            </div>
          )}
          <div className="truncate-text">
            {itemData.actor} {itemData.action_text}
          </div>
        </div>
      </a>
    )
  }
  const handleActivityClick = (evt, activity_data) => {
    evt.preventDefault();
    test_track_event({
      event_name: 'Activity Summary - Click',
      activity_url: activity_data.cta_url
    })
    // Give event time to register then redirect to cta_url
    setTimeout(()=>{window.location = activity_data.cta_url}, 300);
  }

  const renderActivityList = () => {
    const list = [];
    let pointer = 1;
    Object.keys(post_activity_summary_data).forEach((activityId) => {
      const activityData = post_activity_summary_data[activityId];
      list.push(
        <div className="col-sm-6" key={activityId} >
          <ActivitySummaryItem itemData={activityData}/>
        </div>
      );
      if(pointer % 2 == 0){
        list.push(<div className="clear" key={`${activityId}-${pointer}`}></div>);
      }
      pointer++;
    });
    return list;
  };

  return (
    <div className={`row ${classes.root}`}>
      <h2>Activity</h2>
      {renderActivityList()}
      <div className="clear"></div>
      <div className="text-center">
        <a
          href={show_activity_path}
          onClick={(e) => handleActivityClick(e, {cta_url: show_activity_path})}
          className="text-center view-all-link">
          See All
        </a>
      </div>
    </div>
  )
};
export default ProfileActivitySummary;
