import { useState, useRef } from 'react';
import UseMessagesState from 'data/MessagesState';
import BlockButton from '../BlockButton';
import DownloadButton from './DownloadButton';
import PreviewButton from './PreviewButton';
import CurrentUserData from 'CurrentUserData';

export default function AttachmentItem(props){
  const messagesDrawerState = UseMessagesState();
  const { messageId } = props;
  const { conversationData } = messagesDrawerState.get();
  const { messages } = conversationData;
  const message = messages[messageId];
  const { id, authorId, authorUsername, attachmentUrl, attachmentDownloadUrl,  attachmentFilename } = message;

  if(!attachmentFilename){
    return null;
  }
  
  return (
    <>
      {
        CurrentUserData().id == authorId
        ?  <span key={`attachment-data-${id}`} className="message-attachment">
            You sent a file: <br/>
            <a href={attachmentDownloadUrl} target="_blank" >{ attachmentFilename }</a>
           </span>
        :
           <span key={`attachment-data-${id}`} className="message-attachment">
             @{ authorUsername } is sending you a file <br/>
             <strong>{ attachmentFilename }</strong>
             <hr />
             <PreviewButton message={message} />
             <DownloadButton message={message} />
             <BlockButton />
           </span>
       }
     </>
   );
};
