import { useEffect } from "react";
import ReactDOM from "react-dom";
import AskQuestionModal from "components/spaces/AskQuestionModal";
import CircularImage from "components/common/CircularImage/index";
import CurrentUserData from "components/common/CurrentUserData";
import useSharedSpacesStyles from "./SharedStyles";
import useBoostrapVariables from "utilities/BootstrapVariables";
import UseSpaceState from "data/SpaceState";
import { spacesAPIPath } from "utilities/UrlHelpers";
const cssDefaults = useBoostrapVariables();

const AskQuestionOpener = (props) => {
  const { spaceSlug, skipWhiteBlock = false, type = "button", querySpaces = false } = props;
  const spaceState = UseSpaceState();
  const classes = useSharedSpacesStyles();
  const loggedIn = CurrentUserData().id ? true : false;
  const userData = CurrentUserData();

  const handleClick = () => {
    if(!loggedIn){
      return window.triggerSignupWall('ask-question');
    }

    openModal();
  };

  const loadSpaces = async () => {
    if(!querySpaces || spaceState.get().spaces.length > 0){
      return;
    }

    const res = await Fetch(spacesAPIPath());

    if(res.ok){
      const data = await res.json();
      updateSpaceData({spaces: data})
    }
  };

  const updateSpaceData = (newData) => {
    spaceState.merge(newData);
  }

  const openModal = () => {
    let elm = null;
    if($("#ask-question-modal").length > 0){
      return;
    }else{
      elm = $(`<div id="ask-question-modal" class="modal fade mobile-fullscreen-modal"></div>`);
    }

    const onClose = function(){
      elm.modal("hide");
    };

    const onShow = function(){
      elm.modal("show");
    };

    $('body').append(elm);

    elm.on("hidden.bs.modal", function(){
      elm.remove();
    });

    ReactDOM.render(
      <AskQuestionModal onClose={onClose} onShow={onShow} defaultSpaceSlug={spaceSlug} />,
      elm[0]
    );
  };

  const RenderOpener = () => {
    switch(type) {
      case "button":
        return (
          <button className={`${classes.askQuestionOpener} type-button btn btn-default`} onClick={handleClick}>
            Ask a question
          </button>
        );
      case "text":
      default:
        return <TextOpener />
    }
  }

  const TextOpener = () => {
    return(
      <div
        onClick={handleClick}
        className={`${classes.askQuestionOpener} type-text ${!skipWhiteBlock && "white-content-block"}`}>
        { loggedIn &&
          <div className="avatar-row">
            <CircularImage src={userData.avatar} />
            <span className="question-text">Have a question you'd like to ask the community?</span>
          </div>
        }
        <div>
          <button className="btn btn-primary pull-right ">Ask a Question</button>
          <span className="opener-text">Type your question...</span>
        </div>
      </div>
    );
  };

  useEffect(()=>{
    loadSpaces();
  },[]);

  if(userData.messaging_blocked){
    return null;
  }

  return (
    <RenderOpener />
  )
}

export default AskQuestionOpener;
