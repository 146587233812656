import { useState } from 'react';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  actions: { 
    fontSize: 12,
    padding: theme.spacing(2)
  }
}));

export default function ConfirmDialog(props) {
  const classes = useStyles();
  const confirmData = {
    disablePortal: props.disablePortal == undefined ? true : props.disablePortal,
    confirmButtonText: props.confirmButtonText || 'Ok',
    buttonContent: props.buttonContent || 'Open',
    buttonClasses: props.buttonClasses || '',
    confirmTitle: props.confirmTitle || 'Are you sure?',
    confirmContent: props.confirmContent || 'Are you sure you want to do that?',
    onConfirm: props.onConfirm || (()=>{})
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    confirmData.onConfirm();
    handleClose();
  };
  return (
    <>
      <a className={confirmData.buttonClasses} style={{cursor: "pointer"}} onClick={handleClickOpen}>
        {confirmData.buttonContent}
      </a>
      <Dialog
        open={open}
        onClose={handleClose}
        disablePortal={confirmData.disablePortal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{confirmData.confirmTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmData.confirmContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{root: classes.actions }}>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleOk} autoFocus variant="contained">
            {confirmData.confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
