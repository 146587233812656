const ResponseRateAlert = () => {
  return(
    <>
      <div className="alert alert-warning">
        <p className="text-center lead">
          This campaign currently has a very low response rate
        </p>

        <p>
        This campaign currently has a low response rate to applications. This
        may be due to a large number of applications or delays from the
        campaign manager in responding. You can continue with your application
        or check back at a later time when your application is more likely to be
        reviewed.
        </p>
      </div>
    </>
  );
};


export default ResponseRateAlert;
