const FileListToggle = (props) => {
  const { state, toggleCallback } = props;
  const { note, showFileTray } = state;

  if (note.attachments.length > 0) {
    return(
      <a href="#"
        className="toggle-show-files pull-right" 
        onClick={toggleCallback}>
        <i className={`fas ${showFileTray ? 'fa-angle-down' : 'fa-angle-up'}`}></i>
      </a>);
  } else  {
    return '';
  }
};

export default FileListToggle;
