import React, { useState, useEffect } from "react";
import JSONHeaders from "utilities/JSONHeaders";
import CommentForm from "./CommentForm";
import CommentsList from "./CommentsList";

import useBoostrapVariables from "utilities/BootstrapVariables";
import customBreakpointsTheme from "utilities/CustomBreakpointsTheme";
import { getCommentsAPIPath } from "utilities/UrlHelpers";
const cssDefaults = useBoostrapVariables();
import { makeStyles } from "@material-ui/core/styles";

const useSharedSpacesStyles = makeStyles((theme) => ({
  genericCommentList: {
  }
}));

const CommentableList = (props) => {
  const { commentableId, commentableType, hideForm, disableReplies, rerenderTrigger, preview } = props;
  const [previewState, setPreviewState] = useState(preview);
  const classes = useSharedSpacesStyles();
  const [commentsState, setCommentsState] = useState([]);

  const addNewComment = (comment) => {
    getComments();
    setPreviewState(false);
  };

  const getComments = async () => {
    const resp = await Fetch(getCommentsAPIPath(commentableId, commentableType), {
      ...JSONHeaders,
      method: "GET"
    });
    const data = await resp.json();
    setCommentsState(data)
  };

  useEffect(()=>{
    getComments();
    setTimeout(()=>{checkForDeletedComment();}, 1000);
  },[rerenderTrigger]);

  return (
    <div className={classes.genericCommentList}>
      { !hideForm && (
        <CommentForm
          disableAutoFocus={true}
          commentableType={commentableType}
          commentableId={commentableId}
          onCreate={addNewComment} />
      )}
      { commentsState && commentsState.length > 0 && (
        <CommentsList preview={previewState} disableReplies={disableReplies} refreshComments={getComments} comments={commentsState} />
      )}
    </div>
  )
}

export default CommentableList;
