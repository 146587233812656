import { Component } from "react";
import ReactDOM from "react-dom";

export default class SocialMediaContactPanel extends Component {
  render() {
    const { networks } = this.props;
    return (
        <div className={'social-media-contact-card'}>
            <h4 className={"card-heading"}>
              Where to find {this.props.username}
            </h4>
          <ul className="list-unstyled networks">
              {networks.map((network) => <NetworkLink key={network.link_url} network={network}/>)}
          </ul>
        </div>
    )
  }
}

class NetworkLink extends Component {
    render() {
        const {
            action_name,
            count,
            display_name,
            image,
            icon,
            link_url,
            type,
        } = this.props.network;
        const networkIcon = icon
            ? <span className={`fa ${icon} contact-field-image`}></span>
            : <img src={image} className="contact-field-image" />;
        const tracking = action_name
            ? {
                className:"ga-action",
                "data-action-name": `${action_name} Link Click`,
                "data-category-name": "outclick"
              }
            : null;
        const link = <a target="blank" href={link_url} {...tracking} >{display_name}</a>;
        const followerCount = count
            ? <span className="badge badge-influence">{count}</span>
            : null;
        return (
            <li className="network" key={type}>
                {networkIcon}
                {link}
                {followerCount}
            </li>
        )
    }
}

document.addEventListener('DOMContentLoaded', () => {
  const nodes = document.getElementsByClassName('SocialMediaContactPanel')

  nodes.forEach((el, i) => {
    var data = JSON.parse(el.getAttribute('data-react-props'))

    ReactDOM.render(
      <SocialMediaContactPanel networks={data.networks} username={data.username} />,
      el.appendChild(document.createElement('div')),
    )
  })
})
