import { useEffect, useState } from "react";
import NoteOpener from "components/NoteOpener";
import AutoLink from 'components/common/AutoLink';
import Collapse from '@material-ui/core/Collapse';

const NoteSidebarWidget = (props) => {
  const {
    noteData,
    aboutId
  } = props || {};

  const [showDetails, setShowDetails] = useState(false)

  const {
    content, list_timestamp_text, attachments
  } = noteData || {};

  const attachments_length = attachments ? attachments.length : 0;

  return (
    <div className="notes-sidebar-widget">
      <div className="notes-widget-header collapsible-widget-header" onClick={ ()=>{ setShowDetails(!showDetails); } }>
        <h2>
          Notes
        </h2>
        <NoteOpener selectedAboutId={aboutId}
                    classes="fa fa-pencil"
                    openEditing={content && content != '' ? false : true}
                    editContent={content || ''}>
        </NoteOpener>
        <span className={`fa padding-left ${showDetails ? "fa-chevron-down" : "fa-chevron-left"}`}></span>
      </div>
      <Collapse in={showDetails}>
        <small>{list_timestamp_text}</small>
        <div className="widget-content">
          {
            content
              ? <AutoLink text={content} />
              : "No notes yet, add one now"
          }
        </div>
        <div className="widget-attachments">
          <h3>Attachments</h3>
          <NoteOpener selectedAboutId={aboutId}
                      classes="attachments-count"
                      iconClasses=" "
                      containerClasses="link-button"
                      buttonText={`(${attachments_length})`}
                      openEditing={content && content != '' ? false : true}
                      editContent={content}
                      showFileTray={true} />
        </div>
      </Collapse>
    </div>
  );
}

export default NoteSidebarWidget;
