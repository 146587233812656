import { useEffect, useState } from "react";
import routes from 'UrlHelpers';
import JSONHeaders from "JSONHeaders";
import CurrentUserData from "CurrentUserData";
import getConsumer from 'consumer';
import 'InfluenceFontAwesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './ChatOpener.scss';
import { Link, Icon, Badge } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 20,
    color: "#387f9b",
    position: "relative",
    display: "inline-block",
    marginTop: 3,
    "& .unseen-count": {
      position: "absolute",
      bottom: 6,
      right: 0,
      "& .MuiBadge-badge": {
        width: 15,
        height: 15,
        minWidth: 15,
        backgroundColor: "#ee7d76"
      }
    }
  }
}));

export default function ChatOpener(props) {
  const classes = useStyles();
  const consumer = getConsumer();
  const user = CurrentUserData();
  const { displayChatOpener, unseenChatMentionsCount, unseenChatMessagesCount } = user;
  const [unseenCount, setUnseenCount] = useState(unseenChatMessagesCount)
  const [unseenMentionsCount, setUnseenMentionsCount] = useState(unseenChatMentionsCount)

  if(!displayChatOpener){ return null; }

  const subscribeToUserChatCountChannel = () => {
    let sub = consumer.subscriptions.create(
      {channel: 'Chat::AccountChannel'},
      {
        received(data) {
          setUnseenCount(data.unseenMessages);
          setUnseenMentionsCount(data.unseenMentions);
        }
      }
    );

    return () => { sub.unsubscribe() };
  };

  const resetCount = async () => {
    await Fetch(routes.APIPostChatResetUserUnseenCountPath(), {
      ...JSONHeaders,
      method: "POST"
    });
  };

  useEffect(subscribeToUserChatCountChannel, []);

  if (props.badgeOnly) {
    if (unseenCount == 0) {
      return null;
    } else {
      return (
        <>
          <div onClick={resetCount} className="visible-xs unseen-notifications-count">
            {
              unseenMentionsCount > 0 ? unseenMentionsCount : ""
            }
          </div>

          <Badge
          className="unseen-count hidden-xs"
          onClick={resetCount}
          color="secondary"
          badgeContent={unseenMentionsCount > 0 ? unseenMentionsCount : ""}
          invisible={false}/>
        </>
      )
    }

  } else {
    return (
      <Link
        href="/go/communities"
        target="_blank"
        onClick={resetCount}
        className={`${classes.root} chat-opener`}>
        <FontAwesomeIcon icon='comments' />
        {unseenCount > 0 && (
          <Badge
            className="unseen-count"
            color="secondary"
            badgeContent={unseenMentionsCount > 0 ? unseenMentionsCount : ""}
            invisible={false}/>
        )}
      </Link>
    );
  }
}
