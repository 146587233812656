import { useEffect } from "react";
import UseMessagesState from 'data/MessagesState';
import { TextField } from "@material-ui/core";
const ENTER_KEY = 13

const SearchWithinConversationBox = (props) => {
  const messagesDrawerState = UseMessagesState();

  const {
    searchWithinConversation,
    setMessagesDrawerData,
    conversationData,
    matchCount
  } = messagesDrawerState.get();

  const {
    messages
  } = conversationData;

  const setSearchWithinConversation = (search) => {
    let allMessagesOutput = '';
    if(messages && Object.keys(messages).length > 0){
      messages.map((message) => {
        allMessagesOutput += message.body;
      });
    }
    const regex = new RegExp(search, 'gi')
    const matches = allMessagesOutput.match(regex);
    setMessagesDrawerData({searchWithinConversation: search, matchCount: matches ? matches.length : 0});
  }

  const handleEnter = (e) => {
    if (e.keyCode === ENTER_KEY) {
      e.preventDefault();
      e.stopPropagation();
      setMessagesDrawerData({showSidebarMobile: false});
    }
  }

  useEffect(()=>{
    if(!matchCount){
      setSearchWithinConversation(searchWithinConversation);
    }
  },[searchWithinConversation])

  return (
    <div className="current-search-within-conversation-box">
      <form action="#">
        <TextField
          name="search-within-conversation"
          value={searchWithinConversation || ''}
          placeholder="Search in conversation"
          onKeyDown={handleEnter}
          onChange={(evnt) => { setSearchWithinConversation(evnt.target.value) }}/>
        <i className="fa fa-search"></i>
      </form>
    </div>
  )
}

export default SearchWithinConversationBox;
