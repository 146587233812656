import UseMessagesState from 'data/MessagesState';

const CurrentFilter = (props) => {
  const messagesDrawerState = UseMessagesState();

  const {
    setMessagesDrawerData,
    fetchConversationsListData,
    filter
  } = messagesDrawerState.get();

  const clearFilter = () => {
    setMessagesDrawerData({filter: 'inbox'}, ()=>{fetchConversationsListData()});
  }

  return (
    <div className="current-filter">
      <p>
        Filtered by <strong>{filter}</strong>
      </p>
      <p className="clear-filter" onClick={clearFilter}>
        X
      </p>
    </div>
  )
}

export default CurrentFilter;
