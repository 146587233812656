import { makeStyles } from "@material-ui/core/styles";
import useBoostrapVariables from "utilities/BootstrapVariables";
import CircularImage from "components/common/CircularImage/index";
import CurrentUserData from "components/common/CurrentUserData";
const cssDefaults = useBoostrapVariables();

const useStyles = makeStyles((theme) => ({
  usernameHeadline: {
    display: "flex",
    alignItems: "center",
    "& .account-username": {
      "& a": {
        color: cssDefaults.brandLink
      }
    },
    "& .headline": {
      color: "#969696",
      fontSize: 12
    },
    "& img.img-circle": {
      width: 32,
      height: 32,
      marginRight: theme.spacing(1)
    },
  }
}));

const AvatarUsername = (props) => {
  const { account, extraText, hideHeadline, hideUsername, usernameLinebreak, extraLinkText, extraLinkUrl } = props;
  const classes = useStyles();

  const Username = (props) => {
    const { insertLinebreak } = props;

    if(hideUsername){ return null }

    return (
      <>
        { CurrentUserData().id == account.id
            ? "You"
            : <a
                className="profile-modal-opener"
                data-profile-modal-username={account.username}
                href={account.profileUrl}>
                {account.fullName}{insertLinebreak ? <br/> : " "}@{account.username}
              </a> }
      </>
    )
  };

  return (
    <div className={`${classes.usernameHeadline} profile-modal-opener`} data-profile-modal-username={account.username}>
      <CircularImage src={account.profilePicture} />
      <div className="username-headline">
        <div className="account-username">
          <Username insertLinebreak={usernameLinebreak} />
          { extraText && ` ${extraText} ` }
          { extraLinkText && extraLinkUrl && (
            <a href={extraLinkUrl}>{extraLinkText}</a>
          )}
        </div>
        { account.headline && !hideHeadline && (
          <div className="headline"><small>{account.headline}</small></div>
        )}
      </div>
    </div>
  );
};

export default AvatarUsername;
