import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

export default function InfiniteScroll(props){

  const { scrollable, button } = props;

  if (!scrollable || !button) {
    return null
  }

  const handleScroll = (event) => {
    if(scrollable.scrollY >= button.offsetTop - ($(window).height() * 2)){
      button.click();
    }
  };

  const cleanupScroll = () => {
    scrollable.removeEventListener('scroll', handleScroll);
  };

  useEffect(()=>{
    scrollable.addEventListener('scroll', handleScroll);

    return cleanupScroll;
  },[]);

  return null;
}

window.initInfiniteScroll = (element) => {
  const div = $("<div></div>")
  const scrollContainer = element.data("scroll-container") ? $(element.data("scroll-container")) : $(window);
  $('body').append(div);

  ReactDOM.render((
      <InfiniteScroll scrollable={scrollContainer[0]} button={element[0]} />
    ),
    div[0]
  )
};

window.initAutoScroll = () => {
  $(".auto-scroll-button").each(function(){
    window.initInfiniteScroll($(this));
  });
};

$(function(){
  initAutoScroll();
})
