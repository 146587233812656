import { goSelectPath, goProPath } from "utilities/UrlHelpers";

const SelectProLabels = (props) => {
  const { isSelect, isPro, styles } = props;

  if (isSelect) {
    return (
      <a
        className="label label-warning profile-top-badge"
        style={styles}
        href={goSelectPath()}
      >
        SELECT
      </a>
    );
  } if (isPro) {
    return (
      <a
        className="label label-info profile-top-badge"
        style={styles}
        href={goProPath()}
      >
        PRO
      </a>
    );
  }
  return null;
};

export default SelectProLabels;
