import { useState, useEffect } from "react";
import CurrentUserData from "components/common/CurrentUserData";
import Rules from "components/campaign_applications/Rules";
import ResponseRateAlert from "components/campaign_applications/ResponseRateAlert";

const CampaignApplicationForm = (props) => {
  let remaining = 8;
  let campaign = {title: "Hello", lowResponseRate: false};

  const handleSubmit = () => {
  };

  useEffect(() => {
    
  });

  return (
    <>
      <p>You have {remaining} applications remaining this week</p>
      <h2>{campaign.title}</h2>
      {
        campaign.lowResponseRate ?
          <ResponseRateAlert/> :
          ""
      }
      <p>
      <strong>
      Write a message about why you think you'd be
      a great fit for this campaign.
      </strong>
      </p>

      <textarea
        name="campaign_application[application_text]"
        className="form-control"
        required={true}
        rows={5} />

      <input type="checkbox" name="share" value="1" checked="checked" id="share_to_feed" />
      &nbsp;
      <label for="share_to_feed">Share that you applied to this campaign to your feed?</label>
      
      <Rules/>

      <div className="row">
        <div className="col-xs-6">
        </div>
        <div className="col-xs-6">
          <p className="text-right">
          <input
            type="submit"
            onClick={handleSubmit}
            className="btn btn-primary"
            value="Submit Application"/>
          </p>
        </div>
      </div>
    </>
  );
};

export default CampaignApplicationForm;
