import { useEffect } from "react";
import ReactDOM from "react-dom";
import CampaignApplicationForm from "components/campaign_applications/CampaignApplicationForm";
import EventTracking from "components/common/EventTracking";

const CampaignApplicationButton = (props) => {
  const {
    applicationCampaignId,
    buttonText,
    wrapperClasses
  } = props;

  const openModal = () => {
    if ($('#campaign-application-modal').length > 0) {
      return;
    }

    EventTracking("Clicked Apply to Campaign Button", {...props});

    let elm = $('<div></div>');
    const onClose = function() {
      $('#campaign-application-modal').modal('hide');
      setTimeout(() => {$(elm).remove()}, 1000);
    };

    $('body').append(elm);

    ReactDOM.render(
      <div id="campaign-application-modal" className="full-screen-xs modal fade modal-thicker-padding">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-close-button" onClick={() => {onClose()}}>&times;</div>
            <div className="modal-body">
              <CampaignApplicationForm
                applicationCampaignId={props.applicationCampaignId} />
            </div>
          </div>
        </div>
      </div>,
      elm[0]
    )

    $('#campaign-application-modal').modal('show');

    return false;
  }
  
  return (
    <a href="#" className={`btn btn-primary ${wrapperClasses}`} onClick={()=>{openModal()}}>
      {buttonText || 'Apply to Campaign'}
    </a>
  )
}

export default CampaignApplicationButton; 
