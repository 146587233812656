import LoadingSpinner from 'components/common/Loading';
import SuggestedFollow from 'components/follows/SuggestedFollow';
import { trendingFollowsPath } from 'utilities/UrlHelpers';

import 'css/SuggestedFollows';

const TrendingFollowsPage = (props) => {
  const { trendingFollows } = props;
  if(!trendingFollows || trendingFollows.length === 0){ return; }

  const TrendingFollows = () => {
    const followsOutput = [];
    trendingFollows.forEach((account) => {
      if(account == null){
        return;
      }
      followsOutput.push(
        <div className="row" key={`trending-follow-${account.id}`}>
          <div className="col-xs-12">
            <SuggestedFollow
              account={account}
              baseFollowClass="btn btn-lg"
              followClass="btn-primary"
              followedClass="btn-primary btn-outline"
              showLocation={true}
            />
          </div>
        </div>
      );
    });
    return followsOutput;
  };

  return (
    <div id="trending-follows-container">
      <TrendingFollows />
    </div>
  );
}

export default TrendingFollowsPage;
