import { useState } from "react";
import CurrentUserData from "components/common/CurrentUserData";
import PinToFeedButton from 'components/common/PinToFeedButton';
import SuggestToFeedButton from 'components/common/SuggestToFeedButton';
import SendMessageButton from "components/messages/SendMessageButton";
import FollowButton from "components/FollowButton";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ReportError from "components/common/ReportError";


const Dropdown = (props) => {
  try {
    const {
      editUrl,
      viewUrl,
      activityId,
      followAccountId
    } = props;
    const account = CurrentUserData();
    const { is_admin } = account;

    const [menuElement, setMenuElement] = useState(null);

    const handleMenuClick = event => {
      setMenuElement(event.currentTarget);
    };

    const handleCloseMenu = () => {
      setMenuElement(null);
    };

    const handleEditClick = () => {
      handleCloseMenu();
      window.location = editUrl;
    };

    const handleViewClick = () => {
      handleCloseMenu();
      window.location = viewUrl;
    };

    const handleAddClick = async () => {
      const response = await Fetch(addUrl, {method: "POST"});
      setSuggested(true);
      handleCloseMenu();
    };

    if(!account.id){ return null; }

    return (
      <div className="space-dropdown">
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
        >
          <MoreVertIcon style={{ fontSize: 20 }}/>
        </IconButton>
        <Menu
          anchorEl={menuElement}
          open={Boolean(menuElement)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleViewClick} key="view">
            <a href={viewUrl} className="btn btn-link btn-text text-left btn-block">View</a>
          </MenuItem>
          { followAccountId != CurrentUserData().id  && [
            <MenuItem onClick={handleCloseMenu} key="follow">
              <FollowButton source="FollowButton:Space:Dropdown" followedId={followAccountId} baseClass="btn btn-link btn-text text-left btn-block " />
            </MenuItem>,
            <MenuItem onClick={handleCloseMenu} key="message">
              <SendMessageButton messagingAccountId={followAccountId} wrapperClasses="btn btn-link btn-text text-left btn-block" />
            </MenuItem>
          ]}
          { is_admin && (
            [
              <MenuItem key="edit" onClick={handleEditClick}>
                <a href={editUrl} className="btn btn-link btn-text">Edit in Rails Admin</a>
              </MenuItem>,
              <MenuItem key="add">
                <SuggestToFeedButton activityId={activityId} />
              </MenuItem>,
              <MenuItem key="pin">
                <PinToFeedButton activityId={activityId} />
              </MenuItem>
            ]
          )}
        </Menu>
      </div>
    )
  } catch(err) {
    console.log("Error rendering dropdown", err);
    setTimeout(()=>{
      ReportError({message: `Error rendering dropdown`})
    }, 100);
    return null;
  }
};

export default Dropdown;
