import { Component } from "react";
import NotesDrawerList from "components/notes/DrawerList";
import Drawer from "components/Drawer";
import LoadingSpinner from "components/common/Loading";
import NoteShow from "components/notes/Show";
import NoteAccountSearch from "components/notes/AccountSearch";
import AccountSidebarWidget from "containers/AccountSidebarWidget";
import { influencersNotesPath } from "utilities/UrlHelpers";
import { notesListPageLimit } from "utilities/RailsHelpers";

export default class NotesDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedNoteId: props.selectedNoteId,
      selectedAboutId: props.selectedAboutId,
      selectedNote: null,
      noteListData: {},
      sortValues: [['Last Edited', 'edited'], ['Last Viewed', 'viewed'], ['Username', 'username']],
      selectedSort: null,
      defaultSort: 'edited',
      listScrolled: false,
      listLoading: false,
      hideLoadMore: true,
      pageLimit: notesListPageLimit,
      editingNote: props.openEditing || false,
      initShowFileTray: props.showFileTray || false,
      deletingNote: false,
      editNoteContent: null,
      searchAccount: null,
      showAddNoteMobile: false,
      resetDrawer: this.resetDrawer,
      drawerMethods: props.drawerMethods,
      changeNotesSort: this.changeNotesSort,
      setNotesData: this.setNotesData,
      showLoading: this.showLoading,
      removeLoading: this.removeLoading
    };
  };

  componentDidMount = () => {
    this.fetchInitialNotesData();
  };

  resetDrawer = () => {
    const { state, setNotesData } = this;
    const { drawerMethods } = state;
    const { setShowBack, resetDrawerTitle, resetCloseDrawer } = drawerMethods;

    setNotesData({
      selectedNoteId: null,
      selectedAboutId: null,
      showAddNoteMobile: false,
      editingNote: false
    });
    setShowBack(false);
    resetDrawerTitle();
    resetCloseDrawer();
  };

  showLoading = (loader) => {
    $('body').addClass('ajax');
    let notesDataUpdate = {}
    notesDataUpdate[`${loader}Loading`] = true
    this.setNotesData(notesDataUpdate);
  };

  removeLoading = (loader) => {
    $('body').removeClass('ajax');
    let notesDataUpdate = {}
    notesDataUpdate[`${loader}Loading`] = false
    this.setNotesData(notesDataUpdate);
  };

  fetchInitialNotesData = async () => {
    this.showLoading('list');
    Fetch(influencersNotesPath())
      .then(response => response.json())
      .then(data => {
        this.setNotesData({
          noteListData:  data
        });
        if(Object.keys(data).length == this.state.pageLimit){
          this.setNotesData({
            hideLoadMore: false
          });
        }else{
          this.setNotesData({
            hideLoadMore: true
          });
        }
        this.removeLoading('list');
      });
  };

  changeNotesSort = async (sort) => {
    this.setNotesData({
      selectedSort:  sort
    });
    this.showLoading('list');
    Fetch(influencersNotesPath(sort))
      .then(response => response.json())
      .then(data => {
        this.setNotesData({
          noteListData:  data
        });
        if(Object.keys(data).length == this.state.pageLimit){
          this.setNotesData({
            hideLoadMore: false
          });
        }else{
          this.setNotesData({
            hideLoadMore: true
          });
        }
        this.removeLoading('list');
      });
  }

  setNotesData = (notesData) => {
    this.setState(notesData)
  };

  render() {
    const { state } = this;
    const {selectedNoteId, selectedAboutId, noteListData, sortValues, selectedSort, showAddNoteMobile, initShowFileTray} = state;

    return (
      <div className="container-fluid maxed-height">
        <div className="row max-height-excluding-header">
          <div className="visible-xs col-xs-12 maxed-height">
            {
              selectedAboutId
                ? <NoteShow notesDrawerData={state} mobile={true} initShowFileTray={initShowFileTray} />
                : !showAddNoteMobile
                  ? <NotesDrawerList notesDrawerData={state} mobile={true}/>
                  : <NoteAccountSearch notesDrawerData={state} mobile={true} />
            }

          </div>
          <div className="hidden-xs col-sm-3 maxed-height note-list-column no-padding">
            <NotesDrawerList notesDrawerData={state}/>
          </div>
          {
            selectedAboutId
              ? <>
                  <div className="hidden-xs col-sm-6 maxed-height note-show-column">
                    <NoteShow notesDrawerData={state} initShowFileTray={initShowFileTray} />
                  </div>
                  <div className="hidden-xs col-sm-3 maxed-height account-sidebar-column">
                    <AccountSidebarWidget accountId={selectedAboutId}
                                          showNoteButton={false}
                                          showNoteWidget={false} />
                  </div>
                </>
              : <div className="hidden-xs col-sm-9 maxed-height">
                  <NoteAccountSearch notesDrawerData={state} />
                </div>
          }
        </div>
      </div>
    )
  }
}
