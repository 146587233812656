import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { voteAPIPath } from "utilities/UrlHelpers";
import CurrentUserData from "components/common/CurrentUserData";

const useUpvoteStyles = makeStyles((theme) => ({
  upvoteButton: {
    marginRight: theme.spacing(1),
    backgroundColor: "#F1F5F7",
    border: "0px none",
    "&.voted": {
      backgroundColor: "#387F9B",
      color: "#F1F5F7",
    },
    "& .fa-arrow-up": {
      fontSize: 18,
      marginRight: theme.spacing(1)
    }
  },
}));

const UpvoteButton = (props) => {
  const { defaultCount, defaultVoted, voteableId, voteableType, voteCallback } = props;
  const [voteCount, setVoteCount] = useState(defaultCount);
  const [voted, setVoted] = useState(defaultVoted);
  const classes = useUpvoteStyles();

  const handleClick = async () => {
    if(!CurrentUserData().id){
      return window.triggerSignupWall('upvote-button');
    }
    const newVoteCount = voted ? ( voteCount - 1 ) : ( voteCount + 1)
    const resp = await Fetch(voteAPIPath(), {
      method: voted ? "delete" : "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        voteable_id: voteableId,
        voteable_type: voteableType
      })
    });

    if(resp.ok){
      setVoteCount(newVoteCount);
      setVoted(!voted);
    }
  };

  return (
    <button className={`${classes.upvoteButton} ${voted && "voted"} upvote-button btn btn-default`} onClick={handleClick}>
      <i className="fa fa-arrow-up"></i>&nbsp;
      { voteCount }
    </button>
  );
};

export default UpvoteButton;
