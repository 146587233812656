import { Component } from "react";
import Drawer from "components/Drawer";
import LoadingSpinner from "components/common/Loading";
import NotificationsList from "components/notifications/NotificationsList";
import { influencerNotificationsPath, influencerSetAllNotificationsReadPath } from 'utilities/UrlHelpers';
import { notificationsListPageLimit } from 'utilities/RailsHelpers';
import EventTracking from 'components/common/EventTracking';

export default class NotificationsDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      resetDrawer: this.resetDrawer,
      drawerMethods: props.drawerMethods || {closeDrawer: ()=>{}},
      setNotificationsData: this.setNotificationsData,
      fetchNotificationsData: this.fetchNotificationsData,
      notificationsLoading: true,
      errorLoading: false,
      showLoading: this.showLoading,
      removeLoading: this.removeLoading,
      hideClose: props.hideClose || false,
      header: props.header || "Notifications",
      showLoadMore: false,
      showViewAll: props.showViewAll || false
    };
  };

  componentDidMount = () => {
    EventTracking("Notification Drawer Show");
    this.fetchNotificationsData();
  };

  resetDrawer = () => {
    const { state } = this;
    const { drawerMethods } = state;
    const { setShowBack, resetDrawerTitle, resetCloseDrawer } = drawerMethods;

    setShowBack(false);
    resetDrawerTitle();
    resetCloseDrawer();
  };

  showLoading = (loader) => {
    document.getElementsByTagName('body')[0].classList.add("ajax");
    let notificationsDataUpdate = {}
    notificationsDataUpdate[`${loader}Loading`] = true
    this.setNotificationsData(notificationsDataUpdate);
  };

  removeLoading = (loader) => {
    document.getElementsByTagName('body')[0].classList.remove("ajax");
    let notificationsDataUpdate = {}
    notificationsDataUpdate[`${loader}Loading`] = false
    this.setNotificationsData(notificationsDataUpdate);
  };

  fetchNotificationsData = async () => {
    const {state} = this;
    const { notifications } = state;
    const last = notifications.length > 0 ? notifications[notifications.length-1].id : ''

    this.showLoading('notifications');
    let response = await Fetch(influencerNotificationsPath(last));
    if(!response.ok){
      this.setNotificationsData({errorLoading: true})
      this.removeLoading('notifications');
      return;
    }
    let data = await response.json();
    let newShowLoadMore = false;
    if(data.length == notificationsListPageLimit){
      newShowLoadMore = true
    }
    this.setNotificationsData({
      notifications:  [...notifications,...data],
      errorLoading: false,
      showLoadMore: newShowLoadMore,
      notificationsLoading: false
    });
  };

  setAllNotificationsRead = async (evnt) => {
    const { notifications } = this.state
    const response = await Fetch(influencerSetAllNotificationsReadPath(notifications[0].id), {method: "PUT"})
    const response_json = await response.json();
    this.setNotificationsData({
      notifications: response_json
    });
  }

  setNotificationsData = (notificationsData) => {
    this.setState(notificationsData)
  };

  render() {
    const { state, setAllNotificationsRead } = this;
    const { notificationsLoading, drawerMethods, notifications, hideClose, header } = state;

    return (
      <>
        <div className="notifications-header">
          { !hideClose && <i onClick={drawerMethods.closeDrawer} className="fas fa-times"></i> }
          <h2>{header}</h2>
          { notifications.length > 0 && <a onClick={setAllNotificationsRead}>Mark all as read</a> }
        </div>
        <div className="container-fluid maxed-height">
          <div className="row max-height-excluding-header">
            <div className="notifications maxed-height">
              {
                notificationsLoading
                  ? <div className="text-center"><LoadingSpinner /></div>
                  : <NotificationsList notificationsDrawerData={state} />
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}
