import { useEffect } from 'react';
import CurrentUserData from 'components/common/CurrentUserData';
import { currentEnvironment } from "utilities/RailsHelpers";


const EventTracking = (eventName, opts) => {
  const currentUser = CurrentUserData();
  const { id, is_admin } = currentUser;

  opts = opts || {};
  opts.appEnv = currentEnvironment;

  const enrichedOptions = {
    event: eventName,
    properties: opts,
    context: {
      url: window.location.toString(),
      title: document.title,
      userAgent: navigator.userAgent
    }
  };

  if(is_admin){
    console.log(`not tracking ${eventName} due to admin`, enrichedOptions);
    return;
  }
  if(!currentEnvironment == 'production'){
    // Pendo doesn't have a good way to handle environments
    enrichedOptions.event = `zz[${currentEnvironment}] ${eventName}`
  }

  const pendoTracking = window.pendoTrack || false;

  // fire pendo
  if(pendoTracking){
    pendoTracking([enrichedOptions]);
  }

  return null;
};

export default EventTracking;
