import { influencersNotesPath } from "utilities/UrlHelpers";

const NotesDrawerLoadMore = (props) => {
  const {
    noteListData,
    selectedSort,
    setNotesData,
    hideLoadMore,
    pageLimit
  } = props;
  
  if(hideLoadMore){
    return '';
  }

  const lastNote = noteListData[Object.keys(noteListData)[Object.keys(noteListData).length - 1]];

  const handleClick = async () => {
    let lastValue = '';
    switch (selectedSort){
      case 'username':
        lastValue = lastNote.about_username
        break;
      case 'viewed':
        lastValue = lastNote.viewed_at;
        break;
      case 'edited':
      default:
        lastValue = lastNote.edited_at;
        break;
    }

    const response = await Fetch(influencersNotesPath(selectedSort, lastValue))

    if(response.ok){
      const notesData = await response.json()
      setNotesData({
        noteListData: {...noteListData, ...notesData},
        hideLoadMore: (Object.keys(notesData).length < pageLimit)
      })
    }
  }

  return (
    <button className="notes-load-more btn btn-default"
            onClick={handleClick}>
      Load More
    </button>
  )
}

export default NotesDrawerLoadMore;
