import { useEffect } from "react";
import UseSpaceState from "data/SpaceState";
import { filteredSpacesPath } from "utilities/UrlHelpers";
import useSharedSpacesStyles from "./SharedStyles";

const QuestionsListFilters = (props) => {
  const spaceState = UseSpaceState();
  const { currentFilter, currentSpaceId } = spaceState.get();
  const classes = useSharedSpacesStyles();

  const spaceId = currentSpaceId ? currentSpaceId : "all";

  const setFilter = (filter) => {
    window.history.replaceState(null, null, filteredSpacesPath(spaceId, filter));
    spaceState.merge({currentFilter: filter, currentOffset: 0});
  };

  return (
    <div className={classes.questionsListFilters}>
      <a
        className={ currentFilter == "trending" ? "selected" : "" }
        onClick={ (e)=>{ e.preventDefault(); setFilter("trending") } }
        href={filteredSpacesPath(spaceId, "trending")}>
        Trending
      </a>
      <a
        className={ currentFilter == "newest" ? "selected" : "" }
        onClick={ (e)=>{ e.preventDefault(); setFilter("newest") } }
        href={filteredSpacesPath(spaceId, "newest")}>
        Newest
      </a>
      <a
        className={ currentFilter == "unanswered" ? "selected" : "" }
        onClick={ (e)=>{ e.preventDefault(); setFilter("unanswered") } }
        href={filteredSpacesPath(spaceId, "unanswered")}>
        Unanswered
      </a>
    </div>
  )
}

export default QuestionsListFilters;
