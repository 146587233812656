import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useBoostrapVariables from "utilities/BootstrapVariables";
import CurrentUserData from "components/common/CurrentUserData";
import Panels from "components/nav/Panels";
import customBreakpointsTheme from "utilities/CustomBreakpointsTheme";
import EventTracking from "components/common/EventTracking";
import ReportError from "components/common/ReportError";
const cssDefaults = useBoostrapVariables();

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    display: "flex",
    minHeight: "230px",
    flexDirection: "row",
    alignItems: "stretch",
    [customBreakpointsTheme.breakpoints.down('xs')]: {
      display: "block"
    }
  },
  panel: {
    textAlign: "center",
    color: cssDefaults.textColor,
    "&:hover": {
      background: "#f8f8f8",
      textDecoration: "none",
      color: cssDefaults.brandSecondary,
    },
    "& h2": {
      fontSize: 20,
      fontWeight: "bold"
    },
    [customBreakpointsTheme.breakpoints.down('xs')]: {
      marginBottom: 15,
      textAlign: "left",
      "&:last-child": {
        marginBottom: 0
      }
    }
  },
  panelDescriptionContainer: {
    padding: 20,
    "&.panel-container-Content": {
      "& h2": {
        display: "none"
      },
      "& img": {
        marginBottom: "20px",
        height: "40px"
      }
    },
    [customBreakpointsTheme.breakpoints.down('xs')]: {
      display: "flex",
      alignItems: "top",
      "&.panel-container-Content": {
        display: "block"
      }
    }
  },
  panelDescription: {
    backgroundColor: "#f5f5f6",
    borderRadius: 6,
    padding: "15px",
    "& h2": {
      fontSize: 26,
      fontWeight: "bold",
    },
    [customBreakpointsTheme.breakpoints.down('xs')]: {
      marginBottom: 15,
      "& h2": {
        marginTop: 0
      },
      "&.panel-type-Content": {
        padding:15,
      },
    }
  },
  panelIcon: {
    "& img": {
      height: 35,
    },
    [customBreakpointsTheme.breakpoints.down('xs')]: {
      padding: "0 0 0 15px",
      textAlign: "center",
      "& img": {
        width: "30px",
      }
    }
  },
  linkContainer: {
    marginTop: 30,
    "& img":{
      height: "80px",
    },
    [customBreakpointsTheme.breakpoints.down('xs')]: {
      display: "flex",
      alignItems: "center"
    }
  }
}));

const GeneratePanels = (props) => {
  const { panels, currentPanelName, tapeClose } = props;
  const currentUser = CurrentUserData();
  const { account_type } = currentUser;
  const generatedPanels = [];

  if(!panels || panels.length == 0){
    return null;
  }

  panels.forEach((panel) => {
    if(!panel.props){
      let pnl = panel.title ? panel : panel[account_type];
      generatedPanels.push(<GenerateLink link={pnl} key={pnl.title} currentPanelName={currentPanelName} tapeClose={tapeClose} />)
    }else{
      // push react component generated straight in
      generatedPanels.push(panel);
    }
  });
  return generatedPanels;
}

const GenerateLink = (props) => {
  const { link, currentPanelName, tapeClose } = props;
  const { image, description, url, badge, title, action, eventName } = link;
  const currentUser = CurrentUserData();
  const { account_type } = currentUser;
  const classes = useStyles();

  const full_url = typeof(url) === 'function' ? url() : url;

  const trackClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    EventTracking(`Tape Nav Item Click`, {
      ...CurrentUserData(),
      item_name: eventName,
      tape_name: currentPanelName
    });

    if(action){
      action();
    }else{
      setTimeout(()=>{
        window.location = full_url;
      },100);
    }
    tapeClose();
  };

  return (
    <a
      key={title}
      onClick={(evt)=>{trackClick(evt)}}
      href={full_url}
      className={`col-xs-12 col-sm-5ths ${classes.panel}`}
      style={{cursor: "pointer"}}>
    
      {badge ? <span style={{position: 'absolute', top: "10px", right: "10px"}} className="label label-success">{badge}</span> : ""}  
    
      <div className={`row ${classes.linkContainer}`}>
        <div className="col-xs-3 col-sm-12">
          <img alt={`${title} Icon`} src={image} className="img-responsive-mobile" />
        </div>
        <div className="col-xs-9 col-sm-12">
          <h2>{title}</h2>
          {description}
        </div>
      </div>
    </a>
  );
}
const TapePanel = (props) => {
  const classes = useStyles();
  const currentUser = CurrentUserData();
  const { account_type } = currentUser;
  const { tapeClose } = props;
  const currentPanelName = props.currentPanel;
  const currentPanel = Panels()[currentPanelName];
  const currentPanelData = currentPanel.overview ? currentPanel : currentPanel[account_type];
  if(!currentPanelData){
    ReportError({message: `Error rendering TapePanel: missing panel for currentPanelName=${currentPanelName}, account_type=${account_type}`})
    return;
  }
  const { overview, panels, image } = currentPanelData;

  const trackNofilterClick = () => {
    EventTracking(`Tape Nav Item Click`, {
      ...CurrentUserData(),
      item_name: 'content-no-filter-icon',
      tape_name: 'Content'
    });
  }

  useEffect(()=>{
    EventTracking(`Tape Nav Open`, {
      ...currentUser,
      tape_name: currentPanelName
    });
    panels.forEach((panel) => {
      if(!panel.props){
        let pnl = panel.title ? panel : panel[account_type];
        EventTracking(`Tape Nav Item View`, {
          ...currentUser,
          item_name: pnl.eventName,
          tape_name: currentPanelName
        });
      }
    });
  }, [currentPanelName]);

  return (
    <div className="container-fluid">
      <div className={`row ${classes.panelContainer}`}>
        <div className={`col-xs-12 col-sm-5ths ${classes.panelDescription} panel-type-${currentPanelName}`}>
          <div className={`row ${classes.panelDescriptionContainer} panel-container-${currentPanelName}`}>
            { currentPanelName != "Content"
              ? (
                <>
                  <div className={`col-xs-2 col-sm-12 ${classes.panelIcon}`}>
                    <img alt={`${currentPanelName} Icon`} src={image} />
                  </div>
                  <div className="col-xs-10 col-sm-12">
                    <h2>
                      { currentPanelName != "Content" && currentPanelName }
                    </h2>
                    {overview}
                  </div>
                </>
              )
              : (
                <>
                 
                </>
              )
            }
          </div>
        </div>
        <GeneratePanels panels={panels} currentPanelName={currentPanelName} tapeClose={tapeClose} />
      </div>
    </div>
  );
};

export default TapePanel;
