import { useState } from "react";
import LoadingSpinner from "components/common/Loading";
import { sendMessagePath, influencerProfilePath } from "utilities/UrlHelpers";
import UseMessagesState from 'data/MessagesState';
import EventTracking from 'components/common/EventTracking';
import AttachmentOpener from './attachments/AttachmentOpener';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

const SendMessageForm = (props) => {
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [messageValue, setMessageValue] = useState('');
  const [showEmojiPicker, setShowEmoji] = useState(false);
  const messagesDrawerState = UseMessagesState();

  const {
    messagingAccountId,
    conversationData,
    setMessagesDrawerData,
    drawerMethods,
    showLoading,
    removeLoading,
    uploading,
    downloadProgress,
    uploadProgress
  } = messagesDrawerState.get();

  const {
    recipient,
    blocked
  } = conversationData;

  const sendMessageData = async () => {
    Fetch(
      sendMessagePath(messagingAccountId),
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({message: messageValue})
      }
    )
      .then(response => {
        if(!response.ok) throw response;
        return response.json();
      })
      .then(data => {
        messagesDrawerState.conversationData.messages.merge([data.message])
        setMessageValue('');
        setSendMessageLoading(false);
        EventTracking('Message Created')
      })
      .catch(error => {
        if(error.status == 429){
          // User needs to upgrade to pro
          error.json().then(data => {
            window.location = `${influencerProfilePath(recipient.username)}?trigger_paywall=${data.trigger_paywall}`
          })
        }else if(error.status == 403){
          error.json().then(data => {
            setSendMessageLoading(false);
            alert(data.error);
          });
        } else {
          setSendMessageLoading(false);
          alert("There was an unknown error, please try again.  If the problem persists, please contact us and let us know.");
        }
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSendMessageLoading(true);
    sendMessageData();
  }

  const handleResize = (event) => {
    // Set back to 5px first to compute out how tall the textarea is
    event.target.style.height = "5px";
    event.target.style.overflow = "hidden";

    // cap max resize to 300px
    if (event.target.scrollHeight > 300){
      event.target.style.height = "300px";
      event.target.style.overflow = "auto";
    }else{
      event.target.style.height = (event.target.scrollHeight)+"px";
    }
  };

  const handleMessageChange = (event) => {
    setMessageValue(event.target.value);
  };

  const percentUploaded = () => (
    parseInt(
      (
        (
          (parseInt(uploadProgress) || 0) +
          ((parseInt(downloadProgress) || 0) / 50)
        ) / 102
      ) * 100
    )
  );

  if(blocked){
    return(
      <div className="colfoot text-center">
        <strong>You can no longer send messages to this person.</strong>
      </div>
    );
  }

  const showEmojis = e => {
    setShowEmoji(!showEmojiPicker)
  }

  const addEmoji = e => {
    let sym = e.unified.split('-')
    let codesArray = []
    sym.forEach(el => codesArray.push('0x' + el))
    let emoji = String.fromCodePoint(...codesArray)
    setMessageValue(messageValue + emoji)
    setShowEmoji(false)
  }

  return (
    <div className="colfoot">
      { uploading && (
        <div>
          Sending attachment...
          <div className="progress margin-right">
            <div className="progress-bar" role="progressbar" aria-valuenow={percentUploaded()}
              aria-valuemin="0" aria-valuemax="100" style={{width: percentUploaded() + '%'}}>
              {percentUploaded()}%
            </div>
          </div>
        </div>
      )}
      {
        sendMessageLoading
          ? <div>
              <LoadingSpinner hideText={true} classes="pull-left"/>
              Sending your message...
            </div>
          : <form onSubmit={handleSubmit} acceptCharset="UTF-8" style={ uploading ? {display: "none"} : {}}>
              <AttachmentOpener />
              <p onClick={showEmojis} className="emojiIcon" >
                <i className="far fa-smile"></i>
              </p>
              <textarea name="body"
                        onKeyUp={handleResize}
                        onChange={handleMessageChange}
                        style={{resize: "none", height: "40px"}}
                        id="drawer-message-box"
                        required={true}
                        placeholder="Enter your message..."
                        value={messageValue}></textarea>
              <button name="button"
                        type="submit"
                        id="new-message"
                        className="btn btn-primary">Send
              </button>
            </form>
      }
      {
        showEmojiPicker && (
          <span className="emojiPicker">
            <Picker onSelect={addEmoji} title='Pick your emoji…' emoji='point_up' />
          </span>
        )
      }
    </div>
  )
}

export default SendMessageForm;
