import { createState, useState } from "@hookstate/core";

const TapeNavState = createState({
  visible: false,
  currentPanel: "community",
  hovering: false,
  latestNews: [],
  featuredNews: [],
  mobile: false
});

export default function UseTapeNavState(accessDirectly) {
  if (!accessDirectly) {
    return useState(TapeNavState);
  }
  return TapeNavState;
}
