import { makeStyles, useTheme } from "@material-ui/core/styles";
import Carousel from "components/common/Carousel";
import askQuestionPanelImage from "components/spaces/ask-question-sidebar-panel-1.png";
import answerQuestionPanelImage from "components/spaces/answer-question-sidebar-panel-1.png";
import commentPanelImage from "components/spaces/comment-sidebar-panel-1.png";
import useBoostrapVariables from "utilities/BootstrapVariables";

const cssDefaults = useBoostrapVariables();


const useStyles = makeStyles((theme) => ({
 slide: {
   textAlign: "center",
   "& h2": {
     display: "block",
     fontSize: 20,
     padding: 0,
     margin: "16px 0px 8px"
   },
   "& p": {
     fontSize: 14,
     lineHeight: 1.4
   }
 }
}));

const SidebarCarousel = (props) => {
  const styles = useStyles();
  const slides = [
    <div className={styles.slide} key="question-panel">
      <img src={askQuestionPanelImage} />
      <h2>Ask a question</h2>
      <p>Find the right answer by posting a question to over 200k creators and brands.</p>
    </div>,
    <div className={styles.slide} key="answer-panel">
      <img src={answerQuestionPanelImage} />
      <h2>Get answers</h2>
      <p>Find the best answer once the community responds. Let the conversartion begin!</p>
    </div>,
    <div className={styles.slide}  key="comment-panel">
      <img src={commentPanelImage} />
      <h2>Comment and upvote</h2>
      <p>Have an opinion on an answer? Leave a comment, or read other comments. Agree with an answer, upvote it!</p>
    </div>
  ];
  return (
    <Carousel
      title=""
      autoPlay={true}
      useDotNav={true}
      slides={slides}
    />
  );
};

export default SidebarCarousel;
