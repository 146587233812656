import { makeStyles } from "@material-ui/core/styles";
import useBoostrapVariables from "utilities/BootstrapVariables";
import customBreakpointsTheme from "utilities/CustomBreakpointsTheme";
const cssDefaults = useBoostrapVariables();

const useSharedModalStyles = makeStyles((theme) => ({
  previewModal: {
    position: 'absolute',
    width: "60%",
    backgroundColor: theme.palette.background.paper,
    top: "20%",
    left: "20%",
    maxHeight: "calc(100% - 20% - 15px)",
    overflow: "auto",
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    [customBreakpointsTheme.breakpoints.down('sm')]: {
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      maxHeight: "100%"
    },
    "& img": {
      maxWidth: "100%",
      margin: `${theme.spacing(2)}px 0`
    },
    '& .close-modal ': {
      float: 'right',
      '& button': {
        margin: 0,
        padding: 0
      }
    }
  },
  warningModal: {
    position: 'absolute',
    width: '40%',
    backgroundColor: theme.palette.background.paper,
    top: '20%',
    left: '30%',
    maxHeight: 'calc(100% - 20% - 15px)',
    overflow: 'auto',
    borderRadius: '6px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    [customBreakpointsTheme.breakpoints.down('sm')]: {
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      maxHeight: "100%"
    },
    '& img': {
      maxWidth: '100%'
    },
    '& .close-modal ': {
      float: 'right',
      '& button': {
        margin: 0,
        padding: 0
      }
    }
  },
}));

export default useSharedModalStyles;
