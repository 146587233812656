import { useState } from "react";
import { Box, Tooltip } from "@material-ui/core";
import CircularImage from "components/common/CircularImage/index";
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import routes from 'UrlHelpers';
import { DeleteFetch } from 'Fetch';
import ConfirmDialog from 'components/common/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
  tooltip: { 
    fontSize: 12
  }
}));

const AccountListsCard = (props) => {
  const {
    listData, account
  } = props;
  if(listData.length == 0){
    return '';
  }
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(false);
  const [deletedLists, setDeletedLists] = useState({});
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);


  const handleDelete = async (listId) => {
    const rs = await DeleteFetch(routes.APIDeleteListsRemoveUserPath(listId), {
      delete_id: account.id
    });
    if(rs.ok){
      const newData = {...deletedLists};
      newData[listId] = true
      setDeletedLists(newData);
    }
  };

  const renderListData = () => {
    let renderedListItems = [];
    listData.forEach(function (list) {
      if(deletedLists[list.id]){ return; }
      renderedListItems.push(
        <Box display="flex" mt={1} className={classes.listItem} key={list.id}>
            <a href={list.url} target="_BLANK">
              <CircularImage src={list.imageUrl} />
              {list.name}
            </a>
            <Box flexGrow={1} textAlign="right">
              <Tooltip classes={classes} arrow title={`Remove @${account.username} from list`}>
                <span>
                  <ConfirmDialog
                    disablePortal={false}
                    buttonContent=' '
                    buttonClasses="btn-link fa fa-trash-alt delete-button"
                    confirmTitle={<Box component="span" fontSize={18}>Are you sure?</Box>}
                    confirmContent={<Box component="span" fontSize={14}>
                      Are you sure you want to remove <strong>@{account.username}</strong> from <strong>{list.name}</strong>?
                    </Box>}
                    confirmButtonText="Remove"
                    onConfirm={() => { handleDelete(list.id) }} />
                </span>
              </Tooltip>
            </Box>
        </Box>
      );
    });
    return renderedListItems;
  }

  return(
    <div className='account-sidebar-lists'>
      <div className="collapsible-widget-header"  onClick={ ()=>{ setShowDetails(!showDetails); } }>
        <h2>Lists</h2>
        <span className={`fa ${showDetails ? "fa-chevron-down" : "fa-chevron-left"}`}></span>
      </div>
      <Collapse in={showDetails}>
        <Box mb={2}>
          { renderListData() }
        </Box>
      </Collapse>
    </div>
  )
}

export default AccountListsCard;
