import { Component } from "react";
import NoteContent from 'components/notes/Content';
import NoteForm from 'components/notes/Form';
import LoadingSpinner from 'components/common/Loading';
import ConfirmDialog from 'components/common/ConfirmDialog';
import NoteAttachments from 'components/notes/Attachments';
import { notePath } from 'utilities/UrlHelpers';

export default class NoteShow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      notesDrawerData: props.notesDrawerData,
      initShowFileTray: props.initShowFileTray,
      mobile: props.mobile
    };
    if(!props.mobile){
      this.state['loadingNote'] = true;
    }
  };
  componentDidMount = () => {
    this.fetchCurrentNoteData();
  };

  componentDidUpdate = (prevProps) => {
    const { state, props } = this;
    const { mobile } = state;

    const newDrawerData = props.notesDrawerData;
    const oldDrawerData = prevProps.notesDrawerData;
    if(newDrawerData != oldDrawerData){
      this.setState({notesDrawerData: newDrawerData}, () => {
        if(newDrawerData.refreshSelectedData ||
           newDrawerData.selectedNoteId != oldDrawerData.selectedNoteId){
          this.fetchCurrentNoteData();
        }
      });

    }
    if(props.mobile != prevProps.mobile){
      this.setState({mobile: props.mobile});
    }
  };

  fetchCurrentNoteData = async () => {
    const { state } = this;
    const { notesDrawerData, mobile } = state;
    const { selectedNoteId, setNotesData } = notesDrawerData;
    // Only fetch in regular view, selectedNote data will bubble down to mobile view.
    if(mobile){ return; }
    this.setState({loadingNote: true});
    const response = await Fetch(notePath(selectedNoteId));
    if(response.ok){
      const data = await response.json();
      this.setState({loadingNote: false})
      setNotesData({selectedNote: data, refreshSelectedData: false, editNoteContent: data.content});
    }
  }

  deleteNote = async () => {
    const { state } = this;
    const { notesDrawerData } = state;
    const { selectedNoteId, setNotesData } = notesDrawerData;
    setNotesData({
      editingNote: false,
      deletingNote: true
    });
    Fetch(notePath(selectedNoteId), { method: 'DELETE' }).then(() => { this.deleteNoteCallback() });
  };

  deleteNoteCallback = () => {
    const { state } = this;
    const { notesDrawerData } = state;
    const {
      noteListData,
      selectedNoteId,
      setNotesData,
      resetDrawer
    } = notesDrawerData;

    let newNoteListData = {...noteListData};
    delete(newNoteListData[selectedNoteId]);
    setNotesData({
      noteListData: newNoteListData,
      selectedNoteId: null,
      selectedAboutId: null,
      deletingNote: false
    });
    resetDrawer();
  };

  setNote = (note) => {
    setNotesData({selectedNote: note, refreshSelectedData: false});
  };

  deleteNoteClick = () => {
    this.deleteNote();
  };

  render() {
    const { state } = this;
    const { notesDrawerData, mobile, loadingNote, initShowFileTray } = state;
    const {
      setNotesData,
      selectedNoteId,
      selectedNote,
      editingNote,
      deletingNote
    } = notesDrawerData;

    const {
      about_profile_picture, about_id, about_username, about_name, content, list_timestamp_text
    } = selectedNote || {};

    if(loadingNote || !selectedNote){
      return <LoadingSpinner text={'Loading note...'} classes="note-show-loading" />
    } else {
      return (
        <div
          key={`show-${selectedNoteId}`}
          className={`note-show show-note-${selectedNoteId}`}
          >
          {
            !mobile
              ? <h2>Notes on { about_name }</h2>
              : ''
          }
          {
            editingNote
              ? <NoteForm notesDrawerData={notesDrawerData} />
              : <div className="note-content-container maxed-height">
                  <div className="note-content">
                    <NoteContent content={content} />
                  </div>
                  <div className="note-show-buttons">
                    {
                      deletingNote
                        ? <LoadingSpinner text={'Deleting note...'} />
                        : <>
                            <ConfirmDialog
                              buttonContent=' '
                              buttonClasses="delete-note fa fa-trash"
                              confirmContent="Are you sure you want to delete this note? This cannot be undone"
                              onConfirm={this.deleteNoteClick} />
                            <button className="edit-note btn btn-info"  onClick={()=>{setNotesData({editingNote: true})}}>
                              Edit Note
                            </button>
                          </>
                    }
                  </div>
                  <NoteAttachments note={selectedNote} setNote={this.setNote} showFileTray={initShowFileTray}/>
                </div>
          }
        </div>
      )
    }
  }
}
