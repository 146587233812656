import { makeStyles, createTheme } from '@material-ui/core/styles';
const baseTheme = createTheme({
  typography: {
    fontFamily: "'TT Norms', Helvetica, Arial, sans-serif"
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "0px 0px 30px rgba(239, 239, 239, 1)"
      },
      rounded: {
        borderRadius: "20px"
      }
    },
    MuiAccordion: {
      root: {
        backgroundColor: "transparent",
        boxShadow: "none",
        "&.Mui-expanded": {
          borderTop: "1px solid #dcdbdc",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: "0px"
      },
    },
    MuiAccordionDetails: {
      root: {
        flexDirection: "column",
        padding: "0px"
      },
    },
    MuiChip: {
      root: {
        "& .react-loading-spinner": {
          display: "inline-flex",
          marginLeft: 12,
          marginRight: -6
        }
      }
    }
  },
  palette: {
    background: {
      default: "#fff",
      paper: "#fff",
    },
    text: {
      primary: "#3B3C4B"
    },
    primary: {
      main: "#387F9B",
      contrastText: "#FFF"
    },
    secondary: {
      main: "#EE7D76",
      contrastText: "#FFF"
    },
    mention: {
      color: '#387F9B',
      backgroundColor: '#DCF3FC',
      selfBackgroundColor: '#FAE9B4'
    },
    error: {
      main: "#FF0000",
      border: "#FF0000",
      contrastText: "#FFF"
    },
    lines: {
      border: "#dcdbdc"
    },
    boxes: {
      gray: {
        backgroundColor: "#F0F0F0",
        color: "#3B3C4B"
      }
    }
  }
});

export {
  baseTheme
}
