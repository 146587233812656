import { useEffect, useState } from "react";
import { adDataPath, adTrackingPath } from "utilities/UrlHelpers";

export default function InfluenceAd(props) {
  const ad = props.ad || 'inbox';
  const aClass = props.aClass;
  const showDisclaimer = props.showDisclaimer;
  const imgClassName = props.imgClass || 'img-responsive';

  const [adData, setAdData] = useState(null);

  const fetchAdData = async () => {
    Fetch(adDataPath(ad))
      .then(response => response.json())
      .then(data => setAdData(data));
  }

  useEffect(() => {
    fetchAdData();
  }, []);

  useEffect(() => {
    if(adData){
      ga("send", {
        hitType: "event",
        eventCategory: adData.ec,
        eventAction: adData.ea,
        eventLabel: adData.el
      });
    }
  }, [adData]);

  if(!adData){
    return null;
  }

  if (adData.img) {
    return (
      <a
        href={adTrackingPath(adData.id)}
        className={`${aClass} ga-action`}
        data-action-name={adData.ea_click}
        data-label-name={adData.el}
        data-category-name={adData.ec}>
        { showDisclaimer ? <span>Sponsored Ad</span> : "" }
        <img src={adData.img} className={imgClassName} alt={adData.img_alt} />
      </a>
    );
  } else {
    return null;
  }
}
