const ProfileTypeBadge = (props) => {
  const { accountType, styles } = props;
  return (
    <span className="label label-default profile-type-badge" style={styles}>
      {(accountType || "Influencer").toUpperCase()}
    </span>
  );
};

export default ProfileTypeBadge;
