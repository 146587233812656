import { useEffect } from "react";
import ReactMarkdown from 'react-markdown';
import {autoLinkMarkdown} from "components/common/AutoLink";
import gfm from 'remark-gfm';

const CustomMarkdown = (props) => {
  const { source, allowedTypes } = props;
  const allowedTypesWithDefaults = allowedTypes || ["link", "break", "paragraph", "root", "text", "list", "listItem", "strong", "emphasis"];

  useEffect(()=>{
    window.initUnmarkedProfileLinks && initUnmarkedProfileLinks();
  }, [source])

  return (
    <ReactMarkdown
      escapeHtml={false}
      skipHtml={true}
      unwrapDisallowed={true}
      plugins={[gfm]}
      source={ autoLinkMarkdown( { text:source } ) }
      allowedTypes={allowedTypesWithDefaults}
      renderers={ { link: props => <a href={props.href} rel="nofollow noopener" target="_blank" >{props.children}</a>} }
      />
  )
}

export default CustomMarkdown;
