import NoteAttachmentListItem from "components/notes/AttachmentListItem";

const NoteAttachmentList = (props) => {
  const { note, setNoteState } = props;

  const renderListItemsOutput = () => {
    let output = [];
   
    if(note.attachments.length > 0){
      note.attachments.forEach((attachment) => {
        let k = `attachment-list-item-${attachment.id}`;
        output.push(
          <NoteAttachmentListItem
            key={k}
            attachment={attachment}
            note={note}
            setNoteState={setNoteState}/>
        )
      });
    }
    return output;
  };

  return (
    <div id="note-attachment-list"
      className="note-attachment-list"
      key="notes-attachment-list">
        { renderListItemsOutput() }
    </div>
  )
  }

export default NoteAttachmentList;
