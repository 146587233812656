import { Fragment } from "react";
import { usernameRegex } from "utilities/RailsHelpers";
import Autolinker from "autolinker";

const MessageFormatterV2 = ({ text, onMentionClick, skipUnknown, mentionClassMap }) => {
  let nodes = [];
  let lines = [];
  let key = 0;

  text.split("\n").map((line) => {
    lines.push(line);
    lines.push(<br key={key++}/>);
  })
  lines.pop();

  lines.forEach((line) => {
    if (typeof line != 'string') {
      nodes.push(line);
      return;
    }

    const matches = Autolinker.parse(line, {
      urls: true,
      email: true,
      mention: 'instagram'
    });

    if (matches.length == 0) {
      nodes.push(line);
      return;
    }

    let cursorPos = 0;

    matches.forEach((match) => {
      nodes.push(line.slice(cursorPos, match.getOffset()));
      cursorPos = match.getOffset() + match.getMatchedText().length;

      switch (match.getType()) {
        case 'url':
          nodes.push(<a key={key++} href={match.getUrl()}>{match.getMatchedText()}</a>);
          break;
        case 'email':
          nodes.push(<a key={key++} href={`mailto:${match.getEmail()}`}>{match.getEmail()}</a>)
          break;
        case 'mention':
          let username = match.getMention();
        
          if (skipUnknown && (mentionClassMap?.[username] == null)) {
            nodes.push(`@${username}`);
          } else {
            let onMentionClickWrapped = () => { onMentionClick(username) };
            nodes.push(<span key={key++} onClick={onMentionClickWrapped} className={`at-mention at-mention-${mentionClassMap[username]} at-mention-${username.replace(/\./, '-')}`}>@{username}</span>);
          }

          break;
      }
    });

    nodes.push(line.slice(cursorPos));
  })
  

  return nodes;
};

const MessageFormatter = ({ text, onMentionClick, skipUnknown, mentionClassMap }) => {
  const urlDelimiter = /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[@a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi;
  const usernameDelimiter = /(?:^|[^\w])(@[A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/;

  let key = 0;

  let lines = [];
  text.split("\n").map((line) => {
    lines.push(line);
    lines.push(<br key={key++}/>);
  })
  lines.pop();

  let url_lines = lines.map((line, index) => {
    if (typeof line != 'string') {
      return line;
    }
    return line.split(urlDelimiter).map((word, wordIndex) => {
      let match = word.match(urlDelimiter);
      if (match) {
        let url = match[0];
        return <a key={key++} target="_blank" href={url}>{url}</a>
      } 
      return word;
    })
  }).flat();

  return url_lines.map((line, index) => {
    if (typeof line != 'string') {
      return line;
    }
    
    return line.split(usernameDelimiter).map((word, wordIndex) => {
      let match = word.match(usernameDelimiter);
      if (match) {
        let username = match[0].replace(/@/, "");

        if (skipUnknown && (mentionClassMap?.[username] == null)) {
          return ` ${word}`;
        }

        let onMentionClickWrapped = () => { onMentionClick(match[0]) };
        return <span key={key++} onClick={onMentionClickWrapped} className={`at-mention at-mention-${mentionClassMap[username]} at-mention-${username.replace(/\./, '-')}`}>@{username}</span>
      } 
      return word;
    })
  }).flat();

  return linked_lines.map((line, index) => {
    if (typeof line != 'string') {
      return line;
    }
    return line.split(urlDelimiter).map((word, wordIndex) => {
      let match = word.match(urlDelimiter);
      if (match) {
        let url = match[0];
        return <a key={key++} href={url}>{url}</a>
      } 
      return word;
    })
  }).flat();
};


const AutoLink = ({ text }) => {
  // https://react.30secondsofcode.org/snippet/AutoLink/
  const delimiter = /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi;

  const formatLinks = (unformattedText) => {
    if(!unformattedText){
      return unformattedText;
    }
    return unformattedText.split(delimiter).map((word, index) => {
      let match = word.match(delimiter);
      if (match) {
        let url = match[0];
        return <a rel="nofollow noopener" key={index} href={url.startsWith('http') ? url : `http://${url}`}>{url}</a>;
      }
      return word;
    })
  }

  const formatUsernameLinks = (unformattedText) => {
    if(!unformattedText){
      return unformattedText;
    }
    let returnValue = unformattedText.map((textRow, index) => {
      if(typeof textRow == 'string'){
        let stringReturn = textRow.replace(usernameRegex, (usernameMatch) => {
          let trimmedUsername = usernameMatch.trim().replace(/^@/, '');
          return (
            `<a class="profile-modal-opener" data-profile-modal-username="${trimmedUsername}" href="/${trimmedUsername}">${usernameMatch}</a>`
          )
        });
        return <span key={index} dangerouslySetInnerHTML={{__html:stringReturn}} />;
      }else{
        return textRow;
      }
    });
    return returnValue;
  }

  return (
    <Fragment>
    {formatUsernameLinks(formatLinks(text))}
    </Fragment>
  );
}

const autoLinkMarkdown = ({ text }) => {
  // https://react.30secondsofcode.org/snippet/AutoLink/
  const delimiter = /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi;


  const formatLinks = (unformattedText) => {
    const markdownLinkDelimiter = /(\[.*?\]\(.*?\))/;
    if(!unformattedText){
      return unformattedText;
    }
    return unformattedText.split(markdownLinkDelimiter).map(splitMarkdownText => {
      if(splitMarkdownText.match(markdownLinkDelimiter)){
        return splitMarkdownText;
      }

      return splitMarkdownText.split(delimiter).map((word, index) => {
        let match = word.match(delimiter);
        if (match) {
          let url = match[0];
          return `[${url}](${url.startsWith('http') ? url : `http://${url}`})`;
        }
        return word;
      }).join("")
    });

  }

  const formatUsernameLinks = (unformattedText) => {
    if(!unformattedText){
      return unformattedText;
    }
    let returnValue = unformattedText.map((textRow, index) => {
      if(typeof textRow == 'string'){
        let stringReturn = textRow.replace(usernameRegex, (usernameMatch) => {
          let trimmedUsername = usernameMatch.trim().replace(/^@/, '');
          return (`[${usernameMatch}](/${trimmedUsername})`)
        });
        return stringReturn;
      }else{
        return textRow;
      }
    });
    return returnValue;
  }

  return formatUsernameLinks(formatLinks(text)).join("");
}


export default AutoLink;
export {autoLinkMarkdown, MessageFormatter, MessageFormatterV2};
