import { useState, useRef } from "react";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import AvatarUsername from "components/common/AvatarUsername";
import useSharedSpacesStyles from "./SharedStyles";
import CurrentUserData from "components/common/CurrentUserData";

const AnswerForm = (props) => {
  const { onCancel, account, answerInput, answering } = props;
  const classes = useSharedSpacesStyles();
  const [answerValue, setAnswerValue] = useState("");

  const handleAnswerChange = (evt) => {
    setAnswerValue(evt.target.value);
  };

  return (
    <div className={classes.answerForm}>
      <div className="avatar-cancel">
        <AvatarUsername account={CurrentUserData()} hideHeadline hideUsername />
        <div className="cancel-container">
          <button className="btn btn-link" onClick={onCancel}>Cancel</button>
        </div>
      </div>
      <hr />
      <div className="answer">
        <TextareaAutosize name="answer_description" ref={answerInput} placeholder="Write an answer" defaultValue={answerValue} style={{outline: "none"}} />
      </div>
    </div>
  )
};

export default AnswerForm;
