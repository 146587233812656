import { useState } from "react";
import ReactDOM from "react-dom";
import { Box, Button } from "@material-ui/core";
import { ThemeProvider } from '@material-ui/core/styles';
import CustomDialog from "CustomDialog";
import ccCrossoverImg from "./crossing.svg";

import { baseTheme } from "themes/Chat";

export default function CCCrossOverModal(){
  const [open, setOpen] = useState(true);

  return (
    <ThemeProvider theme={baseTheme}>
      <CustomDialog open={open} onClose={()=>{setOpen(false)}}>
        <Box >
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" >
          <img src={ccCrossoverImg} style={{maxWidth: "100%"}} />
          <h2 className="hidden-xs">You've left Creator Communities!</h2>
          <h3 className="visible-xs">You've left Creator Communities!</h3>
          <Box textAlign="">
            <p className="lead">
              <strong>Creator Communities</strong> is just one part of influence.co's broader professional
              network for the creator economy. Feel free to explore. To get back to <strong>Creator
              Communities</strong> click the <i className="text-info fa fa-comments"></i> icon
              <span className="hidden-xs"> in the top right corner of the screen.</span>
              <span className="visible-xs"> inside the <i className="text-primary fa fa-bars"></i> menu.</span>
            </p>
          </Box>
          <Box  mt={2} mb={2}><Button variant="contained" color="secondary" onClick={()=>{setOpen(false)}} style={{fontSize: 18}}>Continue &raquo;</Button></Box>
        </Box>
      </CustomDialog>
    </ThemeProvider>
  );
};

window.triggerCCCrossOverModal = () => {
  ReactDOM.render(<CCCrossOverModal/>, document.body.appendChild(document.createElement("DIV")));
};



