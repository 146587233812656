import { createState, useState } from "@hookstate/core";

const SpaceState = createState({
  spaces: [],
  currentSpaceId: null, // all mine [space_id]
  currentFilter: null, // newest trending unanswered
  questions: null,
  currentOffset: 0,
  showLoadMore: false,
  currentMobilePanel: "middle",
  mobileSpaceListPanel: null,

  // NOTE: should this be it's own state?  ¯\_(ツ)_/¯  putting it here for now
  answers: {}
});

export default function UseSpaceState(accessDirectly) {
  if (accessDirectly) {
    return SpaceState;
  }

  return useState(SpaceState);
}
