import axios from "axios";

class UploadService {
  upload(url, formData, onUploadProgress, onDownloadProgress) {
    return axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
      onDownloadProgress
    });
  }
}

export default new UploadService();
