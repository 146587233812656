import { useState, useEffect } from "react";
import { adminActivityStatusPath, adminTogglePinnedPath } from "utilities/UrlHelpers";
import UseActivitiesState from 'data/ActivitiesState';

const PinToFeedButton = (props) => {
  const { activityId } = props;

  //guard clause in case we somehow get here without an activity being present
  if(!activityId){ return null; }

  const activityState = UseActivitiesState();
  const { activities } = activityState;
  const activity = activities[activityId];
  const { pinned } = activity.get() || {pinned: false};

  const getPinnedStatus = async () => {
    const resp = await Fetch(adminActivityStatusPath(activityId));

    if(resp.ok){
      const data = await resp.json();
      activityState.activities.merge({[activityId]: data});
    }
  };

  const handleClick = async () => {
    const resp = await Fetch(adminTogglePinnedPath(activityId),{ method: "PUT" });
    if(resp.ok){
      const data = await resp.json();
      activityState.activities.merge({[activityId]: data});
    }
  };

  useEffect(() => { getPinnedStatus(); }, []);

  if(!activity.get()){
    return null;
  }

  return (
    <button className="btn btn-link btn-text" onClick={handleClick}>
      { !pinned ? "Pin to " : "Unpin from " } Community Feed
    </button>
  )
}

export default PinToFeedButton;
