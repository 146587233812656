import { Component } from "react";
import ReactDOM from "react-dom";
import Drawer from 'components/Drawer';
import { searchNotesAboutPath } from 'utilities/UrlHelpers';

export default class NoteOpener extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAboutId: props.selectedAboutId,
      mobile: props.mobile,
      iconClasses: (props.iconClasses == false) ? null : (props.iconClasses || 'fa fa-edit'),
      buttonText: props.buttonText || '',
      openEditing: props.openEditing || false,
      showFileTray: props.showFileTray || false,
      containerClasses: props.containerClasses || ''
    };
  };

  componentDidMount = () => {};
  componentDidUpdate = (oldProps) => {};

  findNoteAboutData = async () => {
    const { state } = this;
    const { selectedAboutId } = state;

    // setup post data
    let formData = new FormData();
    formData.append('about_id', selectedAboutId);

    // post data
    const response = await Fetch(searchNotesAboutPath(), {method: 'POST', body: formData});

    // check response data
    if(response.ok){
      const data = await response.json();
      return data;
    }
  }

  openNoteClick = async () => {
    const { state } = this;
    const { selectedAboutId, mobile, openEditing, showFileTray } = state;
    let drawerProps = {
      selectedAboutId: selectedAboutId,
      selectedNoteId: null,
      openEditing: openEditing,
      showFileTray: showFileTray
    };
    let drawerTitle = "Notes";

    if(selectedAboutId){
      const noteData = await this.findNoteAboutData();
      drawerProps.selectedNoteId = noteData.id;
      if(mobile){
        drawerTitle = `Notes about ${noteData.about_name}`;
      }
    }

    let elm = $('<div></div>');
    const onClose = function(){
      $(elm).remove();
    };
    $('body').append(elm);

    ReactDOM.render(
      <Drawer
        drawerTitle={drawerTitle}
        drawerContentType="NotesDrawer"
        drawerContentProps={drawerProps}
        onClose={onClose}
        idScope="notes" />
      ,
      elm[0]
    );
  };

  render() {
    const { state, openNoteClick} = this;
    const { iconClasses, buttonText, containerClasses } = state;

    return (
      <span onClick={openNoteClick} className={containerClasses}>
        {
          iconClasses ? 
            <span
                data-toggle="tooltip"
                data-placement="bottom"
                data-trigger="hover"
                data-title="Notes"
                className={iconClasses}>
            </span>
            :
            ""
        }
        {buttonText}
      </span>
    );
  }
}
