import { makeStyles } from "@material-ui/core/styles";
import HoverableAvatar from "components/common/HoverableAvatar";
import FireballIcon from "components/common/FireballIcon";
import useBoostrapVariables from "utilities/BootstrapVariables";
import EventTracking from "components/common/EventTracking";

const cssDefaults = useBoostrapVariables();

const useStyles = makeStyles((theme) => ({
  post: {
    cursor: "pointer",
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: "1px solid #ccc",
    "&:last-child": {
      borderBottom: "0 none",
      marginBottom: 0,
      paddingBottom: 0,
    },
  },
  postHeader: {
    display: "flex",
    alignItems: "center",
    color: cssDefaults.brandLink,
    fontSize: 12,
  },
  postAvatar: {
    marginRight: theme.spacing(1),
    width: 32,
    height: 32,
  },
  postTitle: {
    fontSize: 14,
    fontWeight: "bold",
  },
  lineClamped: {
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical"
  },
  postContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    "& img": {
      width: 50,
      height: 50,
      objectFit: 'cover'
    },
    "& > div:first-child": {
      flexGrow: 1,
      textAlign: "left",
      maxWidth: "calc(100% - 58px)",
    },
  },
  postMeta: {
    color: "black",
    "& .influence-icon": {
      width: 11,
      marginLeft: 3
    },
    "& .fa-comment": {
      marginLeft: 3,
      fontSize: 14,
    },
  },
  postInfo: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    "& .MuiAvatar-circular": {
      width: 21,
      height: 21,
      marginRight: 4,
    },
    "& > div:first-child": {
      flexGrow: 1,
      display: "flex",
    },
  },
  postShare: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: cssDefaults.tableBgAccent,
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    "& img": {
      width: 60,
      marginRight: theme.spacing(2),
    },
    "& > div": {
      maxWidth: "calc(100% - 76px);",
    },
  },
  shareTitle: {
    color: cssDefaults.brandLink,
    fontSize: 14,
    fontWeight: "bold",
  },
  shareUrl: {
    fontSize: 11,
    color: cssDefaults.brandLink,
  },
}));

export default function TopPost(props) {
  const { post, hidePreview, hideDescription, hideFullName } = props;
  const classes = useStyles();

  const handleClick = () => {
    EventTracking("Top Posts - Click", {postId: post.id, postLink: post.show_post_path})
    setTimeout(()=>{window.location = post.show_post_path}, 300);
  };

  const PostShare = () => {
    const { ogData, shareUrl, display_image_url } = post;
    if (!post || !shareUrl || shareUrl === "" || shareUrl === null || hidePreview) {
      return null;
    }

    return (
      <div className={classes.postShare}>
        { display_image_url && (
        <img src={display_image_url} alt="Post preview" />
        ) }
        <div>
          <div className={`${classes.shareTitle} truncate-text no-wrap`}>{ogData.title}</div>
          <div className={`${classes.shareUrl} truncate-text`}>
            <a rel="noopener nofollow" href={shareUrl}>{shareUrl}</a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      onClick={handleClick}
      onKeyDown={handleClick}
      tabIndex={0}
      role="button"
      key={post.id}
      className={classes.post}
    >
      <div className={classes.postHeader}>
        <HoverableAvatar
          src={post.account.profilePicture}
          username={post.account.username}
          classes={classes.postAvatar}
        />
        <div className="truncate-text">
          <div className={`truncate-text no-wrap`}>
            { !hideFullName && (
              post.account.fullName + " "
            ) }
            @{ post.account.username }
          </div>
          { post.createdAt } 
        </div>
      </div>
      <div className={classes.postContent}>
        <div>
          <div className={`${classes.postTitle} ${classes.lineClamped}`}>
            { post.title }
          </div>
          { !hideDescription && (
            post.content
          ) }
        </div>
        { post.display_image_url && (
          hidePreview || (!post.shareUrl || post.shareUrl === "" || post.shareUrl === null)
        ) && (
          <div className={classes.postImage}>
            <img src={post.display_image_url} alt="Post url preview" />
          </div>
        ) }
      </div>
      <PostShare />
      <div className={classes.postInfo}>
        <div>
          {
            post.topLikeUsers.slice(0,4).map(({ avatarUrl, username }) => (
              <HoverableAvatar
                key={username}
                src={avatarUrl}
                username={username}
              />
            ))
          }
        </div>
        <div className={classes.postMeta}>
          <FireballIcon black /> { `${post.fireballsCount} ` }
          <i className="fa fa-comment" /> { post.commentsCount }
        </div>
      </div>
    </div>
  );
}
