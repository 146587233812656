import CircularImage from "components/common/CircularImage/index";
import FollowButton from "components/FollowButton";

const SuggestedFollow = (props) => {
  const {
    account, baseFollowClass, followClass, followedClass, showLocation,
  } = props;

  return (
    <div className="suggested-follow">
      <div
        className="suggested-avatar profile-modal-opener"
        data-profile-modal-username={account.username}
      >
        <CircularImage src={account.profilePicture} />
      </div>
      <div
        className="account-data profile-modal-opener"
        data-profile-modal-username={account.username}
      >
        <div className="account-data-sans-reach">
          <a href={account.profileUrl} className="suggested-full-name">
            {account.fullName}
            &nbsp;
          </a>
          <br />
          <a href={account.profileUrl} className="suggested-username">
            @
            {account.username}
          </a>
          <br />
          { account.headline ? (
            <>
              <span className="suggested-headline">{account.headline}</span>
              <br />
            </>
          ) : "" }
          { (showLocation && account.location) ? (
            <>
              <span className="suggested-location">
                <i className="fa fa-map-marker"></i>
                {account.location}
              </span>
              <br />
            </>
          ) : "" }
        </div>
        { (account.reach !== 0 && account.reach !== "") ? (
          <small className="account-data-reach">
            {account.reach}
            &nbsp;reach
          </small>
        ) : "" }
      </div>
      <div className="follow-button">
        <FollowButton
          source="FollowButton:SuggestedFollow"
          followedId={account.id}
          baseClass={baseFollowClass}
          followClass={followClass}
          followedClass={followedClass}
        />
      </div>
    </div>
  );
};

export default SuggestedFollow;
