import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faSmile as farSmile
} from '@fortawesome/free-regular-svg-icons';

library.add(
  fas,
  fab,
  farSmile
);
