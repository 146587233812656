import { useState, useRef } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import useBoostrapVariables from "utilities/BootstrapVariables";
const cssDefaults = useBoostrapVariables();

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 168,
    border: '1px solid #dadde9',
    "& h2": {
      fontSize: 14,
      fontWeight: 500,
      margin: `${theme.spacing(1)}px 0`,
    },
    "& a, & a:hover": {
      display: "block",
      fontSize: 14,
      fontWeight: 400,
      color: cssDefaults.textColor,
      padding: `${theme.spacing(1)}px 0`,
      textDecoration: "none"
    },
    "& hr": {
      margin: `${theme.spacing(1)}px 0`
    }
  },
  arrow: {
    fontSize: 16,
    width: 17,
    color: "#fff",
    "&::before": {
      border: "1px solid #dadde9",
      backgroundColor: "#fff",
      boxSizing: "border-box"
    }
  }
}))(Tooltip);

const useShareButtonOpenerStyles = makeStyles((theme) => ({
  opener: {
    cursor: "pointer",
    color: cssDefaults.brandLink
  }
}));

const ShareButton = (props) => {
  const copyTextArea = useRef();
  const { url, title, hashtags, textButton } = props;
  const encodedUrl = encodeURIComponent(url);
  const [open, setOpen] = useState(false);
  const classes = useShareButtonOpenerStyles();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpener = () => {
    setOpen(!open);
  };

  const copyToClipboard = (evt) => {
    evt.preventDefault();
    copyTextArea.current.select();
    document.execCommand("copy");
    alertify.success("Copied url to clipboard");
  };

  return(
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <HtmlTooltip
        arrow
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        interactive
        title={
          <>
            <h2>Share</h2>
            <hr />
            <a target="_blank" rel="noopener noreferrer nofollow"  href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&t=${title} ${hashtags}`}>
              <i className="fa fa-facebook"></i> Facebook
            </a>
            <a target="_blank" rel="noopener noreferrer nofollow" href={`http://twitter.com/share?url=${encodedUrl}&hashtags=${hashtags}&text=${title}`}>
              <i className="fa fa-twitter"></i> Twitter
            </a>
            <a href={url} onClick={copyToClipboard}>
              <i className="fa fa-copy"></i> Copy link
              <textarea ref={copyTextArea} defaultValue={url} style={{position: "absolute", top: -9999, left: -9999}}></textarea>
            </a>
          </>
        }
      >
        { textButton
          ? <span className={`${classes.opener}`} onClick={handleTooltipOpener}>Share</span>
          : <i className={`${classes.opener} fa fa-share`} onClick={handleTooltipOpener}></i> }
      </HtmlTooltip>
    </ClickAwayListener>
  )
};

export default ShareButton;
