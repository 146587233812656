import { Component } from "react";

export default class AvatarBar extends Component {
  renderAvatars(likers) {
    return likers.map(({ avatarUrl, username }, idx) =>
      <div className={"avatar-container profile-modal-opener"}
           data-profile-modal-username={username}
           key={ idx }>
        <a href={`/${username}`} title={username}>
          <CircularProfilePicture
            key={ idx }
            src={ avatarUrl }
            alt={ username }
          />
        </a>
      </div>
    )
  }
  render() {
    const {
      likers
    } = this.props;
    return (
      likers.length
      ? <div className={"hidden-xs"}>
          { this.renderAvatars(likers) }
        </div>
        : null
    )
  }
}

class CircularProfilePicture extends Component {
  render() {
    const {
      alt,
      src
    } = this.props;

    return (
      <div>
        {src
          ? (
            <img
              alt={ alt }
              className={"img-circle"}
              src={ src }
            />
          )
          : ''}
      </div>
    )
  }
}
