
import { Button, Link, Menu, MenuItem } from "@material-ui/core";
import { useEffect, useState, useRef } from "react";
import UseCurrentUserState from 'CurrentUserState';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .menu": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    "& .menulink": {
      textTransform: "none",
      display: "block",
      width: "100%",
      textAlign: "left",
      fontSize: 14,
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
  }
}));

function ButtonsProfileDropdownMenu(props) { 
  let { 
    menuOpener, 
    handleEditProfileClick, 
    handleViewProfileClick,
    insideCC = false
  } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const userState = UseCurrentUserState();
  const classes = useStyles();

  if(!handleEditProfileClick){
    handleEditProfileClick = () => {
      window.location = userState.get().editProfileUrl;
    }
  }
  
  if(!handleViewProfileClick){
    handleViewProfileClick = () => {
      window.location = userState.get().profileUrl;
    }
  }

  const handleMenuClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setDropdownOpen(true);
  };

  const handleCloseMenu = event => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    if(!menuOpener){return;}
    menuOpener.addEventListener('click', handleMenuClick);
    
    return function cleanup() {
      menuOpener.removeEventListener('click', handleMenuClick)
    }
  }, [menuOpener])

  return (
    <Menu
      keepMounted
      disablePortal
      anchorEl={menuOpener}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      getContentAnchorEl={null}
      open={dropdownOpen}
      onClose={handleCloseMenu}
      className={classes.root}
    >
      <MenuItem onClick={handleCloseMenu} className="menu">
        <Button onClick={handleViewProfileClick} className="menulink">View Profile</Button>
      </MenuItem>
      <MenuItem onClick={handleCloseMenu} className="menu">
        <Button onClick={handleEditProfileClick} target="_blank" className="menulink">Edit Profile</Button>
      </MenuItem>
      <MenuItem onClick={handleCloseMenu} className="menu">
        <Button href={userState.get().editSettingsUrl} target="_blank" className="menulink">Account Settings</Button>
      </MenuItem>
      { insideCC && 
        <MenuItem onClick={handleCloseMenu} className="menu">
          <Button href={"/"} target="_blank" className="menulink">Go to influence.co</Button>
        </MenuItem>
      }
      <MenuItem onClick={handleCloseMenu} className="menu">
        <Button href={"/users/sign_out"} className="menulink">Sign Out</Button>
      </MenuItem>
    </Menu>
  );
}

function LinksProfileDropdownMenu(props) { 
  let { 
    menuOpener
  } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const userState = UseCurrentUserState();
  const user = userState.get();
  const classes = useStyles();

  const handleMenuClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setDropdownOpen(true);
  };

  const handleCloseMenu = event => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    if(!menuOpener){return;}
    menuOpener.addEventListener('click', handleMenuClick);
    
    return function cleanup() {
      menuOpener.removeEventListener('click', handleMenuClick)
    }
  }, [menuOpener])

  return (
    <Menu
      keepMounted
      disablePortal
      disableScrollLock
      anchorEl={menuOpener}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      getContentAnchorEl={null}
      open={dropdownOpen}
      onClose={handleCloseMenu}
      className={classes.root}
    >
      <MenuItem className="menu">
        <Button href={user.profileUrl} className="menulink">View Profile</Button>
      </MenuItem>
      <MenuItem className="menu">
        <Button href={user.editProfileUrl} className="menulink">Edit Profile</Button>
      </MenuItem>
      <MenuItem className="menu">
        <Button href={user.editSettingsUrl} className="menulink">Account Settings</Button>
      </MenuItem>
      <MenuItem className="menu">
        <Button href={"/users/sign_out"} className="menulink">Sign Out</Button>
      </MenuItem>
    </Menu>
  );
}

const ProfileDropdownMenuOpener = (props) => {
  const { type = "links" } = props;
  const userState = UseCurrentUserState();
  const { profileUrl, profilePicture } = userState.get();
  const menuOpener = useRef();
  const [menuOpenerCurrent, setMenuOpenerCurrent] = useState(menuOpener.current);

  useEffect(() => {
    setMenuOpenerCurrent(menuOpener.current);
  },[]);

  return (
    <>
      <Link href={profileUrl} aria-controls="simple-menu" aria-haspopup="true" ref={menuOpener} className="nav-link">
        <img src={profilePicture} className="avatar" />
      </Link>
      { type == "links" 
        ? <LinksProfileDropdownMenu menuOpener={menuOpenerCurrent} {...props} />
        : <ButtonsProfileDropdownMenu menuOpener={menuOpenerCurrent} {...props} /> }
    </>
  )
}

export {
  LinksProfileDropdownMenu,
  ButtonsProfileDropdownMenu,
  ProfileDropdownMenuOpener
};