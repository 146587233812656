const UploadingFile = (props) => {
  const {
    attachment,
    percent
  } = props


  return (
    <div className="progress">
      <div className="progress-bar" role="progressbar" aria-valuenow={percent}
        aria-valuemin="0" aria-valuemax="100" style={{width: percent + '%'}}>
        {percent}%
      </div>
    </div>
  )
};

export default UploadingFile;
