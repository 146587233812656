import { useEffect, useRef } from "react";
import LoadingSpinner from "components/common/Loading";
import NotificationItem from "components/notifications/NotificationItem"
import { assetUrl } from "utilities/RailsHelpers";

const NotificationsDrawerList = (props) => {
  const {
    notificationsDrawerData
  } = props;

  const {
    notifications,
    setNotificationsData,
    notificationsLoading,
    showViewAll,
    showLoadMore,
    fetchNotificationsData,
    errorLoading,
    drawerMethods
  } = notificationsDrawerData;

  const {
    closeDrawer
  } = drawerMethods;

  const notificationsListElement = useRef();

  const ListItemsOutput = () => {
    let notificationItemOutput = [];

    if(Object.keys(notifications).length > 0){
      const notification_ids = Object.keys(notifications)

      notification_ids.forEach((currentNotificationId) => {
        notificationItemOutput.push(
          <NotificationItem
            notification={notifications[currentNotificationId]}
            key={currentNotificationId}
            closeDrawer={closeDrawer} />
        )
      })
    }else{
      notificationItemOutput = (
        <div className="notification-list-no-content">
          No notifications yet.
        </div>
      );
    }
    return notificationItemOutput;
  }
  if(notificationsLoading){
    return (
      <div className="maxed-height">
        <div key={`notifications-list`}
             ref={ notificationsListElement }
             className={`notifications-list maxed-height text-center`}>
         <LoadingSpinner />
        </div>
      </div>
    )
  }

  return (
    <div className="maxed-height notifications-body">
      <div key={`notifications-list`}
           ref={ notificationsListElement }
           className={`notifications-list maxed-height`}>
        { Object.keys(notifications).length > 0
            ? <>
                <ListItemsOutput />

              </>
            : <div className="no-notifications">
                <p>
                  <img src={assetUrl('undraw-walking-outside-5-ueb.svg')} />
                </p>
                <p>
                  {!errorLoading
                    ? "You don't have any notifications yet"
                    : "There was an error while loading your notifications, try again later or contact us at support@influence.co if the issue keeps happening" }
                </p>
              </div> }
      </div>
      { !showViewAll && Object.keys(notifications).length > 0 && showLoadMore &&
        <div className="load-more-notifications">
          <a onClick={fetchNotificationsData}>Load More</a>
        </div> }
      { showViewAll && !errorLoading &&
        <div className="show-all-notifications">
          <a href="/influencers/notifications">See All</a>
        </div> }
    </div>
  )
}

export default NotificationsDrawerList;
