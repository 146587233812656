import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Drawer from "components/Drawer";
import {influencerNotificationsResetUnseenCountPath, influencerNotificationsUnseenCountPath} from "utilities/UrlHelpers";
import UseNotificationsCountState from 'data/NotificationsCountState';
import getConsumer from "channels/consumer";
import CurrentUserData from "CurrentUserData";
const consumer = getConsumer();

const ShowNotificationsButton = (props) => {
  const {
    buttonText,
    classes
  } = props;
  const { ghost_mode } = CurrentUserData();

  const unseenCountState = UseNotificationsCountState();
  const unseenCount = unseenCountState.get().unseenCount;
  const setUnseenCount = (count) => {
    unseenCountState.set({unseenCount: count});
  };

  const [drawerOpen, setDrawerOpen] = useState(false);

  const defaultCSSClasses = 'btn btn-default btn-lg truncate-text';

  const openNotificationDrawer = async () => {
    if(drawerOpen){
      return;
    }else {
      setDrawerOpen(true);
    }
    if(!ghost_mode){
      Fetch(influencerNotificationsResetUnseenCountPath(), {method: 'PUT'});
    }

    let elm = document.createElement("div");
    const onClose = async () => {
      setDrawerOpen(false);
      elm.remove();
    };

    document.getElementsByTagName('body')[0].appendChild(elm);
    ReactDOM.render(
      <Drawer
        drawerTitle={'Notifications'}
        drawerContentType="NotificationsDrawer"
        drawerContentProps={{showViewAll: true}}
        backgroundClickClose={true}
        onClose={onClose}
        idScope="notifications" />
      ,
      elm
    );
  }

  // Initialize with value from props
  useEffect(()=>{
    unseenCountState.set({unseenCount: props.unseenCount });
    let sub = consumer.subscriptions.create("NotificationChannel", {
      received(notification_counts) {
        if('unseenNotificationsCount' in notification_counts){
          setUnseenCount(notification_counts.unseenNotificationsCount);
        }
      }
    });

    return () => { sub.unsubscribe(); };
  }, []);

  return (
    <span className={`open-notifications ${classes || defaultCSSClasses}`}
       onClick={()=>{openNotificationDrawer()}}>
      {buttonText || 'Message'}
      {
        unseenCount > 0 &&
        <div className="unseen-notifications-count">
          {
            unseenCount > 9 ? "9+" : unseenCount
          }
        </div>
      }
    </span>
  )
}

export default ShowNotificationsButton;
