import { useEffect, useState } from "react";
import TopPostsCarousel from "components/posts/top_posts/TopPostsCarousel";
import TopPostsDesktop from "components/posts/top_posts/TopPostsDesktop";
import { topPostsPath } from "utilities/UrlHelpers";

export default function TopPosts(props){
  const useCarousel = props.carousel;
  const [posts, setPosts] = useState([]);

  const getTopPosts = async () => {
    const response = await Fetch(topPostsPath())
    if(!response.ok){
      return;
    }else{
      const slideData = await response.json();
      setPosts(slideData);
    }
  };

  useEffect(()=>{
    getTopPosts();
  }, []);

  if(!posts || posts.length == 0){
    return null;
  }

  return (
    <div className={`top-posts`}>
      { useCarousel ? (
          <TopPostsCarousel posts={posts} />
        ) : (
          <TopPostsDesktop posts={posts} />
        ) }
    </div>
  )
};
