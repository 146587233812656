import { useState } from 'react';
import CurrentUserData from 'CurrentUserData';
import Warning from './Warning';

export default function DownloadButton(props){
  const { classes = "btn btn-info", buttonText = "Download", message} = props;
  const { attachmentDownloadUrl } = message;
  const [showDownloadWarning, setShowDownloadWarning] = useState(false);

  const handleDownloadClick = (ev) => {
    if(CurrentUserData().flags_hide_download_warning){
      return true;
    }else{
      ev.preventDefault();
      ev.stopPropagation();
      setShowDownloadWarning(true);
    }
  };

  return (
    <>
      <a href={attachmentDownloadUrl} onClick={handleDownloadClick} className={classes}>
        {buttonText}
      </a>
      <Warning
        type="download"
        message={message}
        showWarning={showDownloadWarning}
        setShowWarning={setShowDownloadWarning} />
    </>
  );
};
