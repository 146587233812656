import 'components/messages/search/ConversationSearchResults.scss';

import { useRef } from "react";
import UseMessagesState from 'data/MessagesState';
import LoadingSpinner from "components/common/Loading";
import CircularImage from "components/common/CircularImage/index";
import { assetUrl } from "utilities/RailsHelpers";
import ScrollTracking from 'components/common/ScrollTracking';

const SearchResuts = (props) => {
  const messagesDrawerState = UseMessagesState();
  const { setMessagesDrawerData, currentSearchScrollPosition } = messagesDrawerState.get();
  const searchResultsElement = useRef();

  const {
    search,
    searchData,
    selectConversation,
    messagingAccountId
  } = messagesDrawerState.get();

  const selectedClass = (id) => {
    return (messagingAccountId && messagingAccountId == id) ? 'selected' : ''
  }

  const ContactResults = () => {
    if(!searchData['contacts'] || searchData['contacts'].length == 0){
      return('');
    }
    const contactsOutput = searchData['contacts'].map(contact => (
      <div
        className={`search-item-container ${selectedClass(contact.id)}`}
        key={`${contact.id}-contact`}
        onClick={()=>{selectConversation(contact.id)}}>
        <CircularImage src={contact.profilePicture} />
        <div className="search-details">
          <p className="search-name">{contact.fullName}</p>
          <p className="search-username">@{contact.username}</p>
          <p className="search-last-message">{contact.lastMessage}</p>
        </div>
      </div>
    ));

    return(
      <div className="search-contacts">
        <h2>Conversations</h2>
        {contactsOutput}
      </div>
    );
  }

  const ConversationResults = () => {
    if(!searchData['conversations'] || searchData['conversations'].length == 0){
      return('');
    }
    const conversationOutput = searchData['conversations'].map(conversation => (
      <div
        className={`search-item-container  ${selectedClass(conversation.recipient.id)}`}
        key={`${conversation.id}-conversation`}
        onClick={()=>{selectConversation(conversation.recipient.id, search)}}>
        <CircularImage src={conversation.recipient.profilePicture} />
        <div className="search-details">
          <p className="search-name">{conversation.recipient.fullName}</p>
          <p className="search-username">@{conversation.recipient.username}</p>
          <p className="search-matches">
            {conversation.messagesCount} matched
            {conversation.messagesCount == 1 ? ' message' : ' messages'}
          </p>
          <p className="search-last-message">{conversation.lastMessage}</p>
        </div>
      </div>
    ));

    return(
      <div className="search-conversation">
        <h2>Messages</h2>
        {conversationOutput}
      </div>
    );
  }

  const AccountResults = actProps => {
    const { type } = actProps;

    if(!searchData[type] || searchData[type].length == 0){
      return('');
    }
    const conversationOutput = searchData[type].map(account => (
      <div
        className={`search-item-container  ${selectedClass(account.id)}`}
        key={`${account.id}-${type}`}
        onClick={()=>{selectConversation(account.id)}}>
        <CircularImage src={account.profilePicture} />
        <div className="search-details">
          <p className="search-name">{account.fullName}</p>
          <p className="search-username">@{account.username}</p>
          <p className="search-reach">{account.reach} reach</p>
        </div>
      </div>
    ));

    return(
      <div className="search-conversation">
        <h2>Other {type}</h2>
        {conversationOutput}
      </div>
    );
  }

  const searchDataBlank = (
    !searchData || (
      (!searchData['conversations'] || searchData['conversations'].length == 0) &&
      (!searchData['contacts'] || searchData['contacts'].length == 0) &&
      (!searchData['influencers'] || searchData['influencers'].length == 0) &&
      (!searchData['businesses'] || searchData['businesses'].length == 0)
    )
  )

  if(search == ''){
    return('');
  } else if(searchData == null){
    return(
      <div className='text-center'>
        <LoadingSpinner />
      </div>
    )
  } else if(searchDataBlank) {
    return(
      <div className='text-center no-messages'>
        <img src={assetUrl('no-messages.svg')} />
        <p>No messages or users matched your search</p>
      </div>
    )
  } else{
    return (
      <>
        <ScrollTracking
          trackElement={searchResultsElement}
          trackValueName={'currentSearchScrollPosition'}
          trackValue={currentSearchScrollPosition}
          setDataMethod={setMessagesDrawerData} />
        <div
          className="current-search-results maxed-height"
          ref={ searchResultsElement }>
          <ContactResults />
          <ConversationResults />
          <AccountResults type="influencers"  />
          <AccountResults type="businesses" />
        </div>
      </>
    )
  }
}

export default SearchResuts;
