import infFireballImage from "../../../assets/images/influence-icon.svg";
import infFireballImageBlack from "../../../assets/images/influence-icon-black.svg";

const FireballIcon = (props) => {
  const source = props.black ? infFireballImageBlack : infFireballImage;
  return (
    <img src={source} className="influence-icon" />
  )
};

export default FireballIcon;
