import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  businessPostEngagementsPath,
  businessCampaignsCreatedCountPath,
  campaignsPath,
  businessLiveCampaignStatsPath
} from "utilities/UrlHelpers";
import UseTapeNavState from "data/TapeNavState";
import useBoostrapVariables from "utilities/BootstrapVariables";
import useSharedNavStyles from "components/nav/SharedStyles";
import EventTracking from "components/common/EventTracking";
import CurrentUserData from "components/common/CurrentUserData";
const cssDefaults = useBoostrapVariables();

const PostsAndEngagementsStats = (props) => {
  const classes = useSharedNavStyles();
  const navState = UseTapeNavState(false);
  const { postAndEngagements } = navState.get();
  const setData = (data) => {
    navState.merge({
      postAndEngagements: data
    });
  }

  const getPostsStats = async () => {
    if(postAndEngagements){ return; }
    const response = await Fetch(businessPostEngagementsPath());
    if(response.ok){
      const data = await response.json();
      setData(data);
    }
  };

  useEffect(()=>{
    getPostsStats();
  },[]);

  if(!postAndEngagements){ return null; }
  const { engagements, postsCount } = postAndEngagements;

  return (
    <div className={`col-xs-12 col-sm-5ths ${classes.infoPanel}`}>
      <div className={classes.lightbulb}><i className="fa fa-lightbulb"></i></div>
      <div className={classes.stats}>
        In the last week, <strong>{postsCount}</strong> businesses created content, and <strong>{engagements}</strong> influencers engaged with it
      </div>
    </div>
  )
};

const LiveCampaignStats= (props) => {
  const classes = useSharedNavStyles();
  const navState = UseTapeNavState(false);
  const { liveCampaignsStats } = navState.get();
  const setData = (data) => {
    navState.merge({
      liveCampaignsStats: data
    });
  }

  const getPostsStats = async () => {
    if(liveCampaignsStats){ return; }
    const response = await Fetch(businessLiveCampaignStatsPath());
    if(response.ok){
      const data = await response.json();
      setData(data);
    }
  };

  const ResponseRateEmoji = (props) => {
    const { responseRate } = props;
    let emoji = "😀";
    if(responseRate <= 50){
      emoji = "🙁";
    }else if(responseRate < 90){
      emoji = "😐";
    }
    return (
      <span className={`biz-stats-icon`}>{emoji}</span>
    )
  };

  const ResponseTimeEmoji = (props) => {
    const { responseTime } = props;
    let emoji = "😀";
    if(responseTime > 72){
      emoji = "🙁";
    }else if(responseTime >= 24){
      emoji = "😐";
    }
    return (
      <span className={`biz-stats-icon`}>{emoji}</span>
    )
  };

  useEffect(()=>{
    getPostsStats();
    EventTracking("Tape Nav Item View", {
      ...CurrentUserData(),
      item_name: "live_campaign_stats_panel",
      tape_name: "Commerce",
    });
  },[]);

  if(!liveCampaignsStats){ return null; }
  const { title, accepted, pending, response_rate, response_time, apps_url } = liveCampaignsStats;
  const trackClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    EventTracking("Tape Nav Item Click", {
      ...CurrentUserData(),
      item_name: "live_campaign_stats_panel",
      tape_name: "Commerce",
    });
    setTimeout(()=>{
      window.location = apps_url;
    },100);
  };

  return (
    <div className={`col-xs-12 col-sm-5ths ${classes.infoPanel} ${classes.campaignStats}`}>
      <h2 className={`no-wrap truncate-text`}>{title}</h2>
      <p>
        <strong>{accepted}</strong>
        Accepted Applications
      </p>
      <p>
        <strong>{pending}</strong>
        Pending Applications
      </p>
      { !!response_rate && !!response_time && (
        <p>
          <strong>
            <ResponseRateEmoji responseRate={response_rate} />
            {Math.round(response_rate)}%
            &nbsp;&nbsp;
            <ResponseTimeEmoji responseTime={response_time} />
            {Math.round(response_time)}hr
          </strong>
          Response Rate / Time
        </p>
      )}
      <a href={apps_url} onClick={trackClick} className={`btn btn-block ${classes.ctaButton}`}>
        Manage Applications
      </a>
    </div>
  )
};

const CampaignsCreatedStats = (props) => {
  const classes = useSharedNavStyles();
  const navState = UseTapeNavState(false);
  const { campaignsCreated } = navState.get();
  const setData = (data) => {
    navState.merge({
      campaignsCreated: data
    });
  }

  const getPostsStats = async () => {
    if(campaignsCreated){ return; }
    const response = await Fetch(businessCampaignsCreatedCountPath());
    if(response.ok){
      const data = await response.json();
      setData(data);
    }
  };

  useEffect(()=>{
    getPostsStats();
    EventTracking("Tape Nav Item View", {
      ...CurrentUserData(),
      item_name: "campaigns_created_stats_panel",
      tape_name: "Commerce",
    });
  },[]);

  if(!campaignsCreated){ return null; }

  const trackClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    EventTracking("Tape Nav Item Click", {
      ...CurrentUserData(),
      item_name: "campaigns_created_stats_panel",
      tape_name: "Commerce",
    });
    setTimeout(()=>{
      window.location = campaignsPath();
    },100);
  };

  return (
    <div className={`col-xs-12 col-sm-5ths ${classes.infoPanel}`}>
      <div className={classes.lightbulb}><i className="fa fa-lightbulb"></i></div>
      <div className={classes.stats}>
        Businesses have created <strong>{campaignsCreated} campaigns</strong> in the last 2 days.
      </div>
      <a href={campaignsPath()} onClick={trackClick} className={`btn btn-block ${classes.ctaButton}`}>
        Browse Campaigns
      </a>
    </div>
  )
};

export {
  PostsAndEngagementsStats,
  CampaignsCreatedStats,
  LiveCampaignStats
}
