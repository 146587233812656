import { useRef, useEffect } from "react";
import MessageItem from "components/messages/MessageItem";
import SendMessageForm from "components/messages/SendMessageForm";
import UnarchiveAndSendMessageButton from "components/messages/UnarchiveAndSendMessageButton";
import LoadingSpinner from "components/common/Loading";
import UseMessagesState from 'data/MessagesState';
import { assetUrl } from "utilities/RailsHelpers";
import SearchWithinConversationNavigation from 'components/messages/search/SearchWithinConversationNavigation';

const MessageList = (props) => {
  const messagesDrawerState = UseMessagesState();
  const {
    key
  } = props;

  const {
    messagingAccountId,
    conversationData,
    messagesLoading,
    setMessagesDrawerData,
    fetchConversationsListData,
    conversationListData,
    showLoading,
    removeLoading,
    currentMatch,
    searchWithinConversation
  } = messagesDrawerState.get();

  const {
    messages,
    archived
  } = conversationData;

  function renderListItemsOutput() {
    let messageItemOutput = [];
    if(Object.keys(messages).length > 0){
      const message_ids = Object.keys(messages)

      message_ids.forEach((currentMessageId) => {
        messageItemOutput.push(
          <MessageItem
              currentMessageId={currentMessageId}
              key={currentMessageId}
             />
        )
      })
    }

    return messageItemOutput;
  }

  if(messagesLoading){
    return(
      <div className="messages-list-container">
        <div id="messages-list" key="messages-list">
             <LoadingSpinner />
        </div>
      </div>
    )
  }
  const listContainerRef = useRef(null);

  useEffect(() => {
    if(searchWithinConversation){
      let currentMatchedElement = document.getElementsByClassName(`match-${currentMatch}`)[0]
      if(currentMatchedElement){
        currentMatchedElement.scrollIntoView();
      }
    }else{
      if(!listContainerRef.current || !listContainerRef.current.scrollHeight || !listContainerRef.current.scrollTo){
        return;
      }
      listContainerRef.current.scrollTo(0, listContainerRef.current.scrollHeight);
    }
  }, [searchWithinConversation, currentMatch]);

  return (
    <div className="messages-list-container">
     
      <div id="messages-list" key="messages-list">
        { messages.length > 0
            ? <>
                <SearchWithinConversationNavigation />
                <ul className="list-unstyled conversation-messages" ref={listContainerRef}>
                  <li>
                    <div className="alert alert-info">
                      <p><strong>Stay informed, and protect yourself from scams.</strong></p>
                      <p>
                      It's our goal to make influence.co the best place for creators and
                      businesses to collaborate, and we want you to have an amazing experience
                      here. To help with that,
                      please <a data-beacon-article-modal="609062f78996210f18bd9c62" href="#">review this article</a> about how to protect
                      yourself from scams. This article is regularly updated with new
                      information.
                      </p>
                    </div>
                  </li>

                  {renderListItemsOutput()}
                </ul>
              </>
            : <>
                <div className="text-center no-content">
                  <img src={assetUrl('messages_blank.svg')} className="message-blank img-responsive block-center padding-top" />
                  <p>Start a new conversation with <b>@{conversationData.recipient ? conversationData.recipient.username : 'user'}</b></p>
                </div>
              </> }
        { archived
            ? <UnarchiveAndSendMessageButton classes={'btn btn-primary pull-right'} />
            : <SendMessageForm  /> }

      </div>
    </div>
  )
}

export default MessageList;
